import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { CheckCircleOutlined } from '@ant-design/icons';
import { selectPlanAction } from '../../store/actions/subscriptionPlanActions';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const PlanContainer = styled.div`
  background-color: #fff;
  border: 2px solid #03071e;
  height: 300px;
  width: 250px;
  border-radius: 8px;
  padding: 16px;
  margin-left: 26px;
`;

const PlanName = styled.h2`
  color: #333;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  font-weight: 500;
  padding: 0;
`;

const FeatureItem = styled.li`
  margin-bottom: 8px;
`;

const Price = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #4caf50;
`;

const FeatureIcon = styled.div`
  margin-right: 8px;
  font-size: 18px;
  color: #4caf50; /* Set the color based on your design */
`;

const FeatureItemContainer = styled.li`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const SubscriptionButton = styled.button`
  background-color: #00a6fb;
  font-weight: 500;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const FeatureText = styled.span``;

const SubscriptionPlanCard = ({ plan }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubscribeClick = () => {
    let planId = plan._id;
    dispatch(selectPlanAction(planId));
    navigate('/order'); // Navigate to the '/order' route
  };

  

  if (!plan || !plan.features) {
    // Handle the case where plan or plan.features is undefined
    return (
      <PlanContainer>
        <p>Error loading subscription plan details.</p>
      </PlanContainer>
    );
  }
  return (
    <PlanContainer>
      <PlanName>{plan.name}</PlanName>
      <FeatureList>
        {plan.features.map((feature, index) => (
          <FeatureItemContainer key={index}>
            <FeatureIcon>
              <CheckCircleOutlined />
            </FeatureIcon>
            <FeatureText>{feature}</FeatureText>
          </FeatureItemContainer>
        ))}
      </FeatureList>
      <Price>{`${plan.monthlyPrice} ${plan.currency} / ${plan.billingInterval}`}</Price>
      <SubscriptionButton onClick={handleSubscribeClick}>Subscribe</SubscriptionButton>
    </PlanContainer>
  );
};

export default SubscriptionPlanCard;
