import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllArticles } from '../../store/actions/articleActions';
import ArticleList from '../../components/help/ArticleList';
import styled from 'styled-components';
import DashHeader from '../../components/common/DashHeader';

const HeroSection = styled.section`
  background-size: cover;
  background-color: #6a0dad;
  padding: 25px 15px;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    
    margin-top: 80px;
    border-radius: 8px;
  }
`;
const HeroTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 10px;
  color:#fff;

  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

const HeroDescription = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  color: #fff;

 
`;

const BillingArticlesPage = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.article.articles);
  const loading = useSelector((state) => state.article.loading);
  const error = useSelector((state) => state.article.error);
  

  console.log(articles);

  useEffect(() => {
    dispatch(getAllArticles());
  }, [dispatch]);

  const billingArticles = articles?.filter((article) => article.category === 'Billing');

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <DashHeader/>
      <HeroSection>
        <HeroTitle>Billing Help Articles</HeroTitle>
        <HeroDescription>Get answers to your questions about billing, payments, and managing your subscriptions.</HeroDescription>
      </HeroSection>

      {/* Article List */}
      <h2>Billing Articles</h2>
      <ArticleList articles={billingArticles} />
    </div>
  );
};

export default BillingArticlesPage;
