import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAction } from '../store/actions/authActions';
import Header from '../components/common/Header';
import Footer from '../components/home/Footer';

const PageContainer = styled.div`
  padding: 40px 20px;
  background-color: #f4f4f4;
  height: 100vh;
  overflow-y: auto;
`;

const Section = styled.section`
  margin-bottom: 40px;
  margin-top: 20px;
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color:#fff;
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
`;

const HeroSection = styled.section`
  background-color: #6a0dad; /* Purple background color */
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  padding: 20px 15px; /* Reduced padding for smaller height */
  text-align: center;
`;

const SubTitle = styled.h3`
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
`;

const CookiePolicy = () => {
    const dispatch = useDispatch();
  
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
    const handleLogout = () => {
      dispatch(logoutUserAction());
    };
  return (
    <>
      <Header isAuthenticated={isAuthenticated} onLogout={handleLogout}/>
      <PageContainer>
      <HeroSection>
              <Title>Cookie Policy for Track4U</Title>
            </HeroSection>
        <Section>
           
          
          <Paragraph>
            This Cookie Policy explains how Track4U ("we", "our", or "us") uses cookies on our website and
            services. By using our website, you consent to our use of cookies in accordance with this policy.
          </Paragraph>

          <SubTitle>What Are Cookies?</SubTitle>
          <Paragraph>
            Cookies are small text files that are placed on your device when you visit a website. They help the
            website recognize your device and remember certain information about your preferences or actions
            over time.
          </Paragraph>

          <SubTitle>How We Use Cookies</SubTitle>
          <Paragraph>
            We use cookies for the following purposes:
            <ul>
              <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function and cannot be switched off in our systems.</li>
              <li><strong>Performance Cookies:</strong> These cookies collect anonymous information about how visitors use the website, helping us improve its performance.</li>
              <li><strong>Functionality Cookies:</strong> These cookies allow us to remember your preferences and improve your experience, such as keeping you logged in.</li>
              <li><strong>Targeting/Advertising Cookies:</strong> These cookies are used to deliver relevant advertisements based on your browsing behavior.</li>
            </ul>
          </Paragraph>

          <SubTitle>Your Cookie Choices</SubTitle>
          <Paragraph>
            You have the right to accept or reject cookies. You can adjust your browser settings to control or
            block cookies. However, please note that some parts of the website may not function properly if you
            block certain cookies.
          </Paragraph>
          <Paragraph>
            To manage cookies, you can check your browser settings (e.g., Google Chrome, Mozilla Firefox, etc.) to
            block or delete cookies. You can also use the "Do Not Track" feature available in many modern browsers.
          </Paragraph>

          <SubTitle>Changes to This Cookie Policy</SubTitle>
          <Paragraph>
            We may update this Cookie Policy from time to time. Any changes will be posted on this page, and the
            date of the last update will be indicated at the top of the page. We encourage you to review this policy
            periodically to stay informed about how we are using cookies.
          </Paragraph>

          <SubTitle>Contact Information</SubTitle>
          <Paragraph>
            If you have any questions about this Cookie Policy, please contact us at: <br />
            Email: <a href="mailto:info@track4u.net">info@track4u.net</a>
          </Paragraph>
        </Section>
        <Footer/>
      </PageContainer>
      
    </>
  );
};

export default CookiePolicy;
