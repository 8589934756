// subscriptionPlanActionTypes.js

export const FETCH_SUBSCRIPTION_PLANS_REQUEST = 'FETCH_SUBSCRIPTION_PLANS_REQUEST';
export const FETCH_SUBSCRIPTION_PLANS_SUCCESS = 'FETCH_SUBSCRIPTION_PLANS_SUCCESS';
export const FETCH_SUBSCRIPTION_PLANS_FAILURE = 'FETCH_SUBSCRIPTION_PLANS_FAILURE';

export const FETCH_SUBSCRIPTION_PLAN_DETAILS_REQUEST = 'FETCH_SUBSCRIPTION_PLAN_DETAILS_REQUEST';
export const FETCH_SUBSCRIPTION_PLAN_DETAILS_SUCCESS = 'FETCH_SUBSCRIPTION_PLAN_DETAILS_SUCCESS';
export const FETCH_SUBSCRIPTION_PLAN_DETAILS_FAILURE = 'FETCH_SUBSCRIPTION_PLAN_DETAILS_FAILURE';

export const CREATE_SUBSCRIPTION_PLAN_REQUEST = 'CREATE_SUBSCRIPTION_PLAN_REQUEST';
export const CREATE_SUBSCRIPTION_PLAN_SUCCESS = 'CREATE_SUBSCRIPTION_PLAN_SUCCESS';
export const CREATE_SUBSCRIPTION_PLAN_FAILURE = 'CREATE_SUBSCRIPTION_PLAN_FAILURE';

export const UPDATE_SUBSCRIPTION_PLAN_REQUEST = 'UPDATE_SUBSCRIPTION_PLAN_REQUEST';
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS = 'UPDATE_SUBSCRIPTION_PLAN_SUCCESS';
export const UPDATE_SUBSCRIPTION_PLAN_FAILURE = 'UPDATE_SUBSCRIPTION_PLAN_FAILURE';

export const DELETE_SUBSCRIPTION_PLAN_REQUEST = 'DELETE_SUBSCRIPTION_PLAN_REQUEST';
export const DELETE_SUBSCRIPTION_PLAN_SUCCESS = 'DELETE_SUBSCRIPTION_PLAN_SUCCESS';
export const DELETE_SUBSCRIPTION_PLAN_FAILURE = 'DELETE_SUBSCRIPTION_PLAN_FAILURE';

export const SELECT_PLAN_REQUEST = 'SELECT_PLAN_REQUEST';
export const SELECT_PLAN_SUCCESS = 'SELECT_PLAN_SUCCESS';
export const SELECT_PLAN_FAILURE = 'SELECT_PLAN_FAILURE';

export const CLEAR_SELECTED_PLAN = 'CLEAR_SELECTED_PLAN';