export const ADD_PACKAGE_REQUEST = 'ADD_PACKAGE_REQUEST';
export const ADD_PACKAGE_SUCCESS = 'ADD_PACKAGE_SUCCESS';
export const ADD_PACKAGE_FAILURE = 'ADD_PACKAGE_FAILURE';

export const ADD_PACKAGES_REQUEST = 'ADD_PACKAGES_REQUEST';
export const ADD_PACKAGES_SUCCESS = 'ADD_PACKAGES_SUCCESS';
export const ADD_PACKAGES_FAILURE = 'ADD_PACKAGES_FAILURE';

export const GET_PACKAGES_REQUEST = 'GET_PACKAGES_REQUEST';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAILURE = 'GET_PACKAGES_FAILURE';

export const GET_PACKAGE_DETAILS_REQUEST = 'GET_PACKAGE_DETAILS_REQUEST';
export const GET_PACKAGE_DETAILS_SUCCESS = 'GET_PACKAGE_DETAILS_SUCCESS';
export const GET_PACKAGE_DETAILS_FAILURE = 'GET_PACKAGE_DETAILS_FAILURE';

export const UPDATE_PACKAGE_STATUS_REQUEST = 'UPDATE_PACKAGE_STATUS_REQUEST';
export const UPDATE_PACKAGE_STATUS_SUCCESS = 'UPDATE_PACKAGE_STATUS_SUCCESS';
export const UPDATE_PACKAGE_STATUS_FAILURE = 'UPDATE_PACKAGE_STATUS_FAILURE';

export const DELETE_PACKAGE_REQUEST = 'DELETE_PACKAGE_REQUEST';
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_FAILURE = 'DELETE_PACKAGE_FAILURE';

export const SEARCH_PACKAGES_BY_TRACKING_NUMBER_REQUEST = 'SEARCH_PACKAGES_BY_TRACKING_NUMBER_REQUEST';
export const SEARCH_PACKAGES_BY_TRACKING_NUMBER_SUCCESS = 'SEARCH_PACKAGES_BY_TRACKING_NUMBER_SUCCESS';
export const SEARCH_PACKAGES_BY_TRACKING_NUMBER_FAILURE = 'SEARCH_PACKAGES_BY_TRACKING_NUMBER_FAILURE';

export const SEARCH_PACKAGES_BY_STATUS_REQUEST = 'SEARCH_PACKAGES_BY_STATUS_REQUEST';
export const SEARCH_PACKAGES_BY_STATUS_SUCCESS = 'SEARCH_PACKAGES_BY_STATUS_SUCCESS';
export const SEARCH_PACKAGES_BY_STATUS_FAILURE = 'SEARCH_PACKAGES_BY_STATUS_FAILURE';

export const SEARCH_PACKAGES_BY_DESTINATION_REQUEST = 'SEARCH_PACKAGES_BY_DESTINATION_REQUEST';
export const SEARCH_PACKAGES_BY_DESTINATION_SUCCESS = 'SEARCH_PACKAGES_BY_DESTINATION_SUCCESS';
export const SEARCH_PACKAGES_BY_DESTINATION_FAILURE = 'SEARCH_PACKAGES_BY_DESTINATION_FAILURE';

export const SEARCH_PACKAGES_BY_SENDER_RECEIVER_INFO_REQUEST = 'SEARCH_PACKAGES_BY_SENDER_RECEIVER_INFO_REQUEST';
export const SEARCH_PACKAGES_BY_SENDER_RECEIVER_INFO_SUCCESS = 'SEARCH_PACKAGES_BY_SENDER_RECEIVER_INFO_SUCCESS';
export const SEARCH_PACKAGES_BY_SENDER_RECEIVER_INFO_FAILURE = 'SEARCH_PACKAGES_BY_SENDER_RECEIVER_INFO_FAILURE';

export const SEARCH_PACKAGES_BY_DATE_RANGE_REQUEST = 'SEARCH_PACKAGES_BY_DATE_RANGE_REQUEST';
export const SEARCH_PACKAGES_BY_DATE_RANGE_SUCCESS = 'SEARCH_PACKAGES_BY_DATE_RANGE_SUCCESS';
export const SEARCH_PACKAGES_BY_DATE_RANGE_FAILURE = 'SEARCH_PACKAGES_BY_DATE_RANGE_FAILURE';

export const SEARCH_PACKAGES_BY_KEYWORD_OR_DESCRIPTION_REQUEST = 'SEARCH_PACKAGES_BY_KEYWORD_OR_DESCRIPTION_REQUEST';
export const SEARCH_PACKAGES_BY_KEYWORD_OR_DESCRIPTION_SUCCESS = 'SEARCH_PACKAGES_BY_KEYWORD_OR_DESCRIPTION_SUCCESS';
export const SEARCH_PACKAGES_BY_KEYWORD_OR_DESCRIPTION_FAILURE = 'SEARCH_PACKAGES_BY_KEYWORD_OR_DESCRIPTION_FAILURE';

export const GET_COURIERS_REQUEST = 'GET_COURIERS_REQUEST';
export const GET_COURIERS_SUCCESS = 'GET_COURIERS_SUCCESS';
export const GET_COURIERS_FAILURE = 'GET_COURIERS_FAILURE';

// Add new action types for getting courier by code
export const GET_COURIER_BY_CODE_REQUEST = 'GET_COURIER_BY_CODE_REQUEST';
export const GET_COURIER_BY_CODE_SUCCESS = 'GET_COURIER_BY_CODE_SUCCESS';
export const GET_COURIER_BY_CODE_FAILURE = 'GET_COURIER_BY_CODE_FAILURE';

// Add new action types for recently updated packages
export const GET_RECENTLY_UPDATED_PACKAGES_REQUEST = 'GET_RECENTLY_UPDATED_PACKAGES_REQUEST';
export const GET_RECENTLY_UPDATED_PACKAGES_SUCCESS = 'GET_RECENTLY_UPDATED_PACKAGES_SUCCESS';
export const GET_RECENTLY_UPDATED_PACKAGES_FAILURE = 'GET_RECENTLY_UPDATED_PACKAGES_FAILURE';

// Add other action types as needed for search operations
