import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FaBoxOpen, FaBars, FaTimes, FaHome, FaQuestionCircle, FaHeadset, FaUser } from "react-icons/fa";

const NavBar = ({ isAuthenticated, onLogout }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <Navbar>
        <NavContainer>
          <NavLogo exact to="/">
            <NavTitle>Track4u</NavTitle>
          </NavLogo>

          <NavMenu className={click ? "active" : ""}>
            <NavItem>
              <NavLinks exact to="/" activeClassName="active" onClick={handleClick}>
                <NavIconWrapper><FaHome /></NavIconWrapper>
                Home
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks exact to="/couriers" activeClassName="active" onClick={handleClick}>
                <NavIconWrapper><FaBoxOpen /></NavIconWrapper>
                Carriers
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks exact to="/faq" activeClassName="active" onClick={handleClick}>
                <NavIconWrapper><FaQuestionCircle /></NavIconWrapper>
                FAQ
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks exact to="/help/center/all" activeClassName="active" onClick={handleClick}>
                <NavIconWrapper><FaHeadset /></NavIconWrapper>
                Help
              </NavLinks>
            </NavItem>
            {isAuthenticated ? (
              <>
                <NavItem>
                  <NavLinks exact to="/dashboard" activeClassName="active" onClick={handleClick}>
                    <NavIconWrapper><FaUser /></NavIconWrapper>
                    Profile
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <LogoutButton as="div" onClick={onLogout}>
                    Logout
                  </LogoutButton>
                </NavItem>
              </>
            ) : (
              <NavItem>
                <LoginButton exact to="/login" activeClassName="active" onClick={handleClick}>
                  Login
                </LoginButton>
              </NavItem>
            )}
          </NavMenu>

          <NavToggleIcon onClick={handleClick}>
            {click ? <FaTimes className="icon" /> : <FaBars className="icon" />}
          </NavToggleIcon>
        </NavContainer>
      </Navbar>
    </>
  );
};

// Styled Components
const Navbar = styled.nav`
  height: 80px;
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  position: relative;
`;

const NavLogo = styled(NavLink)`
  color: #000;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
`;

const NavTitle = styled.span`
  font-weight: 700;
`;

const NavMenu = styled.ul`
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    text-align: left;
    width: 40%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;

    &.active {
      background: #fff;
      left: 0;
      opacity: 1;
      z-index: 1;
    }
  }
`;

const NavItem = styled.li`
 line-height: 40px;
  margin-right: 1.8rem;

  &:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }

  &:hover:after {
    width: 100%;
    background: #3b0086;
  }

  .active {
    color: #3b0086;
    border: 1px solid #3b0086;
  }
`
  
;

const NavLinks = styled(NavLink)`
  color: black;
  font-weight: 700;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;

  @media screen and (max-width: 960px) {
    padding: 1rem;
    margin-right: 5px;
    width: 100%;
    display: table;
  }
`
  
;



const LoginButton = styled(NavLinks)`
  background-color: #3b0086;
  color: white;
  border-radius: 5px;
  height: 20px;
  padding: 10px;
  display: flex;
  align-items: center; /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
  font-weight: 700;
  text-decoration: none;

  &:hover {
    background-color: #2b0065;
  }
`;

const LogoutButton = styled.div`
  background-color: #3b0086;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  height: 20px;
  padding: 10px;
  display: flex;
  align-items: center; /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
  cursor: pointer;

  &:hover {
    background-color: #2b0065;
  }
`;

const NavToggleIcon = styled.div`
  display: none;
  cursor: pointer;
  color: #3b0086;

  .icon {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(-50%, -50%);
  }
`;

const NavIconWrapper = styled.span`
  display: none;
  margin-right: 8px;

  @media screen and (max-width: 960px) {
    display: inline-block;
  }
`;

export default NavBar;
