// HomePage.js
import React from 'react';
import HeroSection from '../components/home/HeroSection';
import FeaturedContent from '../components/home/FeaturedContent';
import AboutUsSection from '../components/home/AboutUsSection';
import TestimonialsSection from '../components/home/TestimonialsSection';
import FeaturesServicesSection from '../components/home/FeaturesServicesSection';
import CTASections from '../components/home/CTASections';
import ImageGalleries from '../components/home/ImageGalleries';
import StatisticsCounters from '../components/home/StatisticsCounters';
import BlogNewsSection from '../components/home/BlogNewsSection';
import ClientLogos from '../components/home/ClientLogos';
import styled from 'styled-components';
import Pricing from '../components/home/Pricing';
import Header from '../components/common/Header';
import Footer from '../components/home/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAction } from '../store/actions/authActions';


const Container = styled.section`
  display: flex;
  flex-direction: column;
  height:100vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    width:100%;
  }
`;

const HeroContainer = styled.section`
  width: 100%;
  margin-bottom: 30px;
`;

const PricingContainer = styled.section`
  width: 100%;
  background-color: #3b0086;
  padding: 80px 0;

  @media (max-width: 1024px) {
    padding: 60px 20px;
  }

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;



const HomePage = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleLogout = () => {
    dispatch(logoutUserAction());
  };

  return (
      <Container>
        <Header isAuthenticated={isAuthenticated} onLogout={handleLogout} />
        <HeroContainer>
          <HeroSection />
        </HeroContainer>
        
        <FeaturedContent />
        <PricingContainer>
           <Pricing/>
        </PricingContainer>
        
        <AboutUsSection />
        <TestimonialsSection />
        <FeaturesServicesSection />
        <Footer/>
      </Container>
  );
};

export default HomePage;
