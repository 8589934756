// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import PackageFormPage from './pages/PackageFormPage';
import PackageInfoPage from './pages/PackagesInfoPage';
import HistoryPage from './pages/HistoryPage';
import EmailFormPage from './pages/EmailFormPage';
import PreviewTemplate from './components/email/PreviewTemplate';
import ProfilePage from './pages/ProfilePage';
import CheckoutPage from './pages/CheckoutPage';
import OrderSummaryPage from './pages/OrderSummaryPage';
import PrivateRoute from './components/common/PrivateRoute'; 
import Sidebar from './components/common/Sidebar';
import CourierListPage from './pages/CourierListPage';
import SubscriptionPage from './pages/SubscriptionPage';
import PaymentListPage from './pages/PaymentsPage';
import SettingPage from './pages/SettingPage';
import FeedbackPage from './pages/FeedbackPage';
import HelpCenterPage from './pages/HelpCenterPage';
import PackageArchivePage from './pages/PackageArchivePage';
import MainPage from './pages/Mainpage';
import FeaturesPage from './pages/FeaturesPage';
import CarrierPage from './pages/CarrierPage';
import FaqPage from './pages/Faq';
import AboutUsPage from './pages/AboutUsPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import HelpMainPage from './pages/HelpMainCenter';
import GeneralArticles from './pages/help/GeneralArticles';
import BillingArticles from './pages/help/BillingArticles';
import ShippingArticles from './pages/help/ShippingArticles';
import PackageTestPage from './pages/PackageTest';
import PackageDetailsPage from './pages/PackageDetailsPage';
import CashAppCheckoutPage from './pages/CashAppCheckoutPage';
import GeneralArticlesPage from './pages/GeneralArticlesPage';
import ShippingArticlesPage from './pages/ShippingArticlesPage';
import BillingArticlesPage from './pages/BillingArticlesPage';
import PaymentConfirmationPage from './pages/PaymentSuccessPage';
import TicketPage from './pages/help/TicketPage';



const MainLayout = ({ children }) => (
  
  <div style={{ display: 'flex' }}>
    <Sidebar />
    <Container>
      {children}
    </Container>
  </div>
);

const App = () => {
  return (
    <PageContainer>
      <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/main" element={<MainPage />} />
        
        <Route path="/login" element={<LoginPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/couriers" element={<CarrierPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/cookie/policy" element={<CookiePolicyPage />} />
        <Route path="/privacy/policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/features" element={<FeaturesPage />} />
        
        <Route path="/templates" element={<PreviewTemplate />} />
        <Route path="/template/create" element={<EmailFormPage />} />
        <Route path="/help/center/all" element={<HelpMainPage />} />
        <Route path="/help/category/general" element={<GeneralArticlesPage />} />
        <Route path="/help/category/shipping" element={<ShippingArticlesPage />} />
        <Route path="/help/category/billing" element={<BillingArticlesPage />} />
        <Route path="/payment/confirmation" element={<PaymentConfirmationPage />} />

        
        {/* Routes with Sidebar */}
        {/* Protected Routes */}
        <Route path="/dashboard" element={<PrivateRoute><MainLayout><ProfilePage /></MainLayout></PrivateRoute>} />
          <Route path="/checkout" element={<PrivateRoute><MainLayout><CheckoutPage /></MainLayout></PrivateRoute>} />
          <Route path="/checkout/cashapp" element={<PrivateRoute><MainLayout><CashAppCheckoutPage /></MainLayout></PrivateRoute>} />
          <Route path="/order" element={<PrivateRoute><MainLayout><OrderSummaryPage /></MainLayout></PrivateRoute>} />
          <Route path="/subscription/plans" element={<PrivateRoute><MainLayout><SubscriptionPage /></MainLayout></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><MainLayout><SettingPage /></MainLayout></PrivateRoute>} />
          <Route path="/payments/all" element={<PrivateRoute><MainLayout><PaymentListPage /></MainLayout></PrivateRoute>} />
          <Route path="/package/create" element={<PrivateRoute><MainLayout><PackageFormPage /></MainLayout></PrivateRoute>} />
          <Route path="/package/test" element={<PrivateRoute><MainLayout><PackageInfoPage /></MainLayout></PrivateRoute>} />
          <Route path="/packages/all" element={<PrivateRoute><MainLayout><PackageTestPage /></MainLayout></PrivateRoute>} />
          <Route path="/package/:id" element={<PrivateRoute><MainLayout><PackageDetailsPage /></MainLayout></PrivateRoute>} />
          <Route path="/archives/all" element={<PrivateRoute><MainLayout><PackageArchivePage /></MainLayout></PrivateRoute>} />
          
          <Route path="/couriers/all" element={<PrivateRoute><MainLayout><CourierListPage /></MainLayout></PrivateRoute>} />
          <Route path="/feedback/create" element={<PrivateRoute><MainLayout><FeedbackPage /></MainLayout></PrivateRoute>} />
          <Route path="/help/center" element={<PrivateRoute><MainLayout><HelpCenterPage /></MainLayout></PrivateRoute>} />
          <Route path="/ticket" element={<PrivateRoute><MainLayout><TicketPage /></MainLayout></PrivateRoute>} />
          <Route path="/dash/help/category/general" element={<PrivateRoute><MainLayout><GeneralArticles /></MainLayout></PrivateRoute>} />
          <Route path="/dash/help/category/shipping" element={<PrivateRoute><MainLayout><ShippingArticles /></MainLayout></PrivateRoute>} />
          <Route path="/dash/help/category/billing" element={<PrivateRoute><MainLayout><BillingArticles /></MainLayout></PrivateRoute>} />
      </Routes>
    </Router>

    </PageContainer>
      

    
    
  );
};

// Styled container for pages
const PageContainer = styled.div`
  height: 100vh;
  overflow-y:hidden;
  
`;

const Container = styled.div`
 width: 100%;
`

export default App;
