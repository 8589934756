import React from 'react';
import styled from 'styled-components';

// Styled Components
const HeroSection = styled.section`
  background: #3f37c9;
  padding-top: 180px;

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding-top: 160px;
  }

  @media (max-width: 767px) {
    padding-top: 130px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

const Column = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const HeroContent = styled.div`
  max-width: 780px;
  margin: auto;
  margin-bottom: 30px;
  text-align: center;

  .wow {
    visibility: visible;
    animation-name: fadeInUp;
  }
`;

const HeroTitle = styled.h1`
  color: var(--white);
  font-weight: 700;
  font-size: 45px;
  line-height: 60px;
  margin-bottom: 30px;

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 38px;
    line-height: 50px;
  }

  @media (max-width: 767px) {
    font-size: 26px;
    line-height: 38px;
  }
`;

const HeroDesc = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  color: var(--white);
  opacity: 0.8;
  max-width: 600px;
  margin: auto;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 30px;
  }
`;

const HeroButtons = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  .ud-main-btn {
    margin: 8px;

    @media (max-width: 767px) {
      margin: 5px;
      padding: 12px 16px;
    }
  }
`;

const MainButton = styled.a`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  padding: 15px 25px;
  border: 1px solid transparent;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-out;

  &.ud-white-btn {
    background: var(--white);
    color: var(--heading-color);

    &:hover {
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
      background: #fefefe;
      color: var(--primary-color);
    }
  }

  &.ud-link-btn:hover {
    color: var(--white);
    background: transparent;
    opacity: 0.5;
  }
`;

const HeroBrandsWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;

  img {
    transition: all 0.3s;
    opacity: 0.5;
    max-width: 250px;

    &:hover {
      opacity: 1;
    }
  }
`;

const HeroImage = styled.div`
  max-width: 845px;
  margin: auto;
  text-align: center;
  position: relative;
  z-index: 1;

  img {
    max-width: 100%;
  }

  .shape {
    position: absolute;
    z-index: -1;

    &.shape-1 {
      bottom: 0;
      left: -30px;
    }

    &.shape-2 {
      top: -20px;
      right: -20px;
    }
  }
`;

const Hero = () => {
  return (
    <HeroSection id="home">
      <Container>
        <Row>
          <Column>
            <HeroContent className="wow fadeInUp" data-wow-delay=".2s">
              <HeroTitle>
                Open-Source Web Template for SaaS, Startup, Apps, and More
              </HeroTitle>
              <HeroDesc>
                Multidisciplinary Web Template Built with Your Favourite
                Technology - HTML Bootstrap, Tailwind and React NextJS.
              </HeroDesc>
              <HeroButtons>
                <li>
                  <MainButton
                    href="https://links.uideck.com/play-bootstrap-download"
                    rel="nofollow noopener"
                    target="_blank"
                    className="ud-main-btn ud-white-btn"
                  >
                    Download Now
                  </MainButton>
                </li>
                <li>
                  <MainButton
                    href="https://github.com/uideck/play-bootstrap"
                    rel="nofollow noopener"
                    target="_blank"
                    className="ud-main-btn ud-link-btn"
                  >
                    Learn More <i className="lni lni-arrow-right"></i>
                  </MainButton>
                </li>
              </HeroButtons>
            </HeroContent>
            <HeroBrandsWrapper className="wow fadeInUp" data-wow-delay=".3s">
              <img src="assets/images/hero/brand.svg" alt="brand" />
            </HeroBrandsWrapper>
            <HeroImage className="wow fadeInUp" data-wow-delay=".25s">
              <img src="assets/images/hero/hero-image.svg" alt="hero-image" />
              <img
                src="assets/images/hero/dotted-shape.svg"
                alt="shape"
                className="shape shape-1"
              />
              <img
                src="assets/images/hero/dotted-shape.svg"
                alt="shape"
                className="shape shape-2"
              />
            </HeroImage>
          </Column>
        </Row>
      </Container>
    </HeroSection>
  );
};

export default Hero;
