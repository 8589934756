import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByIdAction, updateAddressAction } from '../../store/actions/userActions'; // Import the action
import { FaTimes } from 'react-icons/fa'; // Import close icon

// Styled Components
const Container = styled.div`
  width: 100%;
  max-width: 600px;
`;

const FormSector = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
`;

const Form = styled.form`
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #3c096c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ConfirmButton = styled.button`
  background-color: #6200b3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
`;

const BillingAddress = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user);
  const user = useSelector((state) => state.user.selectedUser);

  const [formData, setFormData] = useState({
    street: '',
    city: '',
    postalCode: '',
    state: '',
    country: '',
  });

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(getUserByIdAction(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (user && user.address) {
      setFormData({
        street: user.address.street || '',
        city: user.address.city || '',
        postalCode: user.address.postalCode || '',
        state: user.address.state || '',
        country: user.address.country || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setModalVisible(true);
  };

  const handleConfirmChange = () => {

    const address = {
        street: formData.street,
        city: formData.city,
        zipCode: formData.postalCode,
        state: formData.state,
        country: formData.country,
      };
  
      const addressData = {
        fullName: user.fullName,
        phoneNumber: user.phoneNumber,
        address
      };
   

    // Dispatch the update address action
    dispatch(updateAddressAction(userId, addressData));

    // Hide the confirmation modal
    setModalVisible(false);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <FormSector>
          <FormGroup>
            <Label>Street</Label>
            <Input
              type="text"
              name="street"
              value={formData.street}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>City</Label>
            <Input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </FormGroup>
        </FormSector>

        <FormSector>
          <FormGroup>
            <Label>Postal Code</Label>
            <Input
              type="text"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>State</Label>
            <Input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Country</Label>
            <Input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
            />
          </FormGroup>
        </FormSector>

        <Button type="submit">Save Address</Button>
      </Form>

      {modalVisible && (
        <ModalContainer>
          <ModalContent>
            <CloseButton onClick={() => setModalVisible(false)}>
              <FaTimes size={24} color="#666" />
            </CloseButton>
            <ModalTitle>Confirm Address Update</ModalTitle>
            <p>Are you sure you want to update your address?</p>
            <ConfirmButton onClick={handleConfirmChange}>Confirm</ConfirmButton>
          </ModalContent>
        </ModalContainer>
      )}
    </Container>
  );
};

export default BillingAddress;
