import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px; /* Reduced padding */
  margin: 8px;
  height: 60px; /* Reduced height */
  width: 300px; /* Reduced width */
`;

const CourierLogo = styled.img`
  width: 40px; /* Reduced logo size */
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
`;

const CourierInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CourierName = styled.h3`
  font-size: 16px; /* Slightly smaller font */
  margin: 0;
`;

const CourierType = styled.p`
  font-size: 12px; /* Slightly smaller font */
  margin: 4px 0;
`;

const CountryCode = styled.p`
  font-size: 12px;
  margin: 4px 0;
  color: #666;
`;

const CourierCard = ({ courier }) => {
  const logoUrl = courier.courier_logo.startsWith('//') ? `https:${courier.courier_logo}` : courier.courier_logo;

  return (
    <Card>
      <CourierLogo src={logoUrl} alt={`${courier.courier_name} logo`} />
      <CourierInfo>
        <CourierName>{courier.courier_name}</CourierName>
        <CourierType>{courier.courier_type}</CourierType>
        <CountryCode>{courier.country_code}</CountryCode>
      </CourierInfo>
    </Card>
  );
};

export default CourierCard;
