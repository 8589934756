import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { fetchPaymentsByUserAction } from '../store/actions/paymentActions';
import { FaCalendarAlt, FaHashtag, FaCreditCard, FaDollarSign, FaLayerGroup } from 'react-icons/fa';
import DashHeader from '../components/common/DashHeader';
import LoadingComponent from '../components/common/loading';

const Container = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  height: 100vh;

  @media (max-width: 768px) {
    margin-top: 50px; /* Increased margin for mobile screens */
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const TableContainer = styled.div`
  display: none;
 background-color: #fff;
  @media (min-width: 768px) {
    display: block;
    max-height: 400px;
    overflow-y: auto;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background: #3b0086;
  color: #fff;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #f7f7f7;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 70vh;
  overflow-y:auto;
  

  @media (min-width: 768px) {
    display: none;
  }
`;

const Card = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative; /* Added for proper positioning of StatusIndicator */
`;

const CardField = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Added consistent space between icon and text */
  font-size: 14px; /* Adjusted for readability */
  color: #555; /* Softer color for text */

  
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 10px;
  margin: 0 5px;
  background: #3c096c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background: #ddd;
    cursor: not-allowed;
  }
`;

const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 12px;
  background-color: #f5f5f5;
  color: #000;
  text-transform: capitalize;
`;

const StatusDot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;



const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
`;

const FieldValue = styled.span`
  font-weight: bold;
  color: #333;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #3b0086;
    margin: 0;
  }
`;
const CardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 15px;
`;



const statusColors = {
  paid: 'green',
  pending: 'orange',
  failed: 'red',
  cancelled: 'blue',
};

const Field = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PaymentListPage = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user);
  const payments = useSelector((state) => state.payments.payments);
  const loading = useSelector((state) => state.payments.loading);
  const error = useSelector((state) => state.payments.error);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const paymentsPerPage = 15;

  useEffect(() => {
    dispatch(fetchPaymentsByUserAction(userId));
  }, [dispatch, userId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredPayments = payments.filter(
    (payment) =>
      payment.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      payment.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  

  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = filteredPayments.slice(indexOfFirstPayment, indexOfLastPayment);

  const totalPages = Math.ceil(filteredPayments.length / paymentsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) return (<LoadingComponent/>);
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <DashHeader/>
      <Container>
      <Title>All Payments</Title>
      <SearchInput
        type="text"
        placeholder="Search by order number or status..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>Date</TableHeader>
              <TableHeader>Order Number</TableHeader>
              <TableHeader>Method</TableHeader>
              <TableHeader>Plan</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Amount</TableHeader>
            </tr>
          </thead>
          <tbody>
            {currentPayments.map((payment) => (
              <TableRow key={payment._id}>
                <TableCell>{new Date(payment.timestamp).toLocaleDateString()}</TableCell>
                <TableCell>{payment.orderNumber || 'N/A'}</TableCell>
                <TableCell>{payment.paymentGateway || 'N/A'}</TableCell>
                <TableCell>{payment.planId?.name || 'N/A'}</TableCell>
                <TableCell>{payment.status || 'N/A'}</TableCell>
                <TableCell>{payment.amount || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <CardContainer>
      {filteredPayments.length === 0 ? (
  <p>No payments found.</p>
) : (
  filteredPayments.map((payment) => (
    <Card key={payment._id}>
      <CardHeader>
        <h3>Order: {payment.orderNumber || 'N/A'}</h3>
        <StatusIndicator>
          <StatusDot color={statusColors[payment.status]} />
          {payment.status || 'Unknown'}
        </StatusIndicator>
      </CardHeader>
      <CardBody>
        <CardField>
          <FaCalendarAlt />
          <span>{new Date(payment.timestamp).toLocaleDateString()}</span>
        </CardField>
        <CardField>
          <FaCreditCard />
          <span>{payment.paymentGateway || 'N/A'}</span>
        </CardField>
        <CardField>
          <FaLayerGroup />
          <span>{payment.planId?.name || 'N/A'}</span>
        </CardField>
        <CardField>
          <FaDollarSign />
          <span>${payment.amount || 'N/A'}</span>
        </CardField>
      </CardBody>
    </Card>
  ))
)}
      </CardContainer>
      <PaginationControls>
        <PageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </PageButton>
        <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </PageButton>
      </PaginationControls>
    </Container>
    </>
  );
};

  
export default PaymentListPage;
