import React from 'react';
import styled from 'styled-components';

// Styled Components
const TestimonialsSection = styled.section`
  padding-top: 120px;
  padding-bottom: 90px;

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 50px;
  }
`;

const SingleTestimonial = styled.div`
  padding: 30px;
  box-shadow: 0px 60px 120px -20px #ebeffd;
  background: var(--white);
  margin-bottom: 50px;
`;

const TestimonialRatings = styled.div`
  margin-bottom: 10px;

  i {
    color: #fbb040;
  }
`;

const TestimonialContent = styled.div`
  margin-bottom: 25px;

  p {
    font-size: 16px;
    line-height: 30px;
    color: #637381;
  }
`;

const TestimonialInfo = styled.div`
  display: flex;
  align-items: center;
`;

const TestimonialImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const TestimonialMeta = styled.div`
  h4 {
    font-weight: 600;
    font-size: 14px;
  }

  p {
    font-size: 12px;
    color: #969696;
  }
`;

const BrandsSection = styled.div`
  margin-bottom: 30px;

  h6 {
    font-weight: normal;
    font-size: 12px;
    color: var(--body-color);
    display: inline-block;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 1px;
      background: #afb2b5;
      right: -40px;
      top: 50%;
    }
  }
`;

const BrandsLogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -40px;
`;

const SingleLogo = styled.div`
  margin-right: 40px;
  margin-bottom: 20px;
  max-width: 140px;

  img {
    filter: grayscale(1);
    transition: all 0.3s ease-out;

    &:hover {
      filter: none;
    }
  }
`;

const TestimonialsSectionComponent = () => {
    return (
      <TestimonialsSection id="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-section-title mx-auto text-center">
                <span>Testimonials</span>
                <h2>What our Customers Say</h2>
                <p>
                  There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>
  
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <SingleTestimonial className="wow fadeInUp" data-wow-delay=".1s">
                <TestimonialRatings>
                  <i className="lni lni-star-filled"></i>
                  <i className="lni lni-star-filled"></i>
                  <i className="lni lni-star-filled"></i>
                  <i className="lni lni-star-filled"></i>
                  <i className="lni lni-star-filled"></i>
                </TestimonialRatings>
                <TestimonialContent>
                  <p>
                    “Our members are so impressed. It's intuitive. It's clean.
                    It's distraction free. If you're building a community.
                  </p>
                </TestimonialContent>
                <TestimonialInfo>
                  <TestimonialImage>
                    <img src="assets/images/testimonials/author-01.png" alt="author" />
                  </TestimonialImage>
                  <TestimonialMeta>
                    <h4>Sabo Masties</h4>
                    <p>Founder @UIdeck</p>
                  </TestimonialMeta>
                </TestimonialInfo>
              </SingleTestimonial>
            </div>
  
            {/* Repeat similar structure for other testimonials */}
          </div>
  
          <div className="row">
            <div className="col-lg-12">
              <BrandsSection className="wow fadeInUp" data-wow-delay=".2s">
                <div className="ud-title">
                  <h6>Trusted and Used by</h6>
                </div>
                <BrandsLogoContainer>
                  <SingleLogo>
                    <img src="assets/images/brands/ayroui.svg" alt="ayroui" />
                  </SingleLogo>
                  <SingleLogo>
                    <img src="assets/images/brands/uideck.svg" alt="uideck" />
                  </SingleLogo>
                  <SingleLogo>
                    <img src="assets/images/brands/graygrids.svg" alt="graygrids" />
                  </SingleLogo>
                  <SingleLogo>
                    <img src="assets/images/brands/lineicons.svg" alt="lineicons" />
                  </SingleLogo>
                  <SingleLogo>
                    <img src="assets/images/brands/ecommerce-html.svg" alt="ecommerce-html" />
                  </SingleLogo>
                </BrandsLogoContainer>
              </BrandsSection>
            </div>
          </div>
        </div>
      </TestimonialsSection>
    );
  };
  
  export default TestimonialsSectionComponent;
  