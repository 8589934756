import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { ClockCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledTimeline = styled(Timeline)`
   && {
    
    
  }
  
`;

const PurpleTimelineDot = styled(TimelineDot)`
  && {
    background-color: #9C27B0; // Purple color
  }
`;

const CustomTimelineContent = styled(TimelineContent)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`


const PackageTimeline = ({ trackinfo }) => {
    // Sort checkpoints by date in ascending order
    const sortedCheckpoints = trackinfo.sort((a, b) =>
      new Date(b.checkpoint_date) - new Date(a.checkpoint_date)
    );
  
    console.log(sortedCheckpoints);
  
    return (
      <div >
        <StyledTimeline 
           sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
        },
      }} >
        {sortedCheckpoints.map((checkpoint, index) => (
          <TimelineItem key={index}  classes={{ root: timelineItemClasses.root }}>
            <TimelineSeparator>
              <PurpleTimelineDot>
                <ClockCircleOutlined style={{ fontSize: '18px', color: '#fff' }} />
              </PurpleTimelineDot>
              {/* Add TimelineConnector to connect dots except for the last item */}
              {index < sortedCheckpoints.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <CustomTimelineContent>
              <p> {checkpoint.checkpoint_date}</p>
              <p> {checkpoint.tracking_detail}</p>
              <p> {checkpoint.location}</p>
            </CustomTimelineContent>
          </TimelineItem>
        ))}
      </StyledTimeline>
      </div>
    );
  };


export default PackageTimeline;
