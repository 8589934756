// TestimonialCard.js
import React from 'react';
import styled from 'styled-components';
import { StarFilled } from '@ant-design/icons'; // Assuming you are using Ant Design for icons

const CardContainer = styled.div`
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const UserImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    color: #fadb14;
    margin-right: 4px;
  }
`;

const TestimonialCard = ({ userImage, userName, userLocation, rating, testimonial }) => {
  return (
    <CardContainer>
      <UserImage src={userImage} alt={`${userName}'s Photo`} />
      <h3>{userName}</h3>
      <p>{userLocation}</p>
      <RatingContainer>
        {Array.from({ length: rating }, (_, index) => (
          <StarFilled key={index} />
        ))}
      </RatingContainer>
      <p>{testimonial}</p>
    </CardContainer>
  );
};

export default TestimonialCard;
