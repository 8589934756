import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { TiTick } from 'react-icons/ti';
import { FaTruck, FaLock, FaChartLine, FaMapMarkerAlt, FaBell, FaTachometerAlt, FaShippingFast, FaSearch } from 'react-icons/fa';

const FeatureContainer = styled(Box)`
  width: 100%;
  padding: 80px 0;

  @media (max-width: 600px) {
    padding: 60px 0;
  }
`;

const FeatureTitle = styled(Typography)`
  text-align: center;
  margin-bottom: 40px !important;
`;

const SupportingText = styled(Typography)`
  text-align: center;
  margin-bottom: 40px !important;
  color: #757575;
`;

const FeatureItemContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const IconContainer = styled(Box)`
  background-color: skyblue;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const FeatureIcon = styled(Box)`
  font-size: 32px;
  color: #1976d2;
`;

const FeatureDescription = styled(Typography)`
  color: #757575;
`;

const features = [
  { icon: <FaMapMarkerAlt />, title: 'Real-time Package Tracking', description: 'Track your packages in real-time with our easy-to-use interface. Get instant updates on package movements, estimated delivery times, and current statuses directly from carriers.' },
  { icon: <FaBell />, title: 'Automated Notifications', description: 'Receive automated notifications via email or SMS about your package status. Stay informed without having to manually check for updates. Customize notification preferences to receive alerts for specific events.' },
  { icon: <FaTachometerAlt />, title: 'Comprehensive Dashboard', description: 'View all your package information in one centralized dashboard. Monitor multiple shipments, view historical tracking data, and analyze delivery trends.' },
  { icon: <FaShippingFast />, title: 'Support for Multiple Carriers', description: 'Our service supports a wide range of shipping carriers including UPS, FedEx, DHL, USPS, and more. Seamlessly track packages from different carriers using a unified platform.' },
  { icon: <FaSearch />, title: 'Advanced Search & Filtering', description: 'Easily find specific packages with advanced search and filtering options. Search by tracking number, recipient name, delivery date, or shipment status.' },
  { icon: <FaLock />, title: 'Secure and Reliable', description: 'We prioritize the security and reliability of your package tracking data. Our platform employs industry-standard encryption protocols to protect sensitive information.' },
];

const FeatureHighlights = () => {
  return (
    <FeatureContainer id="features">
      <Container>
        <FeatureTitle variant="h3">Key Features</FeatureTitle>
        <SupportingText variant="body1">
          Explore the features that make package tracking easy and secure with us.
        </SupportingText>
        <Grid container spacing={3}>
          {features.map((item, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <FeatureItemContainer>
                <IconContainer>
                  <FeatureIcon>{item.icon}</FeatureIcon>
                </IconContainer>
                <CardHeader
                  title={item.title}
                  titleTypographyProps={{ variant: 'h6', color: '#1976d2', align: 'left' }}
                />
                <CardContent>
                  <FeatureDescription variant="body1">{item.description}</FeatureDescription>
                </CardContent>
              </FeatureItemContainer>
            </Grid>
          ))}
        </Grid>
      </Container>
    </FeatureContainer>
  );
};

export default FeatureHighlights;
