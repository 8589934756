// actionTypes.js
export const GET_PAYMENT_LINKS_REQUEST = 'GET_PAYMENT_LINKS_REQUEST';
export const GET_PAYMENT_LINKS_SUCCESS = 'GET_PAYMENT_LINKS_SUCCESS';
export const GET_PAYMENT_LINKS_FAILURE = 'GET_PAYMENT_LINKS_FAILURE';

export const GET_PAYMENT_LINK_REQUEST = 'GET_PAYMENT_LINK_REQUEST';
export const GET_PAYMENT_LINK_SUCCESS = 'GET_PAYMENT_LINK_SUCCESS';
export const GET_PAYMENT_LINK_FAILURE = 'GET_PAYMENT_LINK_FAILURE';

export const CREATE_PAYMENT_LINK_REQUEST = 'CREATE_PAYMENT_LINK_REQUEST';
export const CREATE_PAYMENT_LINK_SUCCESS = 'CREATE_PAYMENT_LINK_SUCCESS';
export const CREATE_PAYMENT_LINK_FAILURE = 'CREATE_PAYMENT_LINK_FAILURE';

export const UPDATE_PAYMENT_LINK_REQUEST = 'UPDATE_PAYMENT_LINK_REQUEST';
export const UPDATE_PAYMENT_LINK_SUCCESS = 'UPDATE_PAYMENT_LINK_SUCCESS';
export const UPDATE_PAYMENT_LINK_FAILURE = 'UPDATE_PAYMENT_LINK_FAILURE';

export const DELETE_PAYMENT_LINK_REQUEST = 'DELETE_PAYMENT_LINK_REQUEST';
export const DELETE_PAYMENT_LINK_SUCCESS = 'DELETE_PAYMENT_LINK_SUCCESS';
export const DELETE_PAYMENT_LINK_FAILURE = 'DELETE_PAYMENT_LINK_FAILURE';
