import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import OrderSummary from '../components/payments/orderSummary';
import PaymentButtons from '../components/payments/PaymentButtons';
import BillingInfoForm from '../components/payments/BillingForm';
import { createPaymentAction, createPaymentIntentAction } from '../store/actions/paymentActions';
import { getUserByIdAction, updateAddressAction } from '../store/actions/userActions';
import { fetchSubscriptionPlanDetailsAction } from '../store/actions/subscriptionPlanActions';
import { getAllPaymentMethodsAction, selectPaymentMethodAction } from '../store/actions/paymentMethodActions';
import { getPaymentLinks } from '../store/actions/paymentLinkActions';
import { fetchUsedTracksAction } from '../store/actions/subscriptionActions';
import DashHeader from '../components/common/DashHeader';
import LoadingComponent from '../components/common/loading';


const OrderSummaryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPlan = useSelector((state) => state.plans.selectedPlan);
  const activePlanId = useSelector((state) => state.subscription.activePlan);
  const paymentMethods = useSelector((state) => state.paymentMethod.paymentMethods);
  const MethodSaved = useSelector((state) => state.paymentMethod.selectedPaymentMethod);
  const paymentLinks = useSelector((state) => state.link.links);
  const userId = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.plans.loading);
  const error = useSelector((state) => state.plans.error);
  const user = useSelector((state) => state.user.selectedUser);

 

  const [billingInfo, setBillingInfo] = useState({
    fullName: '',
    street: '',
    city: '',
    zipCode: '',
    state: '',
    country: '',
    phoneNumber: ''
  });

  const [selectedMethod, setSelectedMethod] = useState(null);

  useEffect(() => {
    if (userId) {
      dispatch(getUserByIdAction(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (userId) {
        dispatch(fetchUsedTracksAction(userId))
    }
})

  useEffect(() => {
    dispatch(getAllPaymentMethodsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPaymentLinks());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedPlan && activePlanId) {
      dispatch(fetchSubscriptionPlanDetailsAction(activePlanId));
    }
  }, [selectedPlan, activePlanId, dispatch]);

  useEffect(() => {
    if (user) {
      setBillingInfo({
        fullName: user.fullName || '',
        street: user.address?.street || '',
        city: user.address?.city || '',
        zipCode: user.address?.postalCode || '',
        state: user.address?.state || '',
        country: user.address?.country || '',
        phoneNumber: user.phoneNumber ? user.phoneNumber.toString() : ''
      });
    }
  }, [user]);

   // Dispatch the selectPaymentMethodAction when selecting a payment method
   const handleSelectPaymentMethod = (paymentMethod) => {
    setSelectedMethod(paymentMethod); // Ensure this is updating correctly
    dispatch(selectPaymentMethodAction(paymentMethod));
    
  };

  const handlePayment = async () => {
   
    const { fullName, street, city, zipCode, state, country, phoneNumber } = billingInfo;
  
    if (!fullName || !street || !city || !state || !country || !phoneNumber) {
      alert('Please fill in all fields');
      return;
    }
  
    if (!selectedPlan) {
      alert('Subscription plan details are not available. Please try again later.');
      return;
    }
  
    if (MethodSaved.type.toLowerCase() === 'link'){
      
      const planPaymentLink = paymentLinks.find(link => link.planId === selectedPlan._id && link.status === 'active');
    
  
    if (planPaymentLink) {
      const address = {
        street,
        city,
        zipCode,
        state,
        country
      };
  
      const addressData = {
        fullName,
        phoneNumber,
        address
      };
  
      const paymentData = {
        userId,
        planId: selectedPlan._id,
        amount: selectedPlan.monthlyPrice,
        currency: selectedPlan.currency,
        name: fullName,
        phone: phoneNumber,
        address: addressData.address,
        paymentLinkId: planPaymentLink._id
      };
  
      await dispatch(updateAddressAction(userId, addressData));
      await dispatch(createPaymentAction(paymentData));
  
      window.location.href = planPaymentLink.paymentLink;
      return;
    }
    }
    
  
    if (!MethodSaved) {
      alert('Please select a payment method');
      return;
    }
  
    const address = {
      street,
      city,
      zipCode,
      state,
      country
    };
  
    const addressData = {
      fullName,
      phoneNumber,
      address
    };
  
    const paymentData = {
      userId,
      planId: selectedPlan._id,
      amount: selectedPlan.monthlyPrice,
      currency: selectedPlan.currency,
      name: fullName,
      phone: phoneNumber,
      address: addressData.address,
      paymentMethodId: MethodSaved._id
    };

    await dispatch(createPaymentAction(paymentData));
    await dispatch(updateAddressAction(userId, addressData));
  
    if (MethodSaved.type.toLowerCase() === 'stripe') {
      
      await dispatch(createPaymentIntentAction(paymentData));
    }

    
  
    // Redirect based on the type of payment method selected
    if (MethodSaved.type.toLowerCase().includes('stripe')) {
      
      navigate('/checkout');
    } else if (MethodSaved.type.toLowerCase().includes('crypto')) {
      
      navigate('/checkout/crypto');
    } else if (MethodSaved.type.toLowerCase().includes('cashapp')) {
      
      navigate('/checkout/cashapp');
    }
  };

  

  if (loading) {
    return <LoadingComponent/>;
  }

  if (error) {
    return <ErrorMessage>Error loading order summary. Please try again later.</ErrorMessage>;
  }

  return ( 
    <>
    <DashHeader/>
      <Container>
      <BillingInfoForm billingInfo={billingInfo} setBillingInfo={setBillingInfo} />
      <OrderSummaryPageContainer>
        <OrderSummary selectedPlan={selectedPlan} />
        <PaymentButtons
          paymentMethods={paymentMethods}
          selectedMethod={selectedMethod}
          onSelectPaymentMethod={handleSelectPaymentMethod}
        />
        <Button onClick={handlePayment}>Pay Now</Button>
      </OrderSummaryPageContainer>
    </Container>
    </>
  );
};


const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  flex-wrap:wrap;
  background-color:#f5f5f5;
  padding: 15px;
  height:100vh;
  width:100%;
  overflow-x:hidden;
  overflow-y:auto;

  
`;

const OrderSummaryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  margin-top: 10px;
  width: 45%;
  overflow-y:auto;
  
   @media (max-width: 768px) {
    width: 95%;
     margin-top: 2px;
    padding-bottom: 50px;
  }

`;

const Button = styled.button`
  margin-top: 2rem;
  background-color: #6200b3;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  width: 90%;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #4e009b;
  }

  

`;

const LoadingMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
`;


export default OrderSummaryPage;
