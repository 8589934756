import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { createEmailTemplateAction } from '../../store/actions/templateActions';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  margin: 40px auto;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const TextArea = styled.textarea`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const DropzoneContainer = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const DropzoneText = styled.p`
  text-align: center;
  font-size: 14px;
  color: #555;
`;

const EmailTemplateForm = () => {
  const dispatch = useDispatch();
  const [templateData, setTemplateData] = useState({
    name: '',
    subject: '',
    content: '', // Changed to a string for a regular text area
    placeholders: [],
    file: null,
  });

  const handleChange = (name, value) => {
    setTemplateData((prevTemplateData) => ({
      ...prevTemplateData,
      [name]: value,
    }));
  };

  const handleDrop = (acceptedFiles) => {
    // Assuming you want to handle the first file dropped
    const file = acceptedFiles[0];
    setTemplateData((prevTemplateData) => ({
      ...prevTemplateData,
      file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', templateData.name);
    formData.append('subject', templateData.subject);
    formData.append('content', templateData.content);
    formData.append('placeholders', JSON.stringify(templateData.placeholders));

    if (templateData.file) {
      formData.append('file', templateData.file);
    }

    await dispatch(createEmailTemplateAction(formData));
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <h2>Create Email Template</h2>
      <Input
        type="text"
        name="name"
        placeholder="Template Name"
        value={templateData.name}
        onChange={(e) => handleChange('name', e.target.value)}
      />
      <Input
        type="text"
        name="subject"
        placeholder="Email Subject"
        value={templateData.subject}
        onChange={(e) => handleChange('subject', e.target.value)}
      />
      <DropzoneContainer>
        <Dropzone onDrop={handleDrop} accept="image/*">
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DropzoneText>
                {templateData.file
                  ? `File uploaded: ${templateData.file.name}`
                  : 'Drop an image file here or click to upload.'}
              </DropzoneText>
            </div>
          )}
        </Dropzone>
      </DropzoneContainer>
      <TextArea
        name="content"
        placeholder="Email Content"
        value={templateData.content}
        onChange={(e) => handleChange('content', e.target.value)}
      />
      {/* Add placeholder input fields if needed */}
      <SubmitButton type="submit">Save Template</SubmitButton>
    </FormContainer>
  );
};

export default EmailTemplateForm;
