import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCog, FaRocket, FaTimes } from 'react-icons/fa';
import { IoDiamondOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentIntentAction } from '../../store/actions/paymentActions';
import { selectPlanAction } from '../../store/actions/subscriptionPlanActions';
import { setSubscriptionAction } from '../../store/actions/subscriptionActions';



const SubscriptionPlans = ({ plans }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);


  const ActivePlan = useSelector((state) => state.subscription.plan);

  const handleSubscribe = (plan) => {
    setSelectedPlan(plan);
    let actionType = 'upgrade';
    dispatch(setSubscriptionAction(actionType));
    setModalVisible(true);
  };

  const handleRenew = (plan) => {
    setSelectedPlan(plan);
    let actionType = 'renew';
    dispatch(setSubscriptionAction(actionType));
    setModalVisible(true);
  };

  const handlePay = () => {
    const planId = selectedPlan._id;
    
    dispatch(selectPlanAction(planId));
    setModalVisible(false);
    navigate('/order');
  };

  const renderIcon = (planName) => {
    switch (planName) {
      case 'Basic':
        return <FaCog size={24} color="#6200b3" />;
      case 'Standard':
        return <FaRocket size={24} color="#6200b3" />;
      case 'Premium':
        return <IoDiamondOutline size={24} color="#6200b3" />;
      default:
        return null;
    }
  };

  // Filter plans to exclude those with name 'Trial'
  const filteredPlans = plans.filter(plan => plan.name !== 'Trial');

  return (
    <Container>
      {filteredPlans.map((plan, index) => (
        <PlanContainer key={index}>
          <Header>
            {renderIcon(plan.name)}
            <PlanName>{plan.name}</PlanName>
          </Header>
          <PlanPrice>${plan.monthlyPrice} {plan.billingInterval}</PlanPrice>
          <FeaturesContainer>
            {plan.features.map((feature, idx) => (
              <Feature key={idx}>{feature}</Feature>
            ))}
          </FeaturesContainer>
         {/* Conditional button rendering */}
    {ActivePlan && ActivePlan === plan.name && ActivePlan !== 'Trial' ? (
      <RenewButton onClick={() => handleRenew(plan)}>
        Renew 
      </RenewButton>
    ) : (
      <SubscribeButton onClick={() => handleSubscribe(plan)}>
        Subscribe
      </SubscribeButton>
    )}
  </PlanContainer>
))}

      {modalVisible && (
        <ModalContainer>
          <ModalContent>
            <CloseButton onClick={() => setModalVisible(false)}>
              <FaTimes size={24} color="#666" />
            </CloseButton>
            <ModalTitle>Selected Subscription</ModalTitle>
            <SelectedPlanContainer>
              <SelectedPlanText>{selectedPlan?.name} Plan</SelectedPlanText>
              <SelectedPlanPrice>${selectedPlan?.monthlyPrice}</SelectedPlanPrice>
            </SelectedPlanContainer>
            <PayButton onClick={handlePay}>
              Pay
            </PayButton>
          </ModalContent>
        </ModalContainer>
      )}
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #f5f5f5;
  height: 100vh;
  gap: 50px;
  padding: 20px;
  
  overflow-y: auto;
  @media (max-width: 768px) {
    gap: 20px;
    padding-bottom: 100px; /* Ensures last card is fully visible */
    margin-bottom: 100px;
    
  }
`;

export const PlanContainer = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
  height: 300px;

  @media (max-width: 768px) {
    width: 95%;
    height: 240px;
    padding-bottom: 20px;
    
  }

`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const PlanName = styled.h2`
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
`;

export const PlanPrice = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
`;

export const FeaturesContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Feature = styled.li`
  font-size: 14px;
  margin-bottom: 5px;
`;

export const SubscribeButton = styled.button`
  background-color: #6200b3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;

  &:hover {
    background-color: #4b0091;
  }
`;

export const RenewButton = styled.button`
  background-color: #FFA500;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;

  &:hover {
    background-color: #4b0091;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  position: relative;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const SelectedPlanContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const SelectedPlanText = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

export const SelectedPlanPrice = styled.p`
  font-size: 16px;
  color: #666;
`;

export const PayButton = styled.button`
  background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 80%;
  margin: 0 auto;

  &:hover {
    background-color: #cc8400;
  }
`;

export default SubscriptionPlans;