import React, { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import PackageForm from '../components/package/PackageForm';
import StandardPackageForm from '../components/package/StandardPackageForm';
import PremiumPackageForm from '../components/package/PremiumPackageForm';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsedTracksAction } from '../store/actions/subscriptionActions';
import DashHeader from '../components/common/DashHeader';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled.div`
  background-color: #f7f7f7;
  height: 100vh;
  overflow-y: auto;

  
`;

const Container = styled.div`
  margin-top: 30px;
  
  @media (max-width: 768px) {
    width:95%; 
    margin-top: 30px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
`;

const IconContainer = styled.div`
  font-size: 3rem;
  color: #e74c3c;
  margin-bottom: 15px;
`;

const ModalButton = styled.button`
  background-color: #4834d4;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #372fb5;
  }
`;

const PackageFormPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const usedTracks = useSelector((state) => state.subscription.usedTracks);
  const allowedTracks = useSelector((state) => state.subscription.allowedTracks);
  const remainingDays = useSelector((state) => state.subscription.remainingDays);
  const plan = useSelector((state) => state.subscription.plan);
  const userId = useSelector((state) => state.auth.user);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchUsedTracksAction(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (usedTracks >= allowedTracks || remainingDays <= 0) {
      setShowModal(true);
    }
  }, [usedTracks, allowedTracks, remainingDays]);

  const handleNavigateToPlans = () => {
    setShowModal(false);
    navigate('/subscription/plans');
  };

  const handleNavigateToDashboard = () => {
    setShowModal(false);
    navigate('/dashboard');
  };

  const isTrialPlan = plan === 'Trial';
  const modalTitle = isTrialPlan ? 'Plan Upgrade Required' : 'Plan Renewal Required';
  const modalMessage = isTrialPlan
    ? 'Your Trial Plan has expired. Please upgrade your plan to continue.'
    : 'Your subscription has expired. Please renew your plan to continue.';
  const buttonText = isTrialPlan ? 'Upgrade Plan' : 'Renew Plan';

  return (
    <PageContainer>
      <DashHeader />
      <Container>
        {plan === 'Standard' && (
          <StandardPackageForm
            usedTracks={usedTracks}
            allowedTracks={allowedTracks}
            remainingDays={remainingDays}
            plan={plan}
          />
        )}
        {plan === 'Premium' && (
          <PremiumPackageForm
            usedTracks={usedTracks}
            allowedTracks={allowedTracks}
            remainingDays={remainingDays}
            plan={plan}
          />
        )}
        {(plan === 'Trial' || plan === 'Basic') && (
          <PackageForm
            usedTracks={usedTracks}
            allowedTracks={allowedTracks}
            remainingDays={remainingDays}
            plan={plan}
          />
        )}
      </Container>
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <IconContainer>
              <FaExclamationCircle />
            </IconContainer>
            <h2>{modalTitle}</h2>
            <p>{modalMessage}</p>
            <ModalButton onClick={handleNavigateToPlans}>{buttonText}</ModalButton>
            <ModalButton onClick={handleNavigateToDashboard}>Go to Dashboard</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </PageContainer>
  );
};

export default PackageFormPage;
