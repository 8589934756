// ProfilePage.js
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ProfileStats from '../components/user/ProfileStats';
import ProfileInfoCard from '../components/user/ProfileInfoCard';
import NotificationsPreferences from '../components/user/NotificationPreferences';
import HelpAndSupport from '../components/user/HelpAndSupport';
import SubscriptionPlanCard from '../components/subscription/subscriptionPlan';
import Sidebar from '../components/common/Sidebar';

import { fetchSubscriptionPlansAction } from '../store/actions/subscriptionPlanActions';
import LatestActivity from '../components/user/LatestActivity';
import UsageTracker from '../components/user/UsageTracker';
import { fetchUsedTracksAction } from '../store/actions/subscriptionActions';
import { PlayLesson } from '@mui/icons-material';
import { getPackagesForUserAction, getRecentlyUpdatedPackagesAction } from '../store/actions/packageActions';
import DashHeader from '../components/common/DashHeader';



const ProfilePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubscriptionPlansAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsedTracksAction(userId));
  }, [dispatch]);

  useEffect(()=>{
    dispatch(getRecentlyUpdatedPackagesAction(userId));
    }, [dispatch]);

    useEffect(() => {
      // Dispatch the action to get packages for the user when the component mounts
      if (userId) {
        dispatch(getPackagesForUserAction(userId));
      }
    }, [dispatch]);

  
  const usedTracks = useSelector((state) => state.subscription.usedTracks);
  const allowedTracks = useSelector((state) => state.subscription.allowedTracks);
  const remainingDays = useSelector((state) => state.subscription.remainingDays);
  const plan = useSelector((state) => state.subscription.plan);
  const recentPackages = useSelector((state) => state.package.recentlyUpdatedPackages);
  const userId = useSelector((state) => state.auth.user);
  const packages = useSelector((state) => state.package.packages);
  

   // Define functions to count package statuses
   const countDelivered = packages.filter(pkg => pkg.deliveryStatus === 'delivered').length;
   const countInTransit = packages.filter(pkg => pkg.deliveryStatus === 'in Transit').length;
   const countPending = packages.filter(pkg => pkg.deliveryStatus === 'pending').length;
   const countExpired = packages.filter(pkg => pkg.deliveryStatus === 'expired').length;

  

      

  return (
    <Container>
        <DashHeader />
        <ProfileStats 
           delivered={countDelivered} 
           inTransit={countInTransit} 
           pending={countPending} 
           expired={countExpired} 
      />
      <ListContainer>
         <UsageTracker usedTracks={usedTracks} allowedTracks={allowedTracks} remainingDays={remainingDays} plan={plan} />
         <LatestActivity activities={recentPackages} isLoading={false} /> 
      </ListContainer>
      
    </Container>
  );
};

const Container = styled.div`
  background-color: #f9f9f9;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  
`;

const SubContainer = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: center;
  height: 500px;
  flex-wrap: wrap;
  gap: 20px;
`;

const SubscriptionContainer = styled.div`
  max-width: 780px;
  background-color: #fff;
  border-radius: 10px;

  h2 {
    font-weight: 800;
    margin-left: 20px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-right: 60px;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  border: 1px solid #03071e;
`;

const LoadingMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
`;

const NoPlansMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

export default ProfilePage;
