// src/redux/types/feedbackTypes.js

export const CREATE_FEEDBACK_REQUEST = 'CREATE_FEEDBACK_REQUEST';
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS';
export const CREATE_FEEDBACK_FAILURE = 'CREATE_FEEDBACK_FAILURE';

export const GET_ALL_FEEDBACK_REQUEST = 'GET_ALL_FEEDBACK_REQUEST';
export const GET_ALL_FEEDBACK_SUCCESS = 'GET_ALL_FEEDBACK_SUCCESS';
export const GET_ALL_FEEDBACK_FAILURE = 'GET_ALL_FEEDBACK_FAILURE';

export const GET_FEEDBACK_BY_USER_REQUEST = 'GET_FEEDBACK_BY_USER_REQUEST';
export const GET_FEEDBACK_BY_USER_SUCCESS = 'GET_FEEDBACK_BY_USER_SUCCESS';
export const GET_FEEDBACK_BY_USER_FAILURE = 'GET_FEEDBACK_BY_USER_FAILURE';

export const RESPOND_TO_FEEDBACK_REQUEST = 'RESPOND_TO_FEEDBACK_REQUEST';
export const RESPOND_TO_FEEDBACK_SUCCESS = 'RESPOND_TO_FEEDBACK_SUCCESS';
export const RESPOND_TO_FEEDBACK_FAILURE = 'RESPOND_TO_FEEDBACK_FAILURE';
