// articleActions.js
import * as types from '../types/articleTypes';
import * as articleServices from '../../services/articleServices';

// CREATE ARTICLE
export const createArticle = (articleData) => async (dispatch) => {
  dispatch({ type: types.CREATE_ARTICLE_REQUEST });
  try {
    const response = await articleServices.createArticle(articleData);
    dispatch({ type: types.CREATE_ARTICLE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.CREATE_ARTICLE_FAILURE, payload: error.message });
  }
};

// GET ALL ARTICLES
export const getAllArticles = () => async (dispatch) => {
  dispatch({ type: types.GET_ALL_ARTICLES_REQUEST });
  try {
    const response = await articleServices.getAllArticles();
    dispatch({ type: types.GET_ALL_ARTICLES_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: types.GET_ALL_ARTICLES_FAILURE, payload: error.message });
  }
};

// GET ARTICLE BY ID
export const getArticleById = (id) => async (dispatch) => {
  dispatch({ type: types.GET_ARTICLE_BY_ID_REQUEST });
  try {
    const response = await articleServices.getArticleById(id);
    dispatch({ type: types.GET_ARTICLE_BY_ID_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.GET_ARTICLE_BY_ID_FAILURE, payload: error.message });
  }
};

// UPDATE ARTICLE
export const updateArticle = (id, articleData) => async (dispatch) => {
  dispatch({ type: types.UPDATE_ARTICLE_REQUEST });
  try {
    const response = await articleServices.updateArticle(id, articleData);
    dispatch({ type: types.UPDATE_ARTICLE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.UPDATE_ARTICLE_FAILURE, payload: error.message });
  }
};

// DELETE ARTICLE
export const deleteArticle = (id) => async (dispatch) => {
  dispatch({ type: types.DELETE_ARTICLE_REQUEST });
  try {
    await articleServices.deleteArticle(id);
    dispatch({ type: types.DELETE_ARTICLE_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: types.DELETE_ARTICLE_FAILURE, payload: error.message });
  }
};

// INCREMENT ARTICLE VIEWS
export const incrementArticleViews = (id) => async (dispatch) => {
  dispatch({ type: types.INCREMENT_ARTICLE_VIEWS_REQUEST });
  try {
    const response = await articleServices.incrementViews(id);
    dispatch({ type: types.INCREMENT_ARTICLE_VIEWS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.INCREMENT_ARTICLE_VIEWS_FAILURE, payload: error.message });
  }
};

// ADD FEEDBACK TO ARTICLE
export const addFeedback = (id, feedbackData) => async (dispatch) => {
  dispatch({ type: types.ADD_FEEDBACK_REQUEST });
  try {
    await articleServices.addFeedback(id, feedbackData);
    dispatch({ type: types.ADD_FEEDBACK_SUCCESS });
  } catch (error) {
    dispatch({ type: types.ADD_FEEDBACK_FAILURE, payload: error.message });
  }
};

// VOTE ARTICLE AS HELPFUL
export const voteArticleHelpful = (id) => async (dispatch) => {
  dispatch({ type: types.VOTE_ARTICLE_HELPFUL_REQUEST });
  try {
    await articleServices.voteHelpful(id);
    dispatch({ type: types.VOTE_ARTICLE_HELPFUL_SUCCESS });
  } catch (error) {
    dispatch({ type: types.VOTE_ARTICLE_HELPFUL_FAILURE, payload: error.message });
  }
};

// VOTE ARTICLE AS NOT HELPFUL
export const voteArticleNotHelpful = (id) => async (dispatch) => {
  dispatch({ type: types.VOTE_ARTICLE_NOT_HELPFUL_REQUEST });
  try {
    await articleServices.voteNotHelpful(id);
    dispatch({ type: types.VOTE_ARTICLE_NOT_HELPFUL_SUCCESS });
  } catch (error) {
    dispatch({ type: types.VOTE_ARTICLE_NOT_HELPFUL_FAILURE, payload: error.message });
  }
};
