import React from 'react';
import { Card, Button } from 'antd';
import styled from 'styled-components';

const HelpSupportCard = styled(Card)`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
  padding: 20px;
  width: 310px;
 
  h2 {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  .contact-button {
    margin-top: 20px;
  }
`;

const HelpAndSupport = () => {
  const handleContactSupport = () => {
    // Implement logic to open a contact support modal or navigate to support page
  };

  return (
    <HelpSupportCard>
      <h2>Help and Support</h2>
      <p>If you have any questions or need assistance, our support team is here to help.</p>
      <Button className="contact-button" type="primary" onClick={handleContactSupport}>
        Contact Support
      </Button>
    </HelpSupportCard>
  );
};

export default HelpAndSupport;
