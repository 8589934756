export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';

export const GET_ALL_TICKETS_REQUEST = 'GET_ALL_TICKETS_REQUEST';
export const GET_ALL_TICKETS_SUCCESS = 'GET_ALL_TICKETS_SUCCESS';
export const GET_ALL_TICKETS_FAILURE = 'GET_ALL_TICKETS_FAILURE';

export const GET_TICKET_BY_ID_REQUEST = 'GET_TICKET_BY_ID_REQUEST';
export const GET_TICKET_BY_ID_SUCCESS = 'GET_TICKET_BY_ID_SUCCESS';
export const GET_TICKET_BY_ID_FAILURE = 'GET_TICKET_BY_ID_FAILURE';

export const UPDATE_TICKET_STATUS_REQUEST = 'UPDATE_TICKET_STATUS_REQUEST';
export const UPDATE_TICKET_STATUS_SUCCESS = 'UPDATE_TICKET_STATUS_SUCCESS';
export const UPDATE_TICKET_STATUS_FAILURE = 'UPDATE_TICKET_STATUS_FAILURE';

export const UPDATE_TICKET_PRIORITY_REQUEST = 'UPDATE_TICKET_PRIORITY_REQUEST';
export const UPDATE_TICKET_PRIORITY_SUCCESS = 'UPDATE_TICKET_PRIORITY_SUCCESS';
export const UPDATE_TICKET_PRIORITY_FAILURE = 'UPDATE_TICKET_PRIORITY_FAILURE';
