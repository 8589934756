import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';
import { combineReducers } from 'redux';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducers from './reducers/authReducers';
import userReducers from './reducers/userReducers';
import paymentReducer from './reducers/paymentReducers';
import packageReducers from './reducers/packageReducers';
import templateReducer from './reducers/templateReducer';
import subscriptionPlanReducer from './reducers/subscriptionPlanReducers';
import subscriptionReducer from './reducers/subscriptionReducers';


import notificationReducer from './reducers/notificationReducers';
import paymentMethodReducer from './reducers/paymentMethodReducer';
import articleReducer from './reducers/articleReducers';
import paymentLinkReducer from './reducers/paymentLinkReducers';
import ticketReducer from './reducers/ticketReducer';


const rootReducer = combineReducers({
  auth: authReducers,
  user: userReducers,
  package: packageReducers,
  email: templateReducer,
  plans: subscriptionPlanReducer,
  subscription: subscriptionReducer,
  payments: paymentReducer,
  paymentMethod: paymentMethodReducer,
  note: notificationReducer,
  article: articleReducer,
  tickets: ticketReducer,
  link: paymentLinkReducer
  
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'plans', 'payments','paymentMethod', 'subscription'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
