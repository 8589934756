import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';
import { updatePaymentAction } from '../store/actions/paymentActions';
import { fetchUsedTracksAction, changeSubscriptionPlanAction, renewSubscriptionAction } from '../store/actions/subscriptionActions';


const PaymentConfirmationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userId = useSelector((state) => state.auth.user?.id);
    const paymentId = useSelector((state) => state.payments.paymentId);
    const subscriptionAction = useSelector((state) => state.subscription.subscriptionAction);
    const selectedPlan = useSelector((state) => state.plans.selectedPlan);
    const subscription = useSelector((state) => state.subscription.subscriptionDetails);
    
    const plan = useSelector((state) => state.plans.selectedPlan);

    

    // Sample data to mock the payment details for now
    const samplePaymentDetails = {
        planName: "Premium Subscription",
        amount: "49.99",
        transactionId: "ABC123456789",
        status: "Completed"
    };

    

    useEffect(() => {
        // Update payment status
        if (paymentId) {
            const formData = { userId, status: 'Paid' };
            dispatch(updatePaymentAction(paymentId, formData));
        }

        // Dispatch appropriate subscription action
        if (subscriptionAction === 'renew') {
            const subscriptionData = { 
                subscriptionId: subscription._id, 
                paymentStatus: 'paid' 
              };
            dispatch(renewSubscriptionAction(subscriptionData));
        } else {
            const newPlanData = { subscriptionId: subscription?._id, newPlanId: plan?._id };
            dispatch(changeSubscriptionPlanAction(newPlanData));
        }
    }, [dispatch, paymentId, userId, subscriptionAction, subscription, plan]);

    const handleGoToDashboard = () => {
        navigate('/dashboard');
    };

    return (
        <Container>
            <SubContainer>
                <IconContainer>
                    <FaCheckCircle size={50} color="#4CAF50" /> {/* Display icon above the heading */}
                </IconContainer>
                <h1>Payment Successful</h1>
                <Details>
                    <p><strong>Plan:</strong> {selectedPlan.name}</p>
                    <p><strong>Amount:</strong> ${selectedPlan.monthlyPrice}</p>
                    
                    <p><strong>Status:</strong> {samplePaymentDetails.status}</p>
                </Details>
                <Button onClick={handleGoToDashboard}>Go to Dashboard</Button>
            </SubContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    height: 100vh;
    padding: 20px;
    color: #333;
`;

const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    max-width: 550px;
    background-color: #fff;
    padding: 40px 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const IconContainer = styled.div`
    margin-bottom: 20px;
`;

const Details = styled.div`
    margin-top: 20px;
    p {
        font-size: 1.2rem;
        margin: 10px 0;
    }
`;

const Button = styled.button`
    margin-top: 2rem;
    padding: 0.75rem 2rem;
    border-radius: 30px;
    background-color: #6200b3;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #4e009b;
    }
`;

export default PaymentConfirmationPage;
