// RegisterPage.js
import React from 'react';
import styled from 'styled-components';
import RegisterForm from '../components/auth/RegisterForm';

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const RegisterPage = () => {
  return (
    <PageContainer>
      <RegisterForm />
    </PageContainer>
  );
};

export default RegisterPage;