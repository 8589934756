// Assuming this is your CheckoutPage component
import React,{useEffect} from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import PaymentForm from '../components/payments/paymentForm';
import styled from 'styled-components';
import { fetchSubscriptionPlansAction } from '../store/actions/subscriptionPlanActions';
import SubscriptionPlanCard from '../components/subscription/subscriptionPlan';

const Container = styled.div`
  background-color:#fff;
  border-radius: 10px;
  margin-top: 50px;
  height: 280px;
  width:550px;
  padding-bottom: 20px;

  h2{
    margin-left: 55px;
  }
`;

const LoadingMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
`;

const NoPlansMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const CheckoutPage = () => {
    const dispatch = useDispatch();
  
  useEffect(() => {
      dispatch(fetchSubscriptionPlansAction());
  }, [dispatch]);

  const plans = useSelector((state) => state.plans.subscriptionPlans);
  const publishableKey = useSelector((state) => state.payments.publishableKey);
  const clientSecret = useSelector((state) => state.payments.clientSecret);
  const loading = useSelector((state) => state.plans.loading);
  const error = useSelector((state) => state.plans.error);

  
  // Your other checkout page logic
  const stripePromise = loadStripe(publishableKey);
  
  return (
    <>
       
      <Container>
      <h2>Payment Information</h2>
      <Elements stripe={stripePromise} options={{clientSecret}}>
        <PaymentForm />
      </Elements>
    </Container>
    </>
  );
};

export default CheckoutPage;
