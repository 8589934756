export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const CREATE_NOTIFICATION_REQUEST = 'CREATE_NOTIFICATION_REQUEST';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';

export const COUNT_UNREAD_NOTIFICATIONS_REQUEST = 'COUNT_UNREAD_NOTIFICATIONS_REQUEST';
export const COUNT_UNREAD_NOTIFICATIONS_SUCCESS = 'COUNT_UNREAD_NOTIFICATIONS_SUCCESS';
export const COUNT_UNREAD_NOTIFICATIONS_FAILURE = 'COUNT_UNREAD_NOTIFICATIONS_FAILURE';

export const MARK_NOTIFICATION_AS_READ_REQUEST = 'MARK_NOTIFICATION_AS_READ_REQUEST';
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_FAILURE = 'MARK_NOTIFICATION_AS_READ_FAILURE';

// New action types for marking all notifications as read
export const MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST = 'MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST';
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS = 'MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE = 'MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE';

export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';