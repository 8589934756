import * as ticketTypes from '../types/ticketTypes';

const initialState = {
  tickets: [],
  ticket: null,
  loading: false,
  error: null,
};

// Ticket Reducer
const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case ticketTypes.CREATE_TICKET_REQUEST:
    case ticketTypes.GET_ALL_TICKETS_REQUEST:
    case ticketTypes.GET_TICKET_BY_ID_REQUEST:
    case ticketTypes.UPDATE_TICKET_STATUS_REQUEST:
    case ticketTypes.UPDATE_TICKET_PRIORITY_REQUEST:
      return { ...state, loading: true, error: null };

    case ticketTypes.CREATE_TICKET_SUCCESS:
      return { ...state, loading: false, tickets: [...state.tickets, action.payload] };

    case ticketTypes.GET_ALL_TICKETS_SUCCESS:
      return { ...state, loading: false, tickets: action.payload };

    case ticketTypes.GET_TICKET_BY_ID_SUCCESS:
      return { ...state, loading: false, ticket: action.payload };

    case ticketTypes.UPDATE_TICKET_STATUS_SUCCESS:
    case ticketTypes.UPDATE_TICKET_PRIORITY_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        tickets: state.tickets.map((ticket) =>
          ticket.id === action.payload.id ? action.payload : ticket
        ),
      };

    case ticketTypes.CREATE_TICKET_FAILURE:
    case ticketTypes.GET_ALL_TICKETS_FAILURE:
    case ticketTypes.GET_TICKET_BY_ID_FAILURE:
    case ticketTypes.UPDATE_TICKET_STATUS_FAILURE:
    case ticketTypes.UPDATE_TICKET_PRIORITY_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default ticketReducer;
