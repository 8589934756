import axios from 'axios';

//const BASE_URL = 'https://track-mk6l.onrender.com';
const BASE_URL = 'https://track-ten-blue.vercel.app';



// Create a new article
export const createArticle = async (articleData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/articles/create`, articleData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Get all articles
export const getAllArticles = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/articles/get/all`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Get a single article by ID
export const getArticleById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/articles/get/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Update an article by ID
export const updateArticle = async (id, articleData) => {
  try {
    const response = await axios.put(`${BASE_URL}/api/articles/update/${id}`, articleData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Delete an article by ID
export const deleteArticle = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/articles/delete/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Increment article views
export const incrementViews = async (id) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/articles/views/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Add feedback to an article
export const addFeedback = async (id, feedbackData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/articles/feedback/${id}`, feedbackData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Vote an article as helpful
export const voteHelpful = async (id) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/articles/helpful/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Vote an article as not helpful
export const voteNotHelpful = async (id) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/articles/nothelpful/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
