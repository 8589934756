import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

// Styled for desktop activity item
const StyledActivityItem = styled.li`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  align-items: center;
  justify-items: center;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    display: none; /* Hide on mobile view */
  }
`;

// Styled for mobile activity card
const ActivityCard = styled.div`
  display: none; /* Hidden by default */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 1.2rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: block; /* Show only on mobile view */
  }

  p {
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
    color: #555;
  }

  h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
  }

  span {
    font-size: 1.2rem;
    color: #777;
  }
`;

const ActivityDetail = styled.div`
  font-weight: 500;
`;

function ActivityItem({ activity }) {
  const { t } = useTranslation();
  const { trackingNumber, updatedAt, deliveryStatus } = activity;
  const formattedDate = format(new Date(updatedAt), 'yyyy-MM-dd HH:mm:ss');

  return (
    <>
      {/* Original activity item for desktop */}
      <StyledActivityItem>
        <p>{formattedDate}</p>
        <ActivityDetail>{trackingNumber}</ActivityDetail>
        <div>{t(deliveryStatus)}</div>
      </StyledActivityItem>

      {/* Mobile-specific activity card */}
      <ActivityCard>
        <p>{formattedDate}</p>
        <h3>{trackingNumber}</h3>
        <span>{t(deliveryStatus)}</span>
      </ActivityCard>
    </>
  );
}

export default ActivityItem;
