import React,{useState, useEffect} from 'react';
import styled from 'styled-components';
import { FaRocket } from 'react-icons/fa';
import { FaBtc } from "react-icons/fa6";
import { FaCreditCard } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentIntentAction } from '../../store/actions/paymentActions';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from './paymentForm';

const OrderSummaryContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  border-radius: 20px;
  
  padding: 10px;
  margin-top: 10px;
 
  width: 90%;

   @media (max-width: 768px) {
   
    margin-right: 15px;
  }
`;

const RocketIcon = styled(FaRocket)`
  font-size: 28px;
  font-weight: 600;
  color: #f72585;
  margin-right: 10px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  span {
    font-weight: 700
  }
`;

const StripeContainer = styled.div`
  margin-top: 26px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`

const PlanDetails = styled.div`
  margin-bottom: 20px;
`;

const OrderDetails = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 8px;
  width: 90%;
  border-radius:20px;
`;

const OrderDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: 500;
 padding:5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const TotalAmount = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #4caf50;
`;

const PaymentButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  margin-top: 20px;
  width: 436px;
`;

const PaymentButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  width:300px;
  background-color:#fff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #03045e; 
    color: #fff;
  }
`;

const PaymentButtonIcon = styled.span`
  margin-right: 8px;
`;

const OrderSummary = ({ selectedPlan }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const clientSecret = useSelector((state) => state.payments.clientSecret);
  const publishableKey = useSelector((state) => state.payments.publishableKey);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const stripePromise = loadStripe(publishableKey);

  console.log(user);

  useEffect(() => {
    // Check if clientSecret is updated and set showPaymentForm accordingly
    if (clientSecret) {
      setShowPaymentForm(true);
    }
  }, [clientSecret]);


  const options = {
    clientSecret,
  };

  const orderDetails = {
    itemName: 'Subscription Plan',
    quantity: 1,
    unitPrice: selectedPlan ? selectedPlan.monthlyPrice : 0,
  };

  const totalAmount = orderDetails.quantity * orderDetails.unitPrice;

  const handleCardPayment = () => {
    let planId = selectedPlan._id;
    let userId = user;
    let amount = totalAmount;
    let currency = selectedPlan.currency;

    const paymentData = {
        planId,
        userId,
        amount,
        currency
    }

    dispatch(createPaymentIntentAction(paymentData));
    // Handle card payment logic
    console.log('Card payment clicked');
  };

 

  return (
    <>
    <OrderSummaryContainer>
    <h2>Order Summary</h2>

    <IconContainer>
      <RocketIcon  />
      <span>{selectedPlan ? selectedPlan.name : 'N/A'}</span>
    </IconContainer>

    <OrderDetails>
      <OrderDetailItem>
        <span>Quantity:</span>
        <span>{orderDetails.quantity}</span>
      </OrderDetailItem>
      <OrderDetailItem>
        <span>Unit Price:</span>
        <span>${orderDetails.unitPrice.toFixed(2)}</span>
      </OrderDetailItem>
    </OrderDetails>

    <TotalAmount>Total Amount: ${totalAmount.toFixed(2)}</TotalAmount>
  </OrderSummaryContainer>

  
    </>
  );
};

export default OrderSummary;
