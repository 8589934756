import React, { useState } from 'react';
import styled from 'styled-components';

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const StyledListItem = styled.li`
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  
  &:hover {
    background-color: #f9fafb;
    border-color: #4f46e5;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ArticleTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ArticleContent = styled.p`
  margin: 10px 0 0;
  font-size: 1rem;
  color: #666;
`;

const ArticleCategory = styled.span`
  font-size: 0.9rem;
  color: #999;
`;

const ArticleList = ({ articles }) => {
  const [expandedArticleId, setExpandedArticleId] = useState(null);

  const toggleArticle = (id) => {
    setExpandedArticleId(expandedArticleId === id ? null : id);
  };

  return (
    <StyledList>
      {articles.map((article) => (
        <StyledListItem key={article._id} onClick={() => toggleArticle(article._id)}>
          <ArticleTitle>{article.title}</ArticleTitle>
          
          {expandedArticleId === article._id && (
            <ArticleContent>{article.content}</ArticleContent>
          )}
        </StyledListItem>
      ))}
    </StyledList>
  );
};

export default ArticleList;
