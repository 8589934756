// paymentLinkReducer.js
import * as linkTypes from '../types/paymentLinkTypes';

const initialState = {
  links: [],
  loading: false,
  error: null,
  link: null,
};

const paymentLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case linkTypes.GET_PAYMENT_LINKS_REQUEST:
    case linkTypes.GET_PAYMENT_LINK_REQUEST:
    case linkTypes.CREATE_PAYMENT_LINK_REQUEST:
    case linkTypes.UPDATE_PAYMENT_LINK_REQUEST:
    case linkTypes.DELETE_PAYMENT_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case linkTypes.GET_PAYMENT_LINKS_SUCCESS:
      return {
        ...state,
        links: action.payload,
        loading: false,
      };
    case linkTypes.GET_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        link: action.payload,
        loading: false,
      };
    case linkTypes.CREATE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        links: [...state.links, action.payload],
        loading: false,
      };
    case linkTypes.UPDATE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        links: state.links.map((link) =>
          link._id === action.payload._id ? action.payload : link
        ),
        loading: false,
      };
    case linkTypes.DELETE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        links: state.links.filter((link) => link._id !== action.payload._id),
        loading: false,
      };
    case linkTypes.GET_PAYMENT_LINKS_FAILURE:
    case linkTypes.GET_PAYMENT_LINK_FAILURE:
    case linkTypes.CREATE_PAYMENT_LINK_FAILURE:
    case linkTypes.UPDATE_PAYMENT_LINK_FAILURE:
    case linkTypes.DELETE_PAYMENT_LINK_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default paymentLinkReducer;
