import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PackageInfo from '../components/package/PackageInfo';
import { getPackagesForUserAction } from '../store/actions/packageActions';
import styled from 'styled-components';
import LoadingComponent from '../components/common/loading';

// Styled container for the package information page
const PackageContainer = styled.div`
  height: 100vh;
  
`;

const PackageInfoPage = () => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.package.packages);
  const loading = useSelector((state) => state.package.loading);
  const error = useSelector((state) => state.package.error);
  const userId = useSelector((state) => state.auth.user);

  useEffect(() => {
    // Dispatch the action to get packages for the user when the component mounts
    if (userId) {
      dispatch(getPackagesForUserAction(userId));
    }
  }, [dispatch, userId]);

  return (
    <PackageContainer>
      <h2>Package Information</h2>

      {loading && <LoadingComponent/>}

      {error && <p>Error loading packages: {error}</p>}

      {!loading && !error && <PackageInfo packages={packages} />}
    </PackageContainer>
  );
};

export default PackageInfoPage;
