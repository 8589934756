import axios from 'axios';

//const BASE_URL = 'https://track-mk6l.onrender.com';

const BASE_URL = 'https://track-ten-blue.vercel.app';

// Get all payment links
export const getPaymentLinks = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/payment/link/get/all`);
    
    return response.data;
  } catch (error) {
    console.error('Error fetching payment links:', error);
    throw error;
  }
};

// Get a specific payment link by ID
export const getPaymentLink = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/payment/link/get/${id}`);
    
    return response.data;
  } catch (error) {
    console.error(`Error fetching payment link with ID ${id}:`, error);
    throw error;
  }
};

// Create a new payment link
export const createPaymentLink = async (paymentLinkData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/payment/link/create`, paymentLinkData);
    
    return response.data;
  } catch (error) {
    console.error('Error creating payment link:', error);
    throw error;
  }
};

// Update an existing payment link by ID
export const updatePaymentLink = async (id, paymentLinkData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/api/payment/link/update/${id}`, paymentLinkData);
    
    return response.data;
  } catch (error) {
    console.error(`Error updating payment link with ID ${id}:`, error);
    throw error;
  }
};

// Delete a payment link by ID
export const deletePaymentLink = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/api/payment/link/delete/${id}`);
    
    return response.data;
  } catch (error) {
    console.error(`Error deleting payment link with ID ${id}:`, error);
    throw error;
  }
};
