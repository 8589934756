import React from 'react';
import styled from 'styled-components';

const PricingSection = styled.section`
  padding-top: 120px;
  padding-bottom: 90px;

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const PricingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const PricingCard = styled.div`
  background: ${({ active }) => (active ? 'linear-gradient(180deg, #3056d3 0%, #179bee 100%)' : 'var(--white)')};
  border: ${({ active }) => (active ? 'none' : '2px solid #d4deff')};
  border-radius: 8px;
  text-align: center;
  padding: ${({ active }) => (active ? '55px 30px' : '45px 30px')};
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  max-width: 360px;

  &:first-child::after {
    content: '';
    position: absolute;
    width: 55px;
    height: 55px;
    z-index: 1;
    background: var(--primary-color);
    left: -2px;
    bottom: -2px;
    border-radius: 0px 55px 0px 8px;
  }

  &:last-child::after {
    content: '';
    position: absolute;
    width: 55px;
    height: 55px;
    z-index: 1;
    background: #13c296;
    top: -2px;
    right: -2px;
    border-radius: 0px 8px 0px 55px;
  }

  @media (max-width: 991px) {
    max-width: none;
    width: 100%;
  }
`;

const PopularTag = styled.span`
  display: inline-block;
  padding: 10px 25px;
  background: var(--white);
  border-radius: 30px;
  color: var(--primary-color);
  margin-bottom: 20px;
  font-weight: 600;
`;

const PricingHeader = styled.div`
  h3 {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 8px;
  }

  h4 {
    font-weight: 600;
    font-size: 26px;
    color: ${({ active }) => (active ? 'var(--white)' : 'var(--primary-color)')};
    margin-bottom: 40px;
  }
`;

const PricingBody = styled.ul`
  margin-bottom: 40px;
  padding: 0;
  text-align: left;

  li {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 18px;
    list-style-type: none;
  }
`;

const PricingButton = styled.a`
  display: inline-block;
  border-radius: 30px;
  padding: 15px 40px;
  background: ${({ border }) => (border ? 'var(--white)' : 'var(--primary-color)')};
  color: ${({ border }) => (border ? 'var(--primary-color)' : 'var(--white)')};
  border: ${({ border }) => (border ? '1px solid #d4deff' : 'none')};
  margin-top: 20px;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ border }) => (border ? 'var(--primary-color)' : '#2748b4')};
    color: ${({ border }) => (border ? 'var(--white)' : 'var(--white)')};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
`;

const Pricing = () => {
  return (
    <PricingSection id="pricing">
      <div className="container">
        <SectionTitle>
          <span>Pricing</span>
          <h2>Our Pricing Plans</h2>
          <p>
            There are many variations of passages of Lorem Ipsum available but the majority have
            suffered alteration in some form.
          </p>
        </SectionTitle>

        <PricingWrapper>
          <PricingCard className="ud-single-pricing" active={false}>
            <PricingHeader>
              <h3>STARTING FROM</h3>
              <h4>$ 19.99/mo</h4>
            </PricingHeader>
            <PricingBody>
              <li>5 User</li>
              <li>All UI components</li>
              <li>Lifetime access</li>
              <li>Free updates</li>
              <li>Use on 1 (one) project</li>
              <li>4 Months support</li>
            </PricingBody>
            <PricingButton className="ud-main-btn ud-border-btn" border>
              Purchase Now
            </PricingButton>
          </PricingCard>

          <PricingCard className="ud-single-pricing active" active>
            <PopularTag>POPULAR</PopularTag>
            <PricingHeader active>
              <h3>STARTING FROM</h3>
              <h4>$ 30.99/mo</h4>
            </PricingHeader>
            <PricingBody>
              <li>5 User</li>
              <li>All UI components</li>
              <li>Lifetime access</li>
              <li>Free updates</li>
              <li>Use on 1 (one) project</li>
              <li>4 Months support</li>
            </PricingBody>
            <PricingButton className="ud-main-btn ud-white-btn">Purchase Now</PricingButton>
          </PricingCard>

          <PricingCard className="ud-single-pricing" active={false}>
            <PricingHeader>
              <h3>STARTING FROM</h3>
              <h4>$ 70.99/mo</h4>
            </PricingHeader>
            <PricingBody>
              <li>5 User</li>
              <li>All UI components</li>
              <li>Lifetime access</li>
              <li>Free updates</li>
              <li>Use on 1 (one) project</li>
              <li>4 Months support</li>
            </PricingBody>
            <PricingButton className="ud-main-btn ud-border-btn" border>
              Purchase Now
            </PricingButton>
          </PricingCard>
        </PricingWrapper>
      </div>
    </PricingSection>
  );
};

export default Pricing;
