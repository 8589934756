// paymentLinkActions.js
import * as linkTypes from '../types/paymentLinkTypes';
import * as paymentLinkServices from '../../services/paymentLinkServices';

// Action creator to get all payment links
export const getPaymentLinks = () => async (dispatch) => {
  dispatch({ type: linkTypes.GET_PAYMENT_LINKS_REQUEST });
  try {
    const links = await paymentLinkServices.getPaymentLinks();
    dispatch({ type: linkTypes.GET_PAYMENT_LINKS_SUCCESS, payload: links });
  } catch (error) {
    dispatch({ type: linkTypes.GET_PAYMENT_LINKS_FAILURE, payload: error.message });
  }
};

// Action creator to get a specific payment link by ID
export const getPaymentLink = (id) => async (dispatch) => {
  dispatch({ type: linkTypes.GET_PAYMENT_LINK_REQUEST });
  try {
    const link = await paymentLinkServices.getPaymentLink(id);
    dispatch({ type: linkTypes.GET_PAYMENT_LINK_SUCCESS, payload: link });
  } catch (error) {
    dispatch({ type: linkTypes.GET_PAYMENT_LINK_FAILURE, payload: error.message });
  }
};

// Action creator to create a new payment link
export const createPaymentLink = (paymentLinkData) => async (dispatch) => {
  dispatch({ type: linkTypes.CREATE_PAYMENT_LINK_REQUEST });
  try {
    const newLink = await paymentLinkServices.createPaymentLink(paymentLinkData);
    dispatch({ type: linkTypes.CREATE_PAYMENT_LINK_SUCCESS, payload: newLink });
  } catch (error) {
    dispatch({ type: linkTypes.CREATE_PAYMENT_LINK_FAILURE, payload: error.message });
  }
};

// Action creator to update an existing payment link by ID
export const updatePaymentLink = (id, paymentLinkData) => async (dispatch) => {
  dispatch({ type: linkTypes.UPDATE_PAYMENT_LINK_REQUEST });
  try {
    const updatedLink = await paymentLinkServices.updatePaymentLink(id, paymentLinkData);
    dispatch({ type: linkTypes.UPDATE_PAYMENT_LINK_SUCCESS, payload: updatedLink });
  } catch (error) {
    dispatch({ type: linkTypes.UPDATE_PAYMENT_LINK_FAILURE, payload: error.message });
  }
};

// Action creator to delete a payment link by ID
export const deletePaymentLink = (id) => async (dispatch) => {
  dispatch({ type: linkTypes.DELETE_PAYMENT_LINK_REQUEST });
  try {
    await paymentLinkServices.deletePaymentLink(id);
    dispatch({ type: linkTypes.DELETE_PAYMENT_LINK_SUCCESS, payload: { _id: id } });
  } catch (error) {
    dispatch({ type: linkTypes.DELETE_PAYMENT_LINK_FAILURE, payload: error.message });
  }
};
