import React from 'react';
import styled from 'styled-components';
import TicketForm from '../../components/help/ticketForm';
import DashHeader from '../../components/common/DashHeader';

const Container = styled.div`
 display:flex;
 justify-content: center;
 align-items: center;
  height: 100vh;
  background-color:#f5f5f5;
`;

const SubContainer = styled.div`
  width: 100%;
  max-width:800px;
  
 
`


const TicketPage = () => {
  return (
    <div>
      <DashHeader/>
      <Container>
      <SubContainer>
          <TicketForm />
      </SubContainer>
      </Container>
      
      
    </div>
  );
};

export default TicketPage;
