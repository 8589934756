import * as ticketTypes from '../types/ticketTypes';
import * as ticketServices from '../../services/ticketServices';

// Action to create a ticket
export const createTicket = (ticketData) => async (dispatch) => {
  dispatch({ type: ticketTypes.CREATE_TICKET_REQUEST });
  try {
    const data = await ticketServices.createTicket(ticketData);
    dispatch({
      type: ticketTypes.CREATE_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ticketTypes.CREATE_TICKET_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Action to get all tickets
export const getAllTickets = () => async (dispatch) => {
  dispatch({ type: ticketTypes.GET_ALL_TICKETS_REQUEST });
  try {
    const data = await ticketServices.getAllTickets();
    dispatch({
      type: ticketTypes.GET_ALL_TICKETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ticketTypes.GET_ALL_TICKETS_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Action to get a ticket by ID
export const getTicketById = (ticketId) => async (dispatch) => {
  dispatch({ type: ticketTypes.GET_TICKET_BY_ID_REQUEST });
  try {
    const data = await ticketServices.getTicketById(ticketId);
    dispatch({
      type: ticketTypes.GET_TICKET_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ticketTypes.GET_TICKET_BY_ID_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Action to update the ticket status
export const updateTicketStatus = (ticketId, status) => async (dispatch) => {
  dispatch({ type: ticketTypes.UPDATE_TICKET_STATUS_REQUEST });
  try {
    const data = await ticketServices.updateTicketStatus(ticketId, status);
    dispatch({
      type: ticketTypes.UPDATE_TICKET_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ticketTypes.UPDATE_TICKET_STATUS_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};

// Action to update the ticket priority
export const updateTicketPriority = (ticketId, priority) => async (dispatch) => {
  dispatch({ type: ticketTypes.UPDATE_TICKET_PRIORITY_REQUEST });
  try {
    const data = await ticketServices.updateTicketPriority(ticketId, priority);
    dispatch({
      type: ticketTypes.UPDATE_TICKET_PRIORITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ticketTypes.UPDATE_TICKET_PRIORITY_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};
