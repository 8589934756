// PackageFormPage.js
import React from 'react';

import styled from 'styled-components';

import EmailTemplateForm from '../components/email/EmailForm';

const Container = styled.div`
  margin-top: 100px;
`


const EmailFormPage = () => {
  

  return (
    <Container>
      <EmailTemplateForm />
    </Container>
  );
};

export default EmailFormPage;
