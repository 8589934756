// articleReducer.js
import * as types from '../types/articleTypes';

const initialState = {
  articles: [],
  article: null,
  loading: false,
  error: null,
  feedbackSuccess: false,
  voteSuccess: false,
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    // CREATE ARTICLE
    case types.CREATE_ARTICLE_REQUEST:
      return { ...state, loading: true, error: null };
    case types.CREATE_ARTICLE_SUCCESS:
      return { ...state, loading: false, articles: [action.payload, ...state.articles] };
    case types.CREATE_ARTICLE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // GET ALL ARTICLES
    case types.GET_ALL_ARTICLES_REQUEST:
      return { ...state, loading: true, error: null };
    case types.GET_ALL_ARTICLES_SUCCESS:
      return { ...state, loading: false, articles: action.payload };
    case types.GET_ALL_ARTICLES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // GET ARTICLE BY ID
    case types.GET_ARTICLE_BY_ID_REQUEST:
      return { ...state, loading: true, error: null, article: null };
    case types.GET_ARTICLE_BY_ID_SUCCESS:
      return { ...state, loading: false, article: action.payload };
    case types.GET_ARTICLE_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // UPDATE ARTICLE
    case types.UPDATE_ARTICLE_REQUEST:
      return { ...state, loading: true, error: null };
    case types.UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        articles: state.articles.map((article) =>
          article.id === action.payload.id ? action.payload : article
        ),
      };
    case types.UPDATE_ARTICLE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // DELETE ARTICLE
    case types.DELETE_ARTICLE_REQUEST:
      return { ...state, loading: true, error: null };
    case types.DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        articles: state.articles.filter((article) => article.id !== action.payload),
      };
    case types.DELETE_ARTICLE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // INCREMENT ARTICLE VIEWS
    case types.INCREMENT_ARTICLE_VIEWS_REQUEST:
      return { ...state, loading: true, error: null };
    case types.INCREMENT_ARTICLE_VIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        article: { ...state.article, views: action.payload.views },
      };
    case types.INCREMENT_ARTICLE_VIEWS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // ADD FEEDBACK TO ARTICLE
    case types.ADD_FEEDBACK_REQUEST:
      return { ...state, loading: true, error: null, feedbackSuccess: false };
    case types.ADD_FEEDBACK_SUCCESS:
      return { ...state, loading: false, feedbackSuccess: true };
    case types.ADD_FEEDBACK_FAILURE:
      return { ...state, loading: false, error: action.payload, feedbackSuccess: false };

    // VOTE ARTICLE HELPFUL
    case types.VOTE_ARTICLE_HELPFUL_REQUEST:
      return { ...state, loading: true, error: null, voteSuccess: false };
    case types.VOTE_ARTICLE_HELPFUL_SUCCESS:
      return { ...state, loading: false, voteSuccess: true };
    case types.VOTE_ARTICLE_HELPFUL_FAILURE:
      return { ...state, loading: false, error: action.payload, voteSuccess: false };

    // VOTE ARTICLE NOT HELPFUL
    case types.VOTE_ARTICLE_NOT_HELPFUL_REQUEST:
      return { ...state, loading: true, error: null, voteSuccess: false };
    case types.VOTE_ARTICLE_NOT_HELPFUL_SUCCESS:
      return { ...state, loading: false, voteSuccess: true };
    case types.VOTE_ARTICLE_NOT_HELPFUL_FAILURE:
      return { ...state, loading: false, error: action.payload, voteSuccess: false };

    default:
      return state;
  }
};

export default articleReducer;
