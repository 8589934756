import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  background: #f3f4fe;
  padding: 120px 0;

  @media (max-width: 767px) {
    padding: 80px 0;
  }
`;

const AboutWrapper = styled.div`
  background: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    display: block;
  }
`;

const AboutContentWrapper = styled.div`
  padding: 70px;

  @media (max-width: 767px) {
    padding: 50px 30px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 50px;
  }
`;

const AboutContent = styled.div`
  max-width: 500px;

  .tag {
    font-weight: 500;
    font-size: 14px;
    color: var(--white);
    background: var(--primary-color);
    padding: 5px 20px;
    display: inline-block;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 38px;
    }
  }

  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 40px;
  }

  .ud-main-btn:hover {
    background: #2748b4;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
`;

const AboutImage = styled.div`
  @media (max-width: 991px) {
    text-align: center;
  }

  img {
    max-width: 100%;
  }
`;

const About = () => {
  return (
    <AboutSection id="about">
      <div className="container">
        <AboutWrapper>
          <AboutContentWrapper>
            <AboutContent>
              <span className="tag">About Us</span>
              <h2>Brilliant Toolkit to Build Nextgen Website Faster.</h2>
              <p>
                The main ‘thrust’ is to focus on educating attendees on how to
                best protect highly vulnerable business applications with
                interactive panel discussions and roundtables led by subject
                matter experts.
              </p>
              <p>
                The main ‘thrust’ is to focus on educating attendees on how to
                best protect highly vulnerable business applications with
                interactive panel.
              </p>
              <a href="javascript:void(0)" className="ud-main-btn">Learn More</a>
            </AboutContent>
          </AboutContentWrapper>
          <AboutImage>
            <img src="assets/images/about/about-image.svg" alt="about-image" />
          </AboutImage>
        </AboutWrapper>
      </div>
    </AboutSection>
  );
};

export default About;
