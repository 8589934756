import React, { useState } from 'react';
import { Card, Switch, Button } from 'antd';
import styled from 'styled-components';

const NotificationsCard = styled(Card)`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
  padding: 20px;
  width: 410px;
  height: 100px;

  p {
    font-size: 20px;
    font-weight: 800;
    position: relative;
    display: flex;
    align-items: center;
  }

  div {
    padding-bottom: 10px;
  }

  span {
    font-size: 18px;
    font-weight: 500;
    margin-left: auto;
  }

  .switch-label {
    margin-right: auto;
  }
`;

const NotificationsPreferences = () => {
  const [emailNotifications, setEmailNotifications] = useState(true);

  const handleToggleEmailNotifications = (checked) => {
    // Handle logic to update user's notification preferences
    setEmailNotifications(checked);
  };

  return (
    <NotificationsCard>
      <div>
        <p className="switch-label">Email Notifications</p>
        <Switch checked={emailNotifications} onChange={handleToggleEmailNotifications} />
      </div>
    </NotificationsCard>
  );
};

export default NotificationsPreferences;
