import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { RiBarcodeLine, RiTruckLine, RiFileTextLine } from 'react-icons/ri';
import { getCourierByCodeAction } from '../../store/actions/packageActions';

const GlobalStyle = createGlobalStyle`
  :root {
    --color-blue-100: #e0f2fe;
    --color-blue-700: #0369a1;
    --color-green-100: #dcfce7;
    --color-green-700: #15803d;
    --color-yellow-100: #fef9c3;
    --color-yellow-700: #a16207;
    --color-silver-100: #e5e7eb;
    --color-silver-700: #374151;
    --color-indigo-100: #e0e7ff;
    --color-indigo-700: #4338ca;
    --color-red-100: #fee2e2;
    --color-red-700: #b91c1c;
    --color-red-800: #991b1b;
  }
`;

const StyledStatsContainer = styled.div`
  padding: 20px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  overflow-x: hidden;
`;

const StatCard = styled.div`
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 20px;
 
  min-width: 250px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    
     width:86%;
  }
`;

const StyledStatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  margin-bottom: 10px;
  margin-left:10px;
  font-weight: 700;
`;

const StyledLabel = styled.span`
  margin-right: 10px;
  font-size: 25px;
`;

const StyledValue = styled.span`
  font-size: 18px;
`;

const StyledImage = styled.img`
  aspect-ratio: 1;
  width: 70px; /* Adjust size as needed */
  height: 70px; /* Adjust size as needed */
  border-radius: 50%;
  margin-right: 9px;
`;

const StyledIcon = styled.div`
  aspect-ratio: 1;
  width: 70px; /* Adjust size as needed */
  height: 70px; /* Adjust size as needed */
  border-radius: 50%;
  margin-right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px; /* Adjust icon size */
`;

const TrackingStats = ({ trackingNumber, courier, deliveryStatus, category }) => {
const dispatch = useDispatch();
  
useEffect(() => {
  if (courier) {
    dispatch(getCourierByCodeAction(courier));
  }
   
}, [dispatch]);

  
  const courierDetails = useSelector((state) => state.package.courier);
  const loading = useSelector((state) => state.package.loading);
  const error = useSelector((state) => state.package.error);

  


  // Ensure courier logo URL starts with https and exists
  let courierLogoUrl = '';
  if (courierDetails && courierDetails.courier_logo) {
    courierLogoUrl = courierDetails.courier_logo.startsWith('https')
      ? courierDetails.courier_logo
      : `https://${courierDetails.courier_logo}`;
  }

  return (
    <>
      <GlobalStyle />
      <StyledStatsContainer>
      {loading && <p>Loading courier details...</p>}
      {error && <p>Error fetching courier details: {error}</p>}
      {courier && courierDetails && (
         <StatCard>
             {courierLogoUrl && (
             <StyledImage src={courierLogoUrl} alt={`${courier} logo`} />
          )}
          <StyledStatItem>
      <StyledLabel>{courierDetails.courier_name}</StyledLabel>
      <StyledValue>{courierDetails.courier_phone}</StyledValue>
    </StyledStatItem>
  </StatCard>
)}
        <StatCard>
          <StyledIcon>
            <RiBarcodeLine />
          </StyledIcon>
          <StyledStatItem>
            <StyledLabel>Tracking Number</StyledLabel>
            <StyledValue>{trackingNumber}</StyledValue>
          </StyledStatItem>
        </StatCard>
        <StatCard>
          <StyledIcon>
            <RiTruckLine />
          </StyledIcon>
          <StyledStatItem>
            <StyledLabel>Delivery Status</StyledLabel>
            <StyledValue>{deliveryStatus}</StyledValue>
          </StyledStatItem>
        </StatCard>
        <StatCard>
          <StyledIcon>
            <RiFileTextLine />
          </StyledIcon>
          <StyledStatItem>
            <StyledLabel>Category</StyledLabel>
            <StyledValue>{category}</StyledValue>
          </StyledStatItem>
        </StatCard>
      </StyledStatsContainer>
    </>
  );
};

export default TrackingStats;
