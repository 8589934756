import React from 'react';
import styled from 'styled-components';
import { Layout, Menu, Row, Col, Input, Button, Typography, Card, Carousel } from 'antd';
import {
  SearchOutlined,
  MobileOutlined,
  LikeOutlined,
  AppstoreAddOutlined,
} from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

// Styled components
const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledHeader = styled(Header)`
  background: #03045e;
  padding: 24px 0;
`;

const StyledTitle = styled(Title)`
  color: white;
  margin-bottom: 0;
`;

const HeroSection = styled.div`
  text-align: center;
  padding: 50px 0;
`;

const StyledSearchCard = styled(Card)`
  width: 80%;
  margin: auto;
  text-align: center;
`;

const StyledCarousel = styled(Carousel)`
  margin-top: 50px;
`;

const StyledFooter = styled(Footer)`
  text-align: center;
`;

const StyledHeroTitle = styled(Title)`
  color: #03045e;
`;

const StyledFeatureSection = styled.div`
  text-align: center;
  padding: 50px 0;
`;

const FeatureCard = styled(Card)`
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
`;

const StyledContactSection = styled.div`
  background-color: #f0f0f0;
  text-align: center;
  padding: 50px 0;
`;

const ContactCard = styled(Card)`
  width: 60%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
`;


const HistoryPage = () => {
  return (
    <StyledLayout>
      <StyledHeader>
        <Row justify="space-between">
          <Col>
            <StyledTitle level={2}>Package Tracker</StyledTitle>
          </Col>
          <Col>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
              <Menu.Item key="1">Home</Menu.Item>
              <Menu.Item key="2">Tracking History</Menu.Item>
              <Menu.Item key="3">Settings</Menu.Item>
            </Menu>
          </Col>
        </Row>
      </StyledHeader>
      <Content>
        <HeroSection>
          <StyledHeroTitle level={1}>Track Your Packages with Ease</StyledHeroTitle>
          <Paragraph>
            A modern solution to keep all your online orders in one place. Never lose track again!
          </Paragraph>
          <StyledSearchCard title="Track a Package" extra={<SearchOutlined />}>
            <Input placeholder="Enter tracking number" />
            <Button type="primary" style={{ marginTop: '16px' }}>
              Track Now
            </Button>
          </StyledSearchCard>
        </HeroSection>
        <StyledCarousel autoplay>
          <div>
            <img src="https://via.placeholder.com/1200x400" alt="Feature 1" />
          </div>
          <div>
            <img src="https://via.placeholder.com/1200x400" alt="Feature 2" />
          </div>
          <div>
            <img src="https://via.placeholder.com/1200x400" alt="Feature 3" />
          </div>
        </StyledCarousel>
        <StyledFeatureSection>
          <StyledHeroTitle level={2}>Key Features</StyledHeroTitle>
          <Row gutter={16}>
            <Col span={8}>
              <FeatureCard>
                <MobileOutlined style={{ fontSize: '36px', color: '#1890ff' }} />
                <Title level={4}>Mobile-Friendly</Title>
                <Paragraph>Track your packages on the go with our mobile-friendly app.</Paragraph>
              </FeatureCard>
            </Col>
            <Col span={8}>
              <FeatureCard>
                <LikeOutlined style={{ fontSize: '36px', color: '#1890ff' }} />
                <Title level={4}>Easy to Use</Title>
                <Paragraph>Intuitive interface for a seamless and hassle-free tracking experience.</Paragraph>
              </FeatureCard>
            </Col>
            <Col span={8}>
              <FeatureCard>
                <AppstoreAddOutlined style={{ fontSize: '36px', color: '#1890ff' }} />
                <Title level={4}>One-Stop Solution</Title>
                <Paragraph>Track packages from multiple online shops in one place.</Paragraph>
              </FeatureCard>
            </Col>
          </Row>
        </StyledFeatureSection>
        <StyledContactSection>
          <StyledHeroTitle level={2}>Contact Us</StyledHeroTitle>
          <Paragraph>If you have any questions or need assistance, feel free to reach out.</Paragraph>
          <ContactCard title="Contact Information">
            <Paragraph>Email: info@packagetracker.com</Paragraph>
            <Paragraph>Phone: +1 (123) 456-7890</Paragraph>
          </ContactCard>
        </StyledContactSection>
      </Content>
      <StyledFooter>
        Package Tracker ©2024 Created by Your Company
      </StyledFooter>
    </StyledLayout>
  );
};

export default HistoryPage;
