import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPackageAction, addPackagesAction } from '../../store/actions/packageActions';
import styled from 'styled-components';
import CourierSelectionModal from './CourierSelectionModal';
import { FaTimes } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';

// Styled components
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  @media (max-width: 768px) {
    margin-top: 90px; 
    margin-left: 20px;
    
  }
`;

const FormContent = styled.div`
  flex: 1;
  padding-right: 60px;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
  width: 90%;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
`;

const TextArea = styled.textarea`
  padding: 8px;
  font-size: 14px;
  width: 100%;
  height:70px;
  resize: vertical;
`;

const SelectButton = styled.button`
  background-color: #e5e7eb;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
  color: black;
  cursor: pointer;

  &:hover {
    border: 2px solid #d97706;
  }
`;

const SelectButtonText = styled.span`
  font-size: 16px;
`;

const CategoriesContainer = styled.div`
  margin-bottom: 20px;
`;

const CategoryButton = styled.button`
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #000;
  cursor: pointer;
  border: 1px solid #000;

  ${({ selected }) =>
    selected &&
    `
    border: 2px solid #3b0086; // Purple border color when selected
    
  `}

  &:hover {
    border-color: #3c096c;
  }
`;

const ButtonContainer = styled.div`
 display: flex;
 justify-content: flex-start;
`

const SubmitButton = styled.button`
  background-color: #3b0086;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #2c0063;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ConfirmButton = styled.button`
  background-color: #6200b3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
`;

const ErrorModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorModalContent = styled.div`
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  position: relative;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const PremiumPackageForm = ({ usedTracks, allowedTracks, remainingDays, plan }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.package.loading);
  const error = useSelector((state) => state.package.error);

  console.log(error);

  const [formData, setFormData] = useState({
    trackingNumbers: '',
    packageDescription: '',
    selectedCategory: null,
    courier: null,
    userId: userId,
  });

  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);
  const [isCourierModalVisible, setIsCourierModalVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  useEffect(() => {
    if ((plan === 'Trial' && (remainingDays === 0 || usedTracks === allowedTracks)) ||
        (remainingDays === 0 || usedTracks === allowedTracks)) {
      setIsUpgradeModalVisible(true);
    }
  }, [plan, remainingDays, usedTracks, allowedTracks]);

  useEffect(() => {
    if (error) {
      setIsErrorModalVisible(true); // Show modal if there's an error
    }
  }, [error]);

  const handleSave = (e) => {
    e.preventDefault();

    // Validate form fields
  if (
    !formData.trackingNumbers.trim() ||
    !formData.packageDescription.trim() ||
    !formData.selectedCategory ||
    !formData.courier
  ) {
    alert('Please fill in all fields and ensure courier and category are selected.');
    return;
  }

    const trackingNumbersArray = formData.trackingNumbers.split(',').map((number) => number.trim());

    const packagesData = trackingNumbersArray.map((trackingNumber) => ({
      trackingNumber,
      packageDescription: formData.packageDescription,
      selectedCategory: formData.selectedCategory,
      courier: formData.courier.courier_code,
      userId: userId,
    }));

    dispatch(addPackagesAction(packagesData));

    setFormData({
      trackingNumbers: '',
      packageDescription: '',
      selectedCategory: null,
      courier: null,
      userId: userId,
    });

    
  };

  const categories = [
    
    'Sports',
    'Food',
    'Mother & Kids',
    'Health & Beauty',
    'Flower & Gifts',
    'Outdoor & Travel',
    'Clothing Apparel',
    'Home And Gardening',
    'Games, Toys & Books',
    'Others',
  ];

  return (
    <FormContainer id="packageForm" >
      <FormGroup>
        <Label>Tracking Numbers:</Label>
        <TextArea
          value={formData.trackingNumbers}
          onChange={(e) =>
            setFormData((prevData) => ({
              ...prevData,
              trackingNumbers: e.target.value,
            }))
          }
          placeholder="Enter tracking numbers, separated by commas"
        />
      </FormGroup>

      <FormGroup>
        <Label>Package Description:</Label>
        <TextArea
          value={formData.packageDescription}
          onChange={(e) =>
            setFormData((prevData) => ({
              ...prevData,
              packageDescription: e.target.value,
            }))
          }
        />
      </FormGroup>

      <FormGroup>
        <Label>Select Courier:</Label>
        <SelectButton type="button" onClick={() => setIsCourierModalVisible(true)}>
          {formData.courier ? `Selected: ${formData.courier.courier_name}` : 'Select Courier'}
        </SelectButton>
      </FormGroup>

      <CategoriesContainer>
        <Label>Categories:</Label>
        {categories.map((category, index) => (
          <CategoryButton
            key={index}
            type="button"
            selected={formData.selectedCategory === category}
            onClick={() =>
              setFormData((prevData) => ({
                ...prevData,
                selectedCategory: category,
              }))
            }
          >
            {category}
          </CategoryButton>
        ))}
      </CategoriesContainer>

      <ButtonContainer>
        <SubmitButton type="submit" onClick={handleSave} disabled={loading}>
          {loading ? 'Adding...' : 'Add Package'}
        </SubmitButton>
      </ButtonContainer>

       {/* Loading spinner */}
       {loading && (
        <SpinnerWrapper>
          <ClipLoader size={30} color="#3b0086" loading={loading} />
        </SpinnerWrapper>
      )}

      {isUpgradeModalVisible && (
        <ModalContainer>
          <ModalContent>
            <CloseButton onClick={() => setIsUpgradeModalVisible(false)}>
              <FaTimes />
            </CloseButton>
            <ModalTitle>Plan Upgrade Required</ModalTitle>
            <p>
              You have reached your limit of {allowedTracks} tracking numbers.
              Please upgrade your plan to continue.
            </p>
            <ConfirmButton onClick={() => setIsUpgradeModalVisible(false)}>Close</ConfirmButton>
          </ModalContent>
        </ModalContainer>
      )}
      {isErrorModalVisible && (
        <ErrorModalContainer>
          <ErrorModalContent>
            <CloseButton onClick={() => setIsErrorModalVisible(false)}>
              <FaTimes />
            </CloseButton>
            <ModalTitle>Error</ModalTitle>
            <p>{error || 'An error occurred. Please try again later.'}</p>
            <ConfirmButton onClick={() => setIsErrorModalVisible(false)}>Close</ConfirmButton>
          </ErrorModalContent>
        </ErrorModalContainer>
      )}


      <CourierSelectionModal
        onSelectCourier={(selectedCourier) => {
          setFormData((prevData) => ({
            ...prevData,
            courier: selectedCourier,
          }));
          setIsCourierModalVisible(false);
        }}
        onClose={() => setIsCourierModalVisible(false)}
        visible={isCourierModalVisible}
      />
    </FormContainer>
  );
};

export default PremiumPackageForm;