// ProfileStats.js
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  HiOutlineCheckCircle,
  HiLibrary,
  HiOutlineClock,
  HiOutlineExclamation,
} from 'react-icons/hi';

const GlobalStyle = createGlobalStyle`
  :root {
    --color-blue-100: #e0f2fe;
    --color-blue-700: #0369a1;
    --color-green-100: #dcfce7;
    --color-green-700: #15803d;
    --color-yellow-100: #fef9c3;
    --color-indigo-100: #e0e7ff;
    --color-indigo-700: #4338ca;
    --color-red-100: #fee2e2;
    --color-red-700: #b91c1c;
  }
`;

const StyledStatsContainer = styled.div`
  padding: 40px;
  margin-top: 60px;
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  gap: 20px;
  overflow-x: hidden;
`;

const StatCard = styled.div`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
`;

const StyledStatItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 700;
`;

const StyledIcon = styled.span`
  margin-right: 9px;
  background-color: var(--color-${(props) => props.color}-100);
  padding: 8px;
  border-radius: 50%;
  
  & svg {
    width: 3.2rem;
    height: 3.2rem;
    color: var(--color-${(props) => props.color}-700);
  }
`;

const ProfileStats = ({ delivered, inTransit, pending, expired }) => {
  
  return (
    <>
      <GlobalStyle />
      <StyledStatsContainer>
        <StatCard>
          <StyledStatItem>
            <StyledIcon color="blue">
              <HiOutlineCheckCircle />
            </StyledIcon>
            <span>Total Delivered: {delivered}</span>
          </StyledStatItem>
        </StatCard>
        <StatCard>
          <StyledStatItem>
            <StyledIcon color="green">
              <HiLibrary />
            </StyledIcon>
            <span>Total In Transit: {inTransit}</span>
          </StyledStatItem>
        </StatCard>
        <StatCard>
          <StyledStatItem>
            <StyledIcon color="indigo">
              <HiOutlineClock />
            </StyledIcon>
            <span>Total Pending: {pending}</span>
          </StyledStatItem>
        </StatCard>
        <StatCard>
          <StyledStatItem>
            <StyledIcon color="red">
              <HiOutlineExclamation />
            </StyledIcon>
            <span>Total Expired: {expired}</span>
          </StyledStatItem>
        </StatCard>
      </StyledStatsContainer>
    </>
  );
};

export default ProfileStats;
