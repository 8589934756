import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';



const UsageTracker = ({ usedTracks, allowedTracks, remainingDays, plan }) => {
  
  const { t } = useTranslation();
  const progress = allowedTracks !== 0 ? (usedTracks / allowedTracks) * 100 : 0;

  // SVG circle properties
  const radius = 70; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle

  return (
    <UsageTrackerContainer>
      <h3>{t('Usage Tracker')}</h3>
      <CircularProgress>
        <CircleBackground cx="80" cy="80" r={radius} />
        <CircleProgress
          cx="80"
          cy="80"
          r={radius}
          circumference={circumference}
          progress={progress}
        />
        <ProgressText>{Math.round(progress)}%</ProgressText>
      </CircularProgress>
      <StyledP>{t('tracks used out of', { usedTracks, allowedTracks })}</StyledP>
      <StyledP>{t('days to renew your plan', { remainingDays, plan })}</StyledP>

      {remainingDays < 3 && (
        <>
          
          <RenewButton> {t('Renew')}</RenewButton>
        </>
      )}
    </UsageTrackerContainer>
  );
};

// Styled components for styling
const UsageTrackerContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 350px;
  text-align: center;
  margin-left: 40px;
`;

const CircularProgress = styled.svg`
  transform: rotate(-90deg);
  width: 160px;
  height: 160px;
  margin: 20px auto;
`;

const CircleBackground = styled.circle`
  fill: none;
  stroke: #f3f3f3;
  stroke-width: 15;
`;

const CircleProgress = styled.circle`
  fill: none;
  stroke: #007bff;
  stroke-width: 15;
  stroke-dasharray: ${(props) => props.circumference};
  stroke-dashoffset: ${(props) => props.circumference - (props.progress / 100) * props.circumference};
  transition: stroke-dashoffset 0.3s ease;
`;

const ProgressText = styled.div`
  position: relative;
  top: -110px;  /* Adjust based on SVG size */
  font-size: 24px;
  font-weight: bold;
`;

const StyledP = styled.p`
  font-family: 'Arial', sans-serif;  /* Choose a good font */
  font-size: 16px;                   /* Adjust the font size */
  color: #333;                       /* Font color */
  margin-top: 10px;                  /* Space above the text */
  margin-bottom: 0;                  /* Remove default margin */
  line-height: 1.5;                  /* Line height for readability */
`;

const RenewButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #218838;
  }
`;

export default UsageTracker;
