import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import mbzlogo from '../../Images/mbz_logoo.png';

const FooterContainer = styled.footer`
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
`;

const Branding = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const LogoContainer = styled.div`
  background-color: #fff; /* White background for visibility */
  display: inline-block; /* Ensures the container wraps around the logo */
  padding: 10px; /* Optional padding for spacing */
  border-radius: 10px; /* Optional rounded corners for aesthetics */
`;

const Logo = styled.img`
  max-width: 150px;
`;

const Tagline = styled.p`
  margin-top: 10px;
  font-size: 16px;
`;

const Navigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
`;

const Column = styled.div`
  flex: 1;
  margin: 10px;

  @media (max-width: 768px) {
    flex: 100%;
    margin: 0;
  }
`;

const ColumnTitle = styled.h4`
  font-size: 18px;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SocialMedia = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const SocialLink = styled.a`
  margin: 0 10px;
  color: #fff;
  font-size: 24px;

  &:hover {
    color: #1976d2;
  }
`;

const LegalLinks = styled.div`
  margin-top: 20px;
  text-align: center;

  @media (min-width: 769px) {
    text-align: left;
  }
`;

const Newsletter = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const NewsletterTitle = styled.h4`
  font-size: 18px;
  margin-bottom: 10px;
`;

const NewsletterForm = styled.form`
  display: flex;
  justify-content: center;
`;

const EmailInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
`;

const SubscribeButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #1976d2;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #1565c0;
  }
`;

const PlayStore = styled.div`
  text-align: center;
  margin-top: 20px;

  a {
    display: inline-flex;
    align-items: center;
    color: #fff;

    img {
      width: 150px; /* Adjust the size as needed */
    }
  }
`;

const Bottom = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Navigation>
        <Column>
          <ColumnTitle>Track4U</ColumnTitle>
          <p>Track4U is your go-to platform for seamlessly tracking all your packages in one place. Stay updated with real-time status, delivery notifications, and manage multiple shipments effortlessly.</p>
          <a href="https://mbztechnology.com" target="_blank" rel="noopener noreferrer">
    <LogoContainer>
      <Logo src={mbzlogo} alt="MBZTechnology Logo" />
    </LogoContainer>
  </a>
         
        </Column>
        <Column>
          <ColumnTitle>Contact</ColumnTitle>
          <p>Email</p>
          
          <p><Link href="mailto:info@company.com">info@track4u.net</Link></p>
        </Column>
        <Column>
          <ColumnTitle>About Us</ColumnTitle>
          <List>
            <ListItem><Link href="/about">About</Link></ListItem>
            <ListItem><Link href="/faq">FAQ</Link></ListItem>
            <ListItem><Link href="/help/center/all">Help</Link></ListItem>
          </List>
        </Column>
        <Column>
          <ColumnTitle>Legal</ColumnTitle>
          <List>
            <ListItem><Link href="/privacy/policy">Privacy Policy</Link></ListItem>
            <ListItem><Link href="/terms">Terms of Service</Link></ListItem>
            <ListItem><Link href="/cookie/policy">Cookie Policy</Link></ListItem>
          </List>
        </Column>
      </Navigation>

      <SocialMedia>
        <SocialLink href="#"><FaFacebook /></SocialLink>
        <SocialLink href="#"><FaTwitter /></SocialLink>
        <SocialLink href="#"><FaLinkedin /></SocialLink>
        <SocialLink href="#"><FaInstagram /></SocialLink>
      </SocialMedia>

      <PlayStore>
        <a href="https://play.google.com/store/apps/details?id=com.example">
          <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Get it on Google Play" />
        </a>
      </PlayStore>

      <Newsletter>
        <NewsletterTitle>Subscribe to our Newsletter</NewsletterTitle>
        <NewsletterForm>
          <EmailInput type="email" placeholder="Enter your email" />
          <SubscribeButton type="submit">Subscribe</SubscribeButton>
        </NewsletterForm>
      </Newsletter>

      <Bottom>
        <p>© 2024 Track4U. All rights reserved.</p>
      </Bottom>
    </FooterContainer>
  );
};

export default Footer;
