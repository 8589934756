import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { createFeedbackAction } from '../store/actions/feedbackActions';

const FeedbackContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const FeedbackForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Label = styled.label`
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Textarea = styled.textarea`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 0.7rem;
  background-color: #3b0086;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FeedbackPage = () => {
  const dispatch = useDispatch();

  const [feedbackData, setFeedbackData] = useState({
    feedbackText: '',
    rating: 1,
    category: 'General Comment',
    priority: 'Medium',
    attachments: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFeedbackData((prevData) => ({
      ...prevData,
      attachments: files,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createFeedbackAction(feedbackData));
  };

  return (
    <FeedbackContainer>
      <h1>Submit Feedback</h1>
      <FeedbackForm onSubmit={handleSubmit}>
        <Label htmlFor="feedbackText">Feedback</Label>
        <Textarea
          id="feedbackText"
          name="feedbackText"
          rows="5"
          value={feedbackData.feedbackText}
          onChange={handleChange}
          required
        />
        
        <Label htmlFor="rating">Rating</Label>
        <Select
          id="rating"
          name="rating"
          value={feedbackData.rating}
          onChange={handleChange}
          required
        >
          <option value={1}>1 - Very Bad</option>
          <option value={2}>2 - Bad</option>
          <option value={3}>3 - Okay</option>
          <option value={4}>4 - Good</option>
          <option value={5}>5 - Excellent</option>
        </Select>
        
        <Label htmlFor="category">Category</Label>
        <Select
          id="category"
          name="category"
          value={feedbackData.category}
          onChange={handleChange}
          required
        >
          <option value="Bug Report">Bug Report</option>
          <option value="Feature Request">Feature Request</option>
          <option value="General Comment">General Comment</option>
          <option value="Other">Other</option>
        </Select>

        <Label htmlFor="priority">Priority</Label>
        <Select
          id="priority"
          name="priority"
          value={feedbackData.priority}
          onChange={handleChange}
          required
        >
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </Select>
        
        <Label htmlFor="attachments">Attachments</Label>
        <Input
          id="attachments"
          name="attachments"
          type="file"
          multiple
          onChange={handleFileChange}
        />

        <Button type="submit">Submit Feedback</Button>
      </FeedbackForm>
    </FeedbackContainer>
  );
};

export default FeedbackPage;
