import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAction } from '../store/actions/authActions';
import Header from '../components/common/Header';
import Footer from '../components/home/Footer';

const PageContainer = styled.div`
  padding: 40px 20px;
  background-color: #f4f4f4;
  height: 100vh;
  overflow-y: auto;
`;

const Section = styled.section`
  margin-bottom: 40px;
  margin-top: 20px;
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color:#fff;
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
`;

const HeroSection = styled.section`
  background-color: #6a0dad; /* Purple background color */
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  padding: 20px 15px; /* Reduced padding for smaller height */
  text-align: center;
`;

const SubTitle = styled.h3`
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
`;

const PrivacyPolicy = () => {
    const dispatch = useDispatch();
  
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
    const handleLogout = () => {
      dispatch(logoutUserAction());
    };
  return (
    <>
      <Header isAuthenticated={isAuthenticated} onLogout={handleLogout}/>
      <PageContainer>
        <HeroSection>
        <Title>Privacy Policy for Track4U</Title>
        </HeroSection>
      
        <Section>
          
          <Paragraph>
            At Track4U, we value your privacy and are committed to protecting your personal data. This privacy
            policy explains how we collect, use, and share your information when you use our website and services.
          </Paragraph>

          <SubTitle>Information We Collect</SubTitle>
          <Paragraph>
            We collect both personal and non-personal information to provide and improve our services. The types of
            information we collect include:
            <ul>
              <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and any other details you provide during registration or use of our services.</li>
              <li><strong>Usage Data:</strong> This includes information about how you use our website, such as IP address, browser type, pages visited, and other usage statistics.</li>
              <li><strong>Cookies and Tracking Data:</strong> We use cookies to enhance user experience. For more details, see our Cookie Policy.</li>
            </ul>
          </Paragraph>

          <SubTitle>How We Use Your Information</SubTitle>
          <Paragraph>
            We use the information we collect for the following purposes:
            <ul>
              <li>To provide, maintain, and improve our services.</li>
              <li>To communicate with you about updates, promotions, or customer service inquiries.</li>
              <li>To analyze usage trends and optimize website functionality.</li>
              <li>To comply with legal obligations or resolve disputes.</li>
            </ul>
          </Paragraph>

          <SubTitle>Data Security</SubTitle>
          <Paragraph>
            We implement reasonable security measures to protect your data from unauthorized access, alteration, or
            destruction. However, no data transmission method is completely secure, and we cannot guarantee the
            absolute security of your information.
          </Paragraph>

          <SubTitle>Your Rights</SubTitle>
          <Paragraph>
            You have the right to:
            <ul>
              <li>Access the personal information we hold about you.</li>
              <li>Request corrections to any inaccurate or incomplete information.</li>
              <li>Request the deletion of your personal data, subject to legal obligations.</li>
              <li>Object to the processing of your data for certain purposes.</li>
            </ul>
            To exercise these rights, please contact us at <a href="mailto:info@track4u.net">info@track4u.net</a>.
          </Paragraph>

          <SubTitle>Cookies and Tracking</SubTitle>
          <Paragraph>
            We use cookies and similar tracking technologies to enhance your browsing experience. For more information,
            please see our <a href="/cookie-policy">Cookie Policy</a>.
          </Paragraph>

          <SubTitle>Third-Party Services</SubTitle>
          <Paragraph>
            We may use third-party services (such as analytics providers and payment processors) that may collect
            information about you. These third parties have their own privacy policies, which we encourage you to review.
          </Paragraph>

          <SubTitle>Changes to This Privacy Policy</SubTitle>
          <Paragraph>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date
            of the last update will be shown at the top. We recommend reviewing this page periodically for any changes.
          </Paragraph>

          <SubTitle>Contact Information</SubTitle>
          <Paragraph>
            If you have any questions or concerns about this Privacy Policy, please contact us at: <br />
            Email: <a href="mailto:info@track4u.net">info@track4u.net</a>
          </Paragraph>
        </Section>
        <Footer/>
      </PageContainer>
      
    </>
  );
};

export default PrivacyPolicy;
