// CourierSelectionModal.js
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCouriersAction } from '../../store/actions/packageActions';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative; /* Ensure relative positioning for absolute child */
`;

const FixedTopSection = styled.div`
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure topmost layer for fixed elements */
  background-color: #fff;
  padding-bottom: 10px; /* Add padding to ensure space below fixed elements */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #FFA500;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  height: 40px;
  border: 1px solid #cccccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: calc(100% - 20px); /* Adjust width to account for padding */
`;

const CourierList = styled.div`
  margin-top: 10px;
`;

const CourierButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const CourierButtonText = styled.span`
  font-size: 16px;
  margin-left: 10px;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
`;

const CourierSelectionModal = ({ visible, onSelectCourier, onClose }) => {
  const dispatch = useDispatch();
  const { couriers, loading, error, hasMore } = useSelector((state) => state.package);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (visible) {
      dispatch(getAllCouriersAction(page)); // Fetch initial couriers when modal is opened
    }
  }, [dispatch, page, visible]);

  const handleLoadMore = useCallback(() => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1); // Update page for next fetch
    }
  }, [hasMore, loading]);

  const filteredCouriers = couriers
    ?.sort((a, b) => a.courier_name.localeCompare(b.courier_name)) // Sort alphabetically
    .filter(
      (courier) =>
        courier.courier_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        courier.courier_type?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        courier.country_code?.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  if (!visible) {
    return null;
  }

  return (
    <ModalOverlay>
      <ModalContent>
        <FixedTopSection>
          <CloseButton onClick={onClose}>Close</CloseButton>
          <Title>Select Courier</Title>
          <SearchInput
            placeholder="Search couriers..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </FixedTopSection>
        <CourierList>
          {loading && page === 1 ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            filteredCouriers.map((courier) => (
              <CourierButton key={courier._id} onClick={() => onSelectCourier(courier)}>
                <Logo src={courier.courier_logo.startsWith('//') ? `https:${courier.courier_logo}` : courier.courier_logo} alt={`${courier.courier_name} logo`} />
                <CourierButtonText>{courier.courier_name}</CourierButtonText>
              </CourierButton>
            ))
          )}
          {hasMore && (
            <div>
              <button onClick={handleLoadMore}>Load more</button>
            </div>
          )}
        </CourierList>
      </ModalContent>
    </ModalOverlay>
  );
};

export default CourierSelectionModal;
