import axios from 'axios';

//const BASE_URL = 'https://track-mk6l.onrender.com';
const BASE_URL = 'https://track-ten-blue.vercel.app';

// Function to create a ticket
export const createTicket = async (ticketData) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/tickets/create`, ticketData);
    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
};

// Function to get all tickets
export const getAllTickets = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/tickets/all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tickets:", error);
    throw error;
  }
};

// Function to get a specific ticket by ID
export const getTicketById = async (ticketId) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/tickets/ticket/${ticketId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching ticket:", error);
    throw error;
  }
};

// Function to update the ticket status (e.g. Open, In Progress, Closed)
export const updateTicketStatus = async (ticketId, status) => {
  try {
    const response = await axios.patch(`${BASE_URL}/api/tickets/ticket/status/${ticketId}`, { status });
    return response.data;
  } catch (error) {
    console.error("Error updating ticket status:", error);
    throw error;
  }
};

// Function to update the ticket priority (Low, Medium, High)
export const updateTicketPriority = async (ticketId, priority) => {
  try {
    const response = await axios.patch(`${BASE_URL}/api/tickets/ticket/priority/${ticketId}`, { priority });
    return response.data;
  } catch (error) {
    console.error("Error updating ticket priority:", error);
    throw error;
  }
};
