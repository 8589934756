import React, { useState } from 'react';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';

// Styled Components
const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: all 0.3s ease-out;
`;

const Container = styled.div`
  @media (max-width: 767px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease-out;
`;

const NavbarBrand = styled.a`
  padding: 0;
  color: #ffffff; /* White */
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
  @media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    padding: 5px 0px;
  }
`;

const NavbarToggler = styled.button`
  padding: 0;
  .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #ffffff; /* var(--white) */
    display: block;
    margin: 5px 0;
    position: relative;
    transition: all 0.3s ease-out;
  }
  &.active .toggler-icon:nth-of-type(1) {
    transform: rotate(45deg);
    top: 7px;
  }
  &.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
  }
  &.active .toggler-icon:nth-of-type(3) {
    transform: rotate(135deg);
    top: -7px;
  }
`;

const NavbarCollapse = styled.div`
  @media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    position: absolute;
    top: 100%;
    right: 0;
    max-width: 300px;
    width: 100%;
    background-color: #ffffff; /* var(--white) */
    z-index: 9;
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 0px;
    display: none;
    border-radius: 5px;
    &.show {
      display: block;
    }
  }
`;

const NavbarNav = styled.ul`
  display: flex;
  list-style: none;
  padding: 20px;
`;

const NavItem = styled.li`
  position: relative;
  padding: 0px 20px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0px 12px;
  }
  @media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    padding: 0px;
  }
`;

const NavLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #ffffff; /* var(--white) */
  transition: all 0.3s ease-out;
  padding: 25px 8px;
  position: relative;
  @media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    display: block;
    padding: 8px 0;
    color: #212b36; /* var(--heading-color) */
  }
  &:hover, &.active {
    color: #ffffff; /* var(--white) */
    opacity: 0.5;
    @media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      color: #3056d3; /* var(--primary-color) */
      opacity: 1;
    }
  }
`;

const Submenu = styled.ul`
  position: absolute;
  display: flex;
  list-style: none;
  width: 250px;
  background: #ffffff; /* #fff */
  top: 110%;
  padding: 20px 0;
  box-shadow: 0 15px 44px rgba(140, 140, 140, 0.18);
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  @media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    position: static;
    box-shadow: none;
    width: 100%;
    padding: 0px;
    opacity: 1;
    visibility: visible;
    display: none;
    &.show {
      display: flex;
    }
  }
`;

const SubmenuItem = styled.li`
  .ud-submenu-link {
    padding: 7px 20px;
    color: #212b36; /* var(--heading-color) */
    font-size: 14px;
    &:hover {
      color: #3056d3; /* var(--primary-color) */
    }
  }
`;

const NavbarBtn = styled.div`
  @media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
  }
  .ud-main-btn {
    padding: 8px 16px;
    background: transparent;
    &.ud-login-btn:hover {
      opacity: 0.5;
    }
  }
  .ud-white-btn {
    background: rgba(255, 255, 255, 0.2);
    &:hover {
      background: #ffffff; /* white */
      color: #212b36; /* var(--heading-color) */
    }
  }
`;

const MainButton = styled.a`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  padding: 15px 25px;
  border: 1px solid transparent;
  color: #ffffff; /* var(--white) */
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-out;
  background: #3056d3; /* var(--primary-color) */
  &:hover {
    color: #ffffff; /* var(--white) */
    background: #212b36; /* var(--heading-color) */
  }
`;

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <HeaderContainer className="ud-header">
      <Container className="container">
        <div className="row">
          <div className="col-lg-12">
            <Navbar className="navbar navbar-expand-lg">
              <NavbarBrand href="#">Track48</NavbarBrand>
              <NavbarToggler className="navbar-toggler" type="button" onClick={handleToggle}>
                <FaBars className="toggler-icon" />
              </NavbarToggler>

              <NavbarCollapse className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarNav">
                <NavbarNav className="navbar-nav mx-auto">
                  <NavItem className="nav-item">
                    <NavLink className="ud-menu-scroll" href="#home">Home</NavLink>
                  </NavItem>
                  <NavItem className="nav-item">
                    <NavLink className="ud-menu-scroll" href="#about">About</NavLink>
                  </NavItem>
                  <NavItem className="nav-item">
                    <NavLink className="ud-menu-scroll" href="#pricing">Pricing</NavLink>
                  </NavItem>
                  <NavItem className="nav-item">
                    <NavLink className="ud-menu-scroll" href="#team">Team</NavLink>
                  </NavItem>
                  <NavItem className="nav-item">
                    <NavLink className="ud-menu-scroll" href="#contact">Contact</NavLink>
                  </NavItem>
                  <NavItem className="nav-item nav-item-has-children">
                    <NavLink href="javascript:void(0)">Pages</NavLink>
                    <Submenu className="ud-submenu">
                      <SubmenuItem className="ud-submenu-item">
                        <a href="about.html" className="ud-submenu-link">About Page</a>
                      </SubmenuItem>
                      <SubmenuItem className="ud-submenu-item">
                        <a href="pricing.html" className="ud-submenu-link">Pricing Page</a>
                      </SubmenuItem>
                      <SubmenuItem className="ud-submenu-item">
                        <a href="contact.html" className="ud-submenu-link">Contact Page</a>
                      </SubmenuItem>
                      <SubmenuItem className="ud-submenu-item">
                        <a href="blog.html" className="ud-submenu-link">Blog Grid Page</a>
                      </SubmenuItem>
                      <SubmenuItem className="ud-submenu-item">
                        <a href="blog-details.html" className="ud-submenu-link">Blog Details Page</a>
                      </SubmenuItem>
                      <SubmenuItem className="ud-submenu-item">
                        <a href="signin.html" className="ud-submenu-link">Sign In Page</a>
                      </SubmenuItem>
                      <SubmenuItem className="ud-submenu-item">
                        <a href="signup.html" className="ud-submenu-link">Sign Up Page</a>
                      </SubmenuItem>
                      <SubmenuItem className="ud-submenu-item">
                        <a href="404.html" className="ud-submenu-link">404 Page</a>
                      </SubmenuItem>
                    </Submenu>
                  </NavItem>
                </NavbarNav>
              </NavbarCollapse>

              <NavbarBtn className="navbar-btn d-none d-sm-inline-block">
                <MainButton href="signin.html" className="ud-main-btn ud-login-btn">
                  Sign In
                </MainButton>
                <MainButton href="signup.html" className="ud-main-btn ud-white-btn">
                  Sign Up
                </MainButton>
              </NavbarBtn>
            </Navbar>
          </div>
        </div>
      </Container>
    </HeaderContainer>
  );
};

export default Header;
