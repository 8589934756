import React, { useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createTicket } from '../../store/actions/ticketActions';

// Styled Components
const FormContainer = styled.div`
  background-color: #fff;
  width: 100%;
  margin-top: 50px;
  margin: 0 auto;
  padding: 20px;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 85%;
     margin-top: 35px;
  }
`;

const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items in the center horizontally */
  justify-content: center; /* Align items in the center vertically */
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  width: 100%; /* Ensure form controls take up the form's width */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align labels to the left */
  padding: 0 10%;
`;


const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
`;

const Textarea = styled.textarea`
  width: 90%;
  height: 150px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  resize: vertical;
`;

const Select = styled.select`
  width: 95%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: #4834d4;
  color: white;
  border: none;
  width:80%;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const TicketForm = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('Low'); // Default priority
  const [category, setCategory] = useState('Billing'); // Default category
  const userId = useSelector((state) => state.auth.user);
  const { loading, error } = useSelector((state) => state.tickets);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the ticket data
    const ticketData = {
      userId,
      title,
      description,
      priority,
      category,
      status: 'Open', // Default status on creation
    };

    // Dispatch the create ticket action
    dispatch(createTicket(ticketData));

    // Optionally reset the form
    setTitle('');
    setDescription('');
    setPriority('Low');
    setCategory('Billing');
  };

  return (
    <FormContainer>
      <FormTitle>Submit a Ticket</FormTitle>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="title">Title</Label>
          <Input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="description">Description</Label>
          <Textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="priority">Priority</Label>
          <Select
            id="priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
          >
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="category">Category</Label>
          <Select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Billing">Billing</option>
            <option value="Technical Support">Technical Support</option>
            <option value="General Inquiry">General Inquiry</option>
          </Select>
        </FormGroup>

        <SubmitButton type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit Ticket'}
        </SubmitButton>

        {error && <ErrorMessage>Error: {error}</ErrorMessage>}
      </Form>
    </FormContainer>
  );
};

export default TicketForm;
