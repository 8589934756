import React from 'react';
import styled from 'styled-components';

const ContactSection = () => {
  return (
    <ContactWrapper id="contact">
      <ContactContainer>
        <ContactContent>
          <ContactTitle>
            <span>CONTACT US</span>
            <h2>
              Let’s talk about <br />
              Love to hear from you!
            </h2>
          </ContactTitle>
          <ContactInfoWrapper>
            <SingleInfo>
              <InfoIcon className="lni lni-map-marker"></InfoIcon>
              <InfoMeta>
                <h5>Our Location</h5>
                <p>401 Broadway, 24th Floor, Orchard Cloud View, London</p>
              </InfoMeta>
            </SingleInfo>
            <SingleInfo>
              <InfoIcon className="lni lni-envelope"></InfoIcon>
              <InfoMeta>
                <h5>How Can We Help?</h5>
                <p>info@yourdomain.com</p>
                <p>contact@yourdomain.com</p>
              </InfoMeta>
            </SingleInfo>
          </ContactInfoWrapper>
        </ContactContent>
        <ContactFormWrapper>
          <ContactFormTitle>Send us a Message</ContactFormTitle>
          <ContactForm>
            <FormGroup>
              <label htmlFor="fullName">Full Name*</label>
              <input type="text" name="fullName" placeholder="Adam Gelius" />
            </FormGroup>
            <FormGroup>
              <label htmlFor="email">Email*</label>
              <input type="email" name="email" placeholder="example@yourmail.com" />
            </FormGroup>
            <FormGroup>
              <label htmlFor="phone">Phone*</label>
              <input type="text" name="phone" placeholder="+885 1254 5211 552" />
            </FormGroup>
            <FormGroup>
              <label htmlFor="message">Message*</label>
              <textarea name="message" rows="1" placeholder="Type your message here"></textarea>
            </FormGroup>
            <FormGroup>
              <SubmitButton type="submit">Send Message</SubmitButton>
            </FormGroup>
          </ContactForm>
        </ContactFormWrapper>
      </ContactContainer>
    </ContactWrapper>
  );
};

export default ContactSection;

const ContactWrapper = styled.section`
  padding: 120px 0;
`;

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
`;

const ContactContent = styled.div`
  flex: 0 0 50%;
`;

const ContactTitle = styled.div`
  margin-bottom: 30px;

  h2 {
    font-size: 36px;
    line-height: 1.2;
    margin-top: 15px;
  }
`;

const ContactInfoWrapper = styled.div`
  margin-top: 30px;
`;

const SingleInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    line-height: 1.6;
  }
`;

const InfoIcon = styled.i`
  font-size: 24px;
  margin-right: 15px;
`;

const InfoMeta = styled.div``;

const ContactFormWrapper = styled.div`
  flex: 0 0 45%;
`;

const ContactFormTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ContactForm = styled.form``;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  textarea {
    resize: vertical;
    min-height: 120px;
  }
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;
