import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {  FaTimes } from 'react-icons/fa';
import { changePasswordAction } from '../../store/actions/authActions'; // Import the action

// Styled Components
const Container = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 90%;
     
  }
`;

const Form = styled.form`
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 95%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #3c096c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ConfirmButton = styled.button`
  background-color: #6200b3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
`;

const PasswordSettings = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state=> state.auth.user)
  const [formData, setFormData] = useState({
    
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [modalVisible, setModalVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate passwords
    if (formData.newPassword !== formData.confirmPassword) {
      alert("New password and confirm password don't match.");
      return;
    }

    // Show the confirmation modal
    setModalVisible(true);
  };

  const handleConfirmChange = () => {
    const passwordData = {
      userId,
      oldPassword: formData.currentPassword,
      newPassword: formData.newPassword,
    };

    // Dispatch the change password action
    dispatch(changePasswordAction(passwordData));

    // Clear form fields after submission
    setFormData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });

    // Hide the confirmation modal
    setModalVisible(false);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Current Password</Label>
          <Input
            type="password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>New Password</Label>
          <Input
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Confirm New Password</Label>
          <Input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </FormGroup>
        <Button type="submit">Update Password</Button>
      </Form>

      {modalVisible && (
        <ModalContainer>
          <ModalContent>
            <CloseButton onClick={() => setModalVisible(false)}>
              <FaTimes size={24} color="#666" />
            </CloseButton>
            <ModalTitle>Confirm Password Change</ModalTitle>
            <p>Are you sure you want to change your password?</p>
            <ConfirmButton onClick={handleConfirmChange}>Confirm</ConfirmButton>
          </ModalContent>
        </ModalContainer>
      )}
    </Container>
  );
};

export default PasswordSettings;
