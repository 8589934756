import React from 'react';
import styled from 'styled-components';

const FeaturesSection = styled.section`
  padding-top: 120px;
  padding-bottom: 90px;

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }
`;

const SectionTitle = styled.div`
  max-width: 620px;
  margin-bottom: 70px;
  text-align: center;

  span {
    font-weight: 600;
    font-size: 18px;
    color: var(--primary-color);
    margin-bottom: 10px;
    display: block;
  }

  h2 {
    font-weight: 700;
    font-size: 42px;
    line-height: 55px;
    text-transform: capitalize;
    color: var(--heading-color);
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    line-height: 30px;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

const FeatureCard = styled.div`
  margin-bottom: 40px;
  text-align: center;

  &:hover .feature-icon::before {
    transform: rotate(45deg);
  }
`;

const FeatureIcon = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  background: var(--primary-color);
  font-size: 32px;
  color: var(--white);
  position: relative;
  z-index: 1;
  margin-bottom: 40px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 14px;
    background: var(--primary-color);
    opacity: 0.2;
    z-index: -1;
    transform: rotate(23deg);
    transition: all 0.3s ease-out 0s;
  }
`;

const FeatureTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 12px;

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const FeatureDesc = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
`;

const FeatureLink = styled.a`
  font-weight: 500;
  color: var(--body-color);

  &:hover {
    color: var(--primary-color);
  }
`;

const Features = () => {
  return (
    <FeaturesSection id="features">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle>
              <span>Features</span>
              <h2>Main Features of Play</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </SectionTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-sm-6">
            <FeatureCard>
              <FeatureIcon>
                <i className="lni lni-gift"></i>
              </FeatureIcon>
              <div>
                <FeatureTitle>Free and Open-Source</FeatureTitle>
                <FeatureDesc>
                  Lorem Ipsum is simply dummy text of the printing and industry.
                </FeatureDesc>
                <FeatureLink href="javascript:void(0)">Learn More</FeatureLink>
              </div>
            </FeatureCard>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6">
            <FeatureCard>
              <FeatureIcon>
                <i className="lni lni-move"></i>
              </FeatureIcon>
              <div>
                <FeatureTitle>Multipurpose Template</FeatureTitle>
                <FeatureDesc>
                  Lorem Ipsum is simply dummy text of the printing and industry.
                </FeatureDesc>
                <FeatureLink href="javascript:void(0)">Learn More</FeatureLink>
              </div>
            </FeatureCard>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6">
            <FeatureCard>
              <FeatureIcon>
                <i className="lni lni-layout"></i>
              </FeatureIcon>
              <div>
                <FeatureTitle>High-quality Design</FeatureTitle>
                <FeatureDesc>
                  Lorem Ipsum is simply dummy text of the printing and industry.
                </FeatureDesc>
                <FeatureLink href="javascript:void(0)">Learn More</FeatureLink>
              </div>
            </FeatureCard>
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-6">
            <FeatureCard>
              <FeatureIcon>
                <i className="lni lni-layers"></i>
              </FeatureIcon>
              <div>
                <FeatureTitle>All Essential Elements</FeatureTitle>
                <FeatureDesc>
                  Lorem Ipsum is simply dummy text of the printing and industry.
                </FeatureDesc>
                <FeatureLink href="javascript:void(0)">Learn More</FeatureLink>
              </div>
            </FeatureCard>
          </div>
        </div>
      </div>
    </FeaturesSection>
  );
};

export default Features;
