import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAction } from '../store/actions/authActions';
import Header from '../components/common/Header';
import Footer from '../components/home/Footer';

const Container = styled.div`
  background-color: #f9f9f9;
  height:100vh;
  overflow-y:auto;
`;

const HeroSection = styled.section`
  background-size: cover;
  background-color: #6a0dad;
  padding: 25px 15px;
  text-align: center;

  @media (max-width: 768px) {
    width: 95%;
    
    
    border-radius: 8px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 10px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

const HeroDescription = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const FaqContainer = styled.div`
  padding: 20px;
`;

const HeaderTitle = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const FaqItem = styled.div`
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 15px;
  cursor: pointer;
`;

const Question = styled.h2`
  margin: 0;
  font-size: 18px;
`;

const Answer = styled.p`
  margin: 10px 0 0;
  font-size: 16px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const FaqPage = () => {
  const dispatch = useDispatch();
  const [openItem, setOpenItem] = useState(null);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleLogout = () => {
    dispatch(logoutUserAction());
  };

  const faqData = [
    {
      question: 'How do I track my package?',
      answer: 'To track your package, simply enter your tracking number in the search bar, and you will see real-time updates of your package’s status.',
    },
    {
      question: 'What couriers are supported?',
      answer: 'We support a wide range of couriers including FedEx, UPS, DHL, and more. You can check our list of supported couriers in the platform settings.',
    },
    {
      question: 'How often is the tracking information updated?',
      answer: 'Tracking information is updated in real-time, usually every few hours. However, updates may vary depending on the courier.',
    },
    {
      question: 'Can I track multiple packages at once?',
      answer: 'Yes, you can track multiple packages simultaneously by entering multiple tracking numbers or uploading a list of tracking numbers.',
    },
    {
      question: 'What happens if my package is delayed?',
      answer: 'If your package is delayed, we will notify you and provide updates on the expected delivery time. You can also contact the courier for more details.',
    },
    {
      question: 'How do I get notifications about my package?',
      answer: 'You can set up notifications in your account settings. We offer email and push notifications for status updates on your packages.',
    },
    {
      question: 'Can I see the delivery history for my package?',
      answer: 'Yes, you can view your entire package delivery history in your account dashboard. This includes past deliveries, current shipments, and tracking information.',
    },
    // Add more FAQ items here
  ];

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <Container>
      <Header isAuthenticated={isAuthenticated} onLogout={handleLogout}/>
      <HeroSection>
        <HeroTitle>Frequently Asked Questions</HeroTitle>
        <HeroDescription>Find answers to the most common questions below</HeroDescription>
      </HeroSection>
      <FaqContainer>
        {faqData.map((item, index) => (
          <FaqItem key={index} onClick={() => toggleItem(index)}>
            <Question>{item.question}</Question>
            <Answer isOpen={openItem === index}>{item.answer}</Answer>
          </FaqItem>
        ))}
      </FaqContainer>
      <Footer/>
    </Container>
  );
};

export default FaqPage;
