import React from 'react';
import styled from 'styled-components';
import { FaMapMarkerAlt, FaBell, FaTachometerAlt, FaShippingFast, FaSearch, FaLock } from 'react-icons/fa';

const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 20px;
  background-color:#f5f5f5;
`;

const FeatureCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  margin: 10px;
  text-align: center;
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 20px);
  }

  @media (max-width: 480px) {
    flex: 1 1 calc(100% - 20px);
  }
`;

const IconWrapper = styled.div`
  font-size: 40px;
  color: #6200b3;
  margin-bottom: 15px;
`;

const FeatureTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  font-size: 14px;
  color: #666;
`;

const FeatureHeading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const SupportingText = styled.p`
  font-size: 16px;
  color: #757575;
  text-align: center;
  max-width: 600px;
  margin: 0 auto 40px;
`;

const features = [
  {
    icon: <FaMapMarkerAlt />,
    title: 'Real-time Package Tracking',
    description: 'Track your packages in real-time with our easy-to-use interface.',
  },
  {
    icon: <FaBell />,
    title: 'Automated Notifications',
    description: 'Get notified automatically via email or SMS about your package status.',
  },
  {
    icon: <FaTachometerAlt />,
    title: 'Comprehensive Dashboard',
    description: 'View all your package information in one place with our comprehensive dashboard.',
  },
  {
    icon: <FaShippingFast />,
    title: 'Support for Multiple Carriers',
    description: 'Our service supports multiple carriers to provide you with the best tracking experience.',
  },
  {
    icon: <FaSearch />,
    title: 'Advanced Search & Filtering',
    description: 'Easily find specific packages with our advanced search and filtering options.',
  },
  {
    icon: <FaLock />,
    title: 'Secure and Reliable',
    description: 'We ensure the security and reliability of your data at all times.',
  },
];

const Features = () => {
  return (
    <>
      <FeatureHeading>Key Features</FeatureHeading>
      <SupportingText>
        Explore the features that make package tracking easy and secure with us.
      </SupportingText>
      <FeaturesContainer>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <IconWrapper>{feature.icon}</IconWrapper>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureCard>
        ))}
      </FeaturesContainer>
    </>
  );
};

export default Features;
