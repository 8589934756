import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterSection className="ud-footer">
      <Shape className="shape shape-1">
        <img src="assets/images/footer/shape-1.svg" alt="shape" />
      </Shape>
      <Shape className="shape shape-2">
        <img src="assets/images/footer/shape-2.svg" alt="shape" />
      </Shape>
      <Shape className="shape shape-3">
        <img src="assets/images/footer/shape-3.svg" alt="shape" />
      </Shape>
      <FooterWidgets>
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <Widget>
                <FooterLogo href="index.html" className="ud-footer-logo">
                  <img src="assets/images/logo/logo.svg" alt="logo" />
                </FooterLogo>
                <WidgetDesc className="ud-widget-desc">
                  We create digital experiences for brands and companies by using technology.
                </WidgetDesc>
                <WidgetSocials className="ud-widget-socials">
                  <SocialLink href="https://twitter.com/MusharofChy">
                    <i className="lni lni-facebook-filled"></i>
                  </SocialLink>
                  <SocialLink href="https://twitter.com/MusharofChy">
                    <i className="lni lni-twitter-filled"></i>
                  </SocialLink>
                  <SocialLink href="https://twitter.com/MusharofChy">
                    <i className="lni lni-instagram-filled"></i>
                  </SocialLink>
                  <SocialLink href="https://twitter.com/MusharofChy">
                    <i className="lni lni-linkedin-original"></i>
                  </SocialLink>
                </WidgetSocials>
              </Widget>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
              <Widget>
                <WidgetTitle className="ud-widget-title">About Us</WidgetTitle>
                <WidgetLinks className="ud-widget-links">
                  <li><WidgetLink href="javascript:void(0)">Home</WidgetLink></li>
                  <li><WidgetLink href="javascript:void(0)">Features</WidgetLink></li>
                  <li><WidgetLink href="javascript:void(0)">About</WidgetLink></li>
                  <li><WidgetLink href="javascript:void(0)">Testimonial</WidgetLink></li>
                </WidgetLinks>
              </Widget>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
              <Widget>
                <WidgetTitle className="ud-widget-title">Features</WidgetTitle>
                <WidgetLinks className="ud-widget-links">
                  <li><WidgetLink href="javascript:void(0)">How it works</WidgetLink></li>
                  <li><WidgetLink href="javascript:void(0)">Privacy policy</WidgetLink></li>
                  <li><WidgetLink href="javascript:void(0)">Terms of service</WidgetLink></li>
                  <li><WidgetLink href="javascript:void(0)">Refund policy</WidgetLink></li>
                </WidgetLinks>
              </Widget>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
              <Widget>
                <WidgetTitle className="ud-widget-title">Our Products</WidgetTitle>
                <WidgetLinks className="ud-widget-links">
                  <li><WidgetLink href="https://lineicons.com/" rel="nofollow noopner" target="_blank">Lineicons</WidgetLink></li>
                  <li><WidgetLink href="https://ecommercehtml.com/" rel="nofollow noopner" target="_blank">Ecommerce HTML</WidgetLink></li>
                  <li><WidgetLink href="https://ayroui.com/" rel="nofollow noopner" target="_blank">Ayro UI</WidgetLink></li>
                  <li><WidgetLink href="https://graygrids.com/" rel="nofollow noopner" target="_blank">Plain Admin</WidgetLink></li>
                </WidgetLinks>
              </Widget>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-8 col-sm-10">
              <Widget>
                <WidgetTitle className="ud-widget-title">Partners</WidgetTitle>
                <WidgetBrands className="ud-widget-brands">
                  <li><WidgetBrandLink href="https://ayroui.com/" rel="nofollow noopner" target="_blank"><img src="assets/images/footer/brands/ayroui.svg" alt="ayroui" /></WidgetBrandLink></li>
                  <li><WidgetBrandLink href="https://ecommercehtml.com/" rel="nofollow noopner" target="_blank"><img src="assets/images/footer/brands/ecommerce-html.svg" alt="ecommerce-html" /></WidgetBrandLink></li>
                  <li><WidgetBrandLink href="https://graygrids.com/" rel="nofollow noopner" target="_blank"><img src="assets/images/footer/brands/graygrids.svg" alt="graygrids" /></WidgetBrandLink></li>
                  <li><WidgetBrandLink href="https://lineicons.com/" rel="nofollow noopner" target="_blank"><img src="assets/images/footer/brands/lineicons.svg" alt="lineicons" /></WidgetBrandLink></li>
                  <li><WidgetBrandLink href="https://uideck.com/" rel="nofollow noopner" target="_blank"><img src="assets/images/footer/brands/uideck.svg" alt="uideck" /></WidgetBrandLink></li>
                  <li><WidgetBrandLink href="https://tailwindtemplates.co/" rel="nofollow noopner" target="_blank"><img src="assets/images/footer/brands/tailwindtemplates.svg" alt="tailwindtemplates" /></WidgetBrandLink></li>
                </WidgetBrands>
              </Widget>
            </div>
          </div>
        </div>
      </FooterWidgets>
      <FooterBottom className="ud-footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <FooterLeft className="ud-footer-bottom-left">
                <FooterLink href="javascript:void(0)">Privacy policy</FooterLink>
                <FooterLink href="javascript:void(0)">Support policy</FooterLink>
                <FooterLink href="javascript:void(0)">Terms of service</FooterLink>
              </FooterLeft>
            </div>
            <div className="col-md-4">
              <FooterRight className="ud-footer-bottom-right">
                Designed and Developed by <FooterLink href="https://uideck.com" rel="nofollow">UIdeck</FooterLink>
              </FooterRight>
            </div>
          </div>
        </div>
      </FooterBottom>
    </FooterSection>
  );
};

export default Footer;

const FooterSection = styled.footer`
  background: var(--heading-color);
  padding-top: 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
`;

const Shape = styled.div`
  position: absolute;
  z-index: -1;
`;

const FooterWidgets = styled.div`
  padding-bottom: 50px;
`;

const Widget = styled.div`
  margin-bottom: 50px;
`;

const FooterLogo = styled.a`
  display: block;
  max-width: 160px;
  margin-bottom: 10px;
`;

const WidgetDesc = styled.p`
  font-size: 15px;
  line-height: 24px;
  color: #f3f4fe;
  margin-bottom: 30px;
  max-width: 260px;
`;

const WidgetSocials = styled.ul`
  display: flex;
  align-items: center;
`;

const SocialLink = styled.a`
  font-size: 16px;
  color: #dddddd;
  margin-right: 20px;

  &:hover {
    color: var(--primary-color);
  }
`;

const WidgetTitle = styled.h5`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 35px;
  color: var(--white);
`;

const WidgetLinks = styled.ul`
  li {
    margin-bottom: 12px;
  }
`;

const WidgetLink = styled.a`
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  color: #f3f4fe;

  &:hover {
    color: var(--primary-color);
    padding-left: 10px;
  }
`;

const WidgetBrands = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const WidgetBrandLink = styled.a`
  display: block;
  max-width: 120px;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const FooterBottom = styled.div`
  padding: 35px 0;
  border-top: 1px solid rgba(136, 144, 164, 0.43);
`;

const FooterLeft = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const FooterLink = styled.a`
  font-weight: normal;
  font-size: 15px;
  color: #f3f4fe;
  margin-right: 30px;
  display: inline-block;

  @media (max-width: 767px) {
    margin: 0 8px 8px;
  }

  &:hover {
    color: var(--primary-color);
  }
`;

const FooterRight = styled.p`
  text-align: right;
  font-weight: normal;
  font-size: 15px;
  color: #f3f4fe;

  a {
    color: var(--primary-color);
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    text-align: center;
    margin-top: 10px;
  }
`;
