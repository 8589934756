import React from 'react';
import styled from 'styled-components';

// Styled Components
const FAQSection = styled.section`
  padding-top: 120px;
  padding-bottom: 90px;
  background: #f3f4fe;
  position: relative;
  z-index: 1;

  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 50px;
  }
`;

const ShapeImage = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;

  img {
    display: block;
  }
`;

const FAQTitle = styled.div`
  text-align: center;
  margin-bottom: 60px;

  span {
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: #2748b4;
  }

  h2 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    color: #6b7c93;
  }
`;

const FAQItem = styled.div`
  background: var(--white);
  border: 1px solid #f3f4fe;
  box-shadow: 0px 20px 95px rgba(201, 203, 204, 0.3);
  border-radius: 10px;
  margin-bottom: 30px;
`;

const FAQButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 18px;
  color: var(--heading-color);
  opacity: 0.88;
  padding: 30px;

  span.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: rgba(48, 86, 211, 0.06);
    border-radius: 10px;
    color: var(--primary-color);
    margin-right: 24px;
  }

  .lni-chevron-down {
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

const FAQBody = styled.div`
  padding: 0px 30px 40px 95px;

  @media only screen and (min-width: 992px) and (max-width: 1199px),
    (max-width: 767px) {
    padding: 0px 16px 20px 82px;
  }
`;

// React Component
const FAQSectionComponent = () => {
  return (
    <FAQSection id="faq">
      <ShapeImage>
        <img src="assets/images/faq/shape.svg" alt="shape" />
      </ShapeImage>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <FAQTitle>
              <span>FAQ</span>
              <h2>Any Questions? Answered</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </FAQTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <FAQItem>
              <div className="accordion">
                <FAQButton
                  className="ud-faq-btn collapsed"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                >
                  <span className="icon">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>How to use UIdeck?</span>
                </FAQButton>
                <div id="collapseOne" className="accordion-collapse collapse">
                  <FAQBody className="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </FAQBody>
                </div>
              </div>
            </FAQItem>
            <FAQItem>
              <div className="accordion">
                <FAQButton
                  className="ud-faq-btn collapsed"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                >
                  <span className="icon">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>How to download icons from Lineicons?</span>
                </FAQButton>
                <div id="collapseTwo" className="accordion-collapse collapse">
                  <FAQBody className="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </FAQBody>
                </div>
              </div>
            </FAQItem>
            <FAQItem>
              <div className="accordion">
                <FAQButton
                  className="ud-faq-btn collapsed"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                >
                  <span className="icon">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>Is GrayGrids part of UIdeck?</span>
                </FAQButton>
                <div id="collapseThree" className="accordion-collapse collapse">
                  <FAQBody className="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </FAQBody>
                </div>
              </div>
            </FAQItem>
          </div>
          <div className="col-lg-6">
            <FAQItem>
              <div className="accordion">
                <FAQButton
                  className="ud-faq-btn collapsed"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                >
                  <span className="icon">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>Can I use this template for commercial project?</span>
                </FAQButton>
                <div id="collapseFour" className="accordion-collapse collapse">
                  <FAQBody className="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </FAQBody>
                </div>
              </div>
            </FAQItem>
            <FAQItem>
              <div className="accordion">
                <FAQButton
                  className="ud-faq-btn collapsed"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                >
                  <span className="icon">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>Do you have plan releasing Play Pro?</span>
                </FAQButton>
                <div id="collapseFive" className="accordion-collapse collapse">
                  <FAQBody className="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </FAQBody>
                </div>
              </div>
            </FAQItem>
            <FAQItem>
              <div className="accordion">
                <FAQButton
                  className="ud-faq-btn collapsed"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                >
                  <span className="icon">
                    <i className="lni lni-chevron-down"></i>
                  </span>
                  <span>Where and how to host this template?</span>
                </FAQButton>
                <div id="collapseSix" className="accordion-collapse collapse">
                  <FAQBody className="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </FAQBody>
                </div>
              </div>
            </FAQItem>
          </div>
        </div>
      </div>
    </FAQSection>
  );
};

export default FAQSectionComponent;
