import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updatePaymentAction } from '../store/actions/paymentActions';
import { changeSubscriptionPlanAction, fetchUsedTracksAction, renewSubscriptionAction } from '../store/actions/subscriptionActions';
import CashAppCheckout from '../components/payments/CashAppCheckout';


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f9f9f9;
  height: 100vh;
  overflow-y:auto;
  
`;



const CashAppCheckoutPage = () => {
    const userId = useSelector((state) => state.auth.user);
    const paymentId = useSelector((state) => state.payments.paymentId);
    const subscriptionAction = useSelector((state) => state.subscription.subscriptionAction);
    const subscription = useSelector((state) => state.subscription.subscriptionDetails);
    const plan = useSelector((state) => state.plans.selectedPlan);
    const selectedPaymentMethod = useSelector((state) => state.paymentMethod.selectedPaymentMethod);

    
  
  return (
    <PageContainer>
      
      <CashAppCheckout
        selectedPaymentMethod={selectedPaymentMethod} 
         
      />
    </PageContainer>
  );
};

export default CashAppCheckoutPage;
