import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { AiFillDashboard } from "react-icons/ai";
import { BiPackage } from "react-icons/bi";
import { PiTruckBold } from "react-icons/pi";
import { MdOutlinePayments, MdOutlineSubscriptions } from "react-icons/md";
import { LuArchive, LuBadgeHelp } from "react-icons/lu";
import { FaBoxOpen, FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next"; // Importing i18n translation hook

// Styled components
const StyledSidebar = styled.aside`
  background-color: #fff;
  padding: 2.0rem 1.0rem;
  border-right: 1px solid #f3f4f6;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.3s ease-in-out;
  width: 220px;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(-100%)")};
    z-index: 1000;
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const StyledNavLink = styled(NavLink)`
  &:link,
  &:visited {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    color: #4b5563;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    transition: all 0.3s;
    text-decoration: none;
  }

  &:hover,
  &:active,
  &.active:link,
  &.active:visited {
    color: #1f2937;
    background-color: #f9fafb;
    border-radius: var(--border-radius-sm);
  }

  & svg {
    width: 2.4rem;
    height: 2.4rem;
    color: #9ca3af;
    transition: all 0.3s;
  }

  &:hover svg,
  &:active svg,
  &.active:link svg,
  &.active:visited svg {
    color: #4f46e5;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align logo and close button inline */
  gap: 1rem;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #333;
  font-size: 2rem;
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const LogoText = styled.span`
  color: #000;
`;

const Logo = ({ toggleSidebar }) => (
  <LogoContainer>
    <LogoText>Track4u</LogoText>
    <CloseButton onClick={toggleSidebar}>
      <FaTimes />
    </CloseButton>
  </LogoContainer>
);

const MenuButton = styled.button`
  background: none;
  border: none;
  color: #333;
  font-size: 2rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1100;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

// Sidebar component
const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const { t } = useTranslation(); // Translation hook

  return (
    <>
      {!isOpen && (
        <MenuButton onClick={toggleSidebar}>
          <FaBars />
        </MenuButton>
      )}
      <StyledSidebar isOpen={isOpen}>
        <Logo toggleSidebar={toggleSidebar} />
        <nav>
          <NavList>
            <li>
              <StyledNavLink to="/dashboard" onClick={closeSidebar}>
                <AiFillDashboard />
                <span>{t("Dashboard")}</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/packages/all" onClick={closeSidebar}>
                <BiPackage />
                <span>{t("Packages")}</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/couriers/all" onClick={closeSidebar}>
                <PiTruckBold />
                <span>{t("Couriers")}</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/subscription/plans" onClick={closeSidebar}>
                <MdOutlineSubscriptions />
                <span>{t("Plans")}</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/payments/all" onClick={closeSidebar}>
                <MdOutlinePayments />
                <span>{t("Payments")}</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/archives/all" onClick={closeSidebar}>
                <LuArchive />
                <span>{t("Archives")}</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/settings" onClick={closeSidebar}>
                <HiOutlineCog6Tooth />
                <span>{t("Settings")}</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/help/center" onClick={closeSidebar}>
                <LuBadgeHelp />
                <span>{t("Help")}</span>
              </StyledNavLink>
            </li>
          </NavList>
        </nav>
      </StyledSidebar>
    </>
  );
};

export default Sidebar;
