import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditorState, ContentState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css'; // Import Draft.js styles

import styled from 'styled-components';
import { getEmailTemplateByIdAction } from '../../store/actions/templateActions';

const TemplatePreviewContainer = styled.div`
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  padding: 20px;
`;

const EmailTemplateContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const HTMLContent = styled.div`
  font-size: 16px;
  line-height: 1.6;
`;

const PreviewTemplate = ({ templateId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let templateId = '65a9aa699e815172b4a30716';
    // Fetch the email template by ID when the component mounts
    dispatch(getEmailTemplateByIdAction(templateId));
  }, [dispatch, templateId]);

  // Get the email template from the Redux store
  const emailTemplate = useSelector((state) => state.email.template);

  // Check if the template has been loaded
  if (!emailTemplate) {
    return <p>Loading...</p>; // Add a loading state if needed
  }

  // Convert the JSON string to Draft.js content
  const contentState = convertFromRaw(JSON.parse(emailTemplate.content));

   // Render the HTML content
   const htmlContent = contentState.getPlainText();

  // Render the template
  return (
    <TemplatePreviewContainer>
      <EmailTemplateContainer>
      <HTMLContent dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </EmailTemplateContainer>
    </TemplatePreviewContainer>
  );
};

export default PreviewTemplate;
