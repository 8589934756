import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getAllArticles } from '../store/actions/articleActions';
import SearchBar from '../components/help/Searchbar';
import DashHeader from '../components/common/DashHeader';
import { FaQuestionCircle, FaShippingFast, FaMoneyBillWave } from 'react-icons/fa';



const HelpMainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categories = [
    {
      name: 'General',
      icon: <FaQuestionCircle />,
      path: '/dash/help/category/general',
      description: 'Get answers to general questions about our services and policies.'
    },
    {
      name: 'Shipping',
      icon: <FaShippingFast />,
      path: '/dash/help/category/shipping',
      description: 'Learn more about our shipping options, tracking, and delivery times.'
    },
    {
      name: 'Billing',
      icon: <FaMoneyBillWave />,
      path: '/dash/help/category/billing',
      description: 'Find information on billing, payments, and managing your subscriptions.'
    },
    {
      name: 'Support',
      icon: <FaQuestionCircle />,  // You can use any icon you prefer here
      path: '/ticket',
      description: 'Reach out to our support team for help with specific issues.'
    }
  ];

  useEffect(() => {
    dispatch(getAllArticles());
  }, [dispatch]);

  const handleCategoryClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <DashHeader/>
    <Container>
      
      <HeroSection>
        <HeroTitle>How Can We Help You?</HeroTitle>
        <HeroDescription>Find answers to your questions or get in touch with our support team.</HeroDescription>
        
      </HeroSection>

      <CategoriesSection>
        {categories.map((category, index) => (
          <CategoryCard key={index} onClick={() => handleCategoryClick(category.path)}>
            <CategoryIcon>{category.icon}</CategoryIcon>
            <CategoryContent>
              <CategoryName>{category.name}</CategoryName>
              <CategoryDescription>{category.description}</CategoryDescription>
            </CategoryContent>
          </CategoryCard>
        ))}
      </CategoriesSection>
    </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 100vh;
  background-color: #f5f5f5;

  @media (max-width: 768px) {
    height: 100vh;
    overflow-y:auto;
  }
`;

const HeroSection = styled.section`
  background-size: cover;
  background-color: #6a0dad;
  padding: 25px 15px;
  text-align: center;

  @media (max-width: 768px) {
    width: 95%;
    
    margin-top: 80px;
    border-radius: 8px;
  }
`;

const HeroTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 10px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

const HeroDescription = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CategoriesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f9f9f9;
  gap: 15px;
  margin: 20px 0;
  padding: 0 10px;
  height:100%;

  @media (max-width: 768px) {
    gap: 10px;  /* Reduce the gap between cards in mobile view */
    margin: 15px 0; /* Reduce the margin around the section */
  }
`;

const CategoryCard = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 30px;
  width: 260px;
  height: 100px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    margin-top: 10px; /* Reduce the top margin */
    width: 100%; /* Make the cards take full width in mobile */
    padding: 10px; /* Reduce padding for smaller screens */
  }
`;

const CategoryIcon = styled.div`
  font-size: 30px;
  color: #6a0dad;
  margin-right: 15px;
`;

const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryName = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0;
`;

const CategoryDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin: 4px 0 0;
`;

export default HelpMainPage;
