import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllCouriersAction } from '../store/actions/packageActions';
import { logoutUserAction } from '../store/actions/authActions';
import CourierCard from '../components/package/CourierCard';
import Header from '../components/common/Header';


const CarrierPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const couriers = useSelector((state) => state.package.couriers);
  const loading = useSelector((state) => state.package.loading);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const error = useSelector((state) => state.package.error);

  const [currentPage, setCurrentPage] = useState(1);
  const [couriersPerPage, setCouriersPerPage] = useState(15);
  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('courier_name');
  const [sortOrder, setSortOrder] = useState('asc');

  
  

  const handleLogout = () => {
    dispatch(logoutUserAction());
  };

  useEffect(() => {
    dispatch(getAllCouriersAction());
  }, [dispatch]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSizePerPageChange = (newSizePerPage, newPage) => {
    setCouriersPerPage(newSizePerPage);
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const filteredCouriers = couriers
    .filter((courier) =>
      (courier.courier_name && courier.courier_name.toLowerCase().includes(searchText)) ||
      (courier.courier_type && courier.courier_type.toLowerCase().includes(searchText)) ||
      (courier.country_code && courier.country_code.toLowerCase().includes(searchText))
    )
    .sort((a, b) => {
      const comparison = (a[sortBy] && a[sortBy]?.toLowerCase()) > (b[sortBy] && b[sortBy]?.toLowerCase()) ? 1 : -1;
      return sortOrder === 'asc' ? comparison : -comparison;
    });

  const paginatedCouriers = filteredCouriers.slice((currentPage - 1) * couriersPerPage, currentPage * couriersPerPage);
  const totalPages = Math.ceil(filteredCouriers.length / couriersPerPage);

  return (
    <Container>
      <Header isAuthenticated={isAuthenticated} onLogout={handleLogout}/>
      <HeroSection>
        <h1>{t('Find Your Courier')}</h1>
        <p>{t('Discover a wide range of couriers for your shipping needs.')}</p>
      </HeroSection>
      <FilterContainer>
        <SearchInput>
          <input type="text" placeholder={t('Search couriers')} value={searchText} onChange={handleSearchChange} />
        </SearchInput>

        <SortControls>
          <label htmlFor="sort">{t('Sort By')}:</label>
          <select id="sort" value={sortBy} onChange={handleSortChange}>
            <option value="courier_name">{t('Name')}</option>
            <option value="courier_type">{t('Type')}</option>
            <option value="country_code">{t('Country Code')}</option>
          </select>
          <button onClick={handleSortOrderChange}>
            {t('Sort')} {sortOrder === 'asc' ? t('ascending') : t('descending')}
          </button>
        </SortControls>
      </FilterContainer>
      <CourierContainer>
        {loading && <LoadingMessage>{t('Loading couriers...')}</LoadingMessage>}
        {error && <ErrorMessage>{t(error)}</ErrorMessage>}
        {!loading && paginatedCouriers.length === 0 && <NoCouriersMessage>{t('No couriers available')}</NoCouriersMessage>}
        {!loading && paginatedCouriers.map((courier) => (
          <CourierCard key={courier._id} courier={courier} />
        ))}
      </CourierContainer>
      {totalPages > 1 && (
        <PaginationContainer>
          <Pagination>
            <PrevButton disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
              {t('Previous')}
            </PrevButton>
            <PageDisplay>
              {t('Page')} {currentPage} {t('of')} {totalPages}
            </PageDisplay>
            <NextButton disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
              {t('Next')}
            </NextButton>
            <PageSizeSelect value={couriersPerPage} onChange={(e) => handleSizePerPageChange(parseInt(e.target.value, 10), 1)}>
              <option value="5">{t('5 per page')}</option>
              <option value="10">{t('10 per page')}</option>
              <option value="15">{t('15 per page')}</option>
              <option value="20">{t('20 per page')}</option>
            </PageSizeSelect>
          </Pagination>
        </PaginationContainer>
      )}
    </Container>
  );
};


const Container = styled.div`
  background-color: #f9f9f9;
  width: 100%;
 
  height: 95vh; /* Full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const HeroSection = styled.section`
margin-top:20px;
border-radius: 8px;
  background-color: #6a0dad; /* Purple background */
  padding: 20px 10px; /* Reduced padding for shorter height */
  text-align: center;
  color: white; /* Optional: to make text more visible against purple */
`;

const CourierContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 15px;
  padding: 10px;
`;

const SearchInput = styled.div`
  input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
`;

const SortControls = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }

  select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }

  button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const LoadingMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
`;

const NoCouriersMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #fff;
  color: #333;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const PrevButton = styled(Button)``;

const NextButton = styled(Button)``;

const PageDisplay = styled.span`
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const PageSizeSelect = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export default CarrierPage;
