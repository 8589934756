import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getAllArticles } from '../store/actions/articleActions';
import SearchBar from '../components/help/Searchbar';
import Header from '../components/common/Header';
import { logoutUserAction } from '../store/actions/authActions';
import { FaQuestionCircle, FaShippingFast, FaMoneyBillWave } from 'react-icons/fa';
import Footer from '../components/home/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height:100vh;
  overflow-y:auto;
`;

const HeroSection = styled.section`
  background-size: cover;
  background-color: #6a0dad;
  padding: 25px 15px;
  text-align: center;

  @media (max-width: 768px) {
    width: 95%;
    border-radius: 8px;
  }
`;
const HeroTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 10px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const HeroDescription = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CategoriesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center align the cards */
  background-color: #f9f9f9;
  gap: 15px; /* Adjust spacing between cards */
  margin: 20px 0;
  padding: 10px;
  @media (max-width: 768px) {
    gap: 10px; /* Reduce gap on smaller screens */
  }
`;
const CategoryCard = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%; /* Allow full width on small screens */
  max-width: 300px; /* Limit maximum width */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    margin: 0; /* Remove extra margin on smaller screens */
    margin-bottom: 10px; /* Add a smaller bottom margin */
  }
`;

const CategoryIcon = styled.div`
  font-size: 30px;
  color: #6a0dad;
  margin-right: 15px;
`;

const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryName = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0;
`;

const CategoryDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin: 4px 0 0;
`;

const HelpMainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleLogout = () => {
    dispatch(logoutUserAction());
  };

  const categories = [
    {
      name: 'General',
      icon: <FaQuestionCircle />,
      path: '/help/category/general',
      description: 'Get answers to general questions about our services and policies.'
    },
    {
      name: 'Shipping',
      icon: <FaShippingFast />,
      path: '/help/category/shipping',
      description: 'Learn more about our shipping options, tracking, and delivery times.'
    },
    {
      name: 'Billing',
      icon: <FaMoneyBillWave />,
      path: '/help/category/billing',
      description: 'Find information on billing, payments, and managing your subscriptions.'
    }
  ];

  useEffect(() => {
    dispatch(getAllArticles());
  }, [dispatch]);

  const handleCategoryClick = (path) => {
    navigate(path);
  };

  return (
    <Container>
     <Header isAuthenticated={isAuthenticated} onLogout={handleLogout}/>
      <HeroSection>
        <HeroTitle>How Can We Help You?</HeroTitle>
        <HeroDescription>Find answers to your questions or get in touch with our support team.</HeroDescription>
        
      </HeroSection>

      <CategoriesSection>
        {categories.map((category, index) => (
          <CategoryCard key={index} onClick={() => handleCategoryClick(category.path)}>
            <CategoryIcon>{category.icon}</CategoryIcon>
            <CategoryContent>
              <CategoryName>{category.name}</CategoryName>
              <CategoryDescription>{category.description}</CategoryDescription>
            </CategoryContent>
          </CategoryCard>
        ))}
      </CategoriesSection>
      <Footer/>
    </Container>
  );
};

export default HelpMainPage;
