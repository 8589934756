import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAction } from '../store/actions/authActions';
import Header from '../components/common/Header';
import Footer from '../components/home/Footer';

const PageContainer = styled.div`
  padding: 40px 20px;
  background-color: #f4f4f4;
  height: 100vh;
  overflow-y: auto;
`;

const HeroSection = styled.section`
  background-color: #6a0dad; /* Purple background color */
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  padding: 20px 15px; /* Reduced padding for smaller height */
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 40px;
  margin-top: 20px;
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  color:#fff;
  margin-bottom: 20px;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
`;

const SubTitle = styled.h3`
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
`;

const TermsOfService = () => {
    const dispatch = useDispatch();
  
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
    const handleLogout = () => {
      dispatch(logoutUserAction());
    };
  return (
    <>
      <Header isAuthenticated={isAuthenticated} onLogout={handleLogout}/>
      <PageContainer>
  <HeroSection>
    <Title>Terms of Service for Track4U</Title>
  </HeroSection>
  <Section>
    <Paragraph>
      By using our website and services, you agree to the following Terms of Service. Please read them carefully.
    </Paragraph>

    <SubTitle>1. Introduction</SubTitle>
    <Paragraph>
      Welcome to Track4U! These Terms of Service govern your access to and use of our platform and services. By accessing or using our services, you agree to comply with these terms and conditions.
    </Paragraph>

    <SubTitle>2. Eligibility</SubTitle>
    <Paragraph>
      To use Track4U, you must be at least 18 years old or the age of majority in your jurisdiction. If you are under 18, you may only use the service with the permission of a parent or guardian.
    </Paragraph>

    <SubTitle>3. User Responsibilities</SubTitle>
    <Paragraph>
      As a user of Track4U, you agree to:
      <ul>
        <li>Provide accurate and complete information when registering or using the platform.</li>
        <li>Keep your account information secure and notify us of any unauthorized access.</li>
        <li>Use the platform only for lawful purposes and comply with all applicable laws and regulations.</li>
      </ul>
    </Paragraph>

    <SubTitle>4. Account Registration</SubTitle>
    <Paragraph>
      To access certain features of our service, you must create an account. By registering, you agree to provide accurate and up-to-date information. You are responsible for maintaining the confidentiality of your account credentials.
    </Paragraph>

    <SubTitle>5. Content</SubTitle>
    <Paragraph>
      By submitting content to Track4U, including but not limited to text, images, and videos, you grant us a non-exclusive, worldwide, royalty-free license to use, modify, and display that content as part of our services. You retain ownership of your content, but we may use it in accordance with our platform’s purpose.
    </Paragraph>

    <SubTitle>6. Prohibited Uses</SubTitle>
    <Paragraph>
      You may not use Track4U to:
      <ul>
        <li>Upload or transmit malicious software or viruses.</li>
        <li>Engage in illegal activities or violate the rights of others.</li>
        <li>Harass, abuse, or harm other users.</li>
        <li>Attempt to access or disrupt Track4U's servers or infrastructure.</li>
      </ul>
    </Paragraph>

    <SubTitle>7. Termination of Service</SubTitle>
    <Paragraph>
      We reserve the right to suspend or terminate your access to Track4U at our discretion, for any reason, including violation of these Terms of Service. Upon termination, your account and data may be deleted.
    </Paragraph>

    <SubTitle>8. Limitation of Liability</SubTitle>
    <Paragraph>
      Track4U is not liable for any indirect, incidental, special, or consequential damages arising from your use or inability to use the platform. We do not guarantee the availability or reliability of the service.
    </Paragraph>

    <SubTitle>9. Changes to Terms</SubTitle>
    <Paragraph>
      We may update these Terms of Service at any time. Any changes will be posted on this page, and the updated version will be effective as of the date indicated. Please check this page regularly for updates.
    </Paragraph>

    <SubTitle>10. Governing Law</SubTitle>
    <Paragraph>
      These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes shall be resolved in the courts of [Your Jurisdiction].
    </Paragraph>

    <SubTitle>11. Contact Information</SubTitle>
    <Paragraph>
      If you have any questions or concerns about these Terms of Service, please contact us at: <br />
      Email: <a href="mailto:info@track4u.net">info@track4u.net</a>
    </Paragraph>

    <SubTitle>12. Acknowledgment</SubTitle>
    <Paragraph>
      Track4U is a product of MBZ Technology LTD. We strive to provide reliable and efficient services to help you manage your package tracking needs.
    </Paragraph>
  </Section>
  <Footer />
</PageContainer>

    </>
  );
};

export default TermsOfService;
