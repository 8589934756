import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getPackageDetailsAction } from '../store/actions/packageActions';
import PackageTimeline from '../components/package/PackageTimeline';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TrackingStats from '../components/package/TrackingStats';
import DashHeader from '../components/common/DashHeader';
import LoadingComponent from '../components/common/loading';

const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  height: 90vh;
  overflow-y:auto;
  background: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const TimelineContainer = styled.div`
  margin-left: 0;
  margin-top: 20px;
  height: 90vh;
  overflow-y: auto;
`;

const HeaderTitle = styled.h2`
  margin-bottom: 20px;
`;

const Detail = styled.p`
  margin: 10px 0;
  background-color:#fff;
  border-radius:8px;
  padding:10px;
`;

const PackageDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const packageDetails = useSelector((state) => state.package.packageDetails);
  const loading = useSelector((state) => state.package.loading);
  const error = useSelector((state) => state.package.error);
  const pkg = packageDetails;

   

  useEffect(() => {
    dispatch(getPackageDetailsAction(id));
  }, [dispatch, id]);

  if (loading) {
    return <LoadingComponent/>;
  }

  if (error) {
    return <Container>Error: {error}</Container>;
  }

  if (!pkg) {
    return <Container>Package not found</Container>;
  }

  return (
    <>
      <DashHeader/>
    <Container>
      
      <HeaderTitle>Package Details</HeaderTitle>
      
      <TrackingStats
        trackingNumber={pkg.trackingNumber}
        courier={pkg.courier}
        deliveryStatus={pkg.deliveryStatus}
        category={pkg.category}
      />
      <Detail><strong>Latest Event:</strong> {pkg.latest_event || 'Pending'}</Detail>
      <TimelineContainer>
      <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="destination-content"
            id="destination-header"
          >
            <Typography>Destination Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PackageTimeline
              trackinfo={pkg.destination_info[0].trackinfo}
              location={pkg.destination_info[0].location}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="origin-content"
            id="origin-header"
          >
            <Typography>Origin Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PackageTimeline
              trackinfo={pkg.origin_info[0].trackinfo}
              location={pkg.origin_info[0].location}
            />
          </AccordionDetails>
        </Accordion>
        
      </TimelineContainer>
    </Container>
    </>
  );
};

export default PackageDetailsPage;
