// templateTypes.js

// Action types for email templates
export const ADD_EMAIL_TEMPLATE_REQUEST = 'ADD_EMAIL_TEMPLATE_REQUEST';
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMAIL_TEMPLATE_FAILURE = 'ADD_EMAIL_TEMPLATE_FAILURE';

export const GET_ALL_EMAIL_TEMPLATES_REQUEST = 'GET_ALL_EMAIL_TEMPLATES_REQUEST';
export const GET_ALL_EMAIL_TEMPLATES_SUCCESS = 'GET_ALL_EMAIL_TEMPLATES_SUCCESS';
export const GET_ALL_EMAIL_TEMPLATES_FAILURE = 'GET_ALL_EMAIL_TEMPLATES_FAILURE';

export const GET_EMAIL_TEMPLATE_DETAILS_REQUEST = 'GET_EMAIL_TEMPLATE_DETAILS_REQUEST';
export const GET_EMAIL_TEMPLATE_DETAILS_SUCCESS = 'GET_EMAIL_TEMPLATE_DETAILS_SUCCESS';
export const GET_EMAIL_TEMPLATE_DETAILS_FAILURE = 'GET_EMAIL_TEMPLATE_DETAILS_FAILURE';

export const UPDATE_EMAIL_TEMPLATE_REQUEST = 'UPDATE_EMAIL_TEMPLATE_REQUEST';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_FAILURE = 'UPDATE_EMAIL_TEMPLATE_FAILURE';

export const DELETE_EMAIL_TEMPLATE_REQUEST = 'DELETE_EMAIL_TEMPLATE_REQUEST';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
export const DELETE_EMAIL_TEMPLATE_FAILURE = 'DELETE_EMAIL_TEMPLATE_FAILURE';

// Add more types as needed for your application
