import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  padding-top: 3rem;
  padding-bottom: 5rem;
  background-color: #fff;
  border-radius: 20px;
  max-width: 500px;
  max-height: 500px;
  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 85%;
    margin-top: 70px;
    margin-right: 35px;
  }

  @media (min-width: 1024px) {
    width: 45%;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 1rem;
  border: 2px solid #ccc;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #007bff; /* Change this to your desired color */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Light blue shadow */
    outline: none; /* Remove the default outline */
  }

  &::placeholder {
    color: #aaa; /* Placeholder text color */
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Title = styled.h3`
  margin-bottom: 2rem;
  font-size: 25px;
`;

const Label = styled.label`
  width: 100%;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const RowContainer = styled.div`
  display: flex;
  gap: 10px;
 
  width: 100%;
`;

const HalfWidthInput = styled(Input)`
  width: 90%;
  height: 50px;
`;

const BillingInfoForm = ({ billingInfo, setBillingInfo }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  return (
    <Container>
      <Title>Billing Information</Title>
      <Label htmlFor="fullName">Full Name</Label>
      <Input
        type="text"
        name="fullName"
        id="fullName"
        value={billingInfo.fullName}
        onChange={handleChange}
        placeholder="Enter your full name"
      />
      <Label htmlFor="street">Street</Label>
      <Input
        type="text"
        name="street"
        id="street"
        value={billingInfo.street}
        onChange={handleChange}
        placeholder="Enter your street address"
      />
      <Label htmlFor="city">City</Label>
      <Input
        type="text"
        name="city"
        id="city"
        value={billingInfo.city}
        onChange={handleChange}
        placeholder="Enter your city"
      />
      
      <RowContainer>
        <div>
          <Label htmlFor="state">State</Label>
          <HalfWidthInput
            type="text"
            name="state"
            id="state"
            value={billingInfo.state}
            onChange={handleChange}
            placeholder="Enter your state"
          />
        </div>
        <div>
          <Label htmlFor="zipCode">Zip Code</Label>
          <HalfWidthInput
            type="text"
            name="zipCode"
            id="zipCode"
            value={billingInfo.zipCode}
            onChange={handleChange}
            placeholder="Enter your zip code"
          />
        </div>
      </RowContainer>

      <Label htmlFor="country">Country</Label>
      <Input
        type="text"
        name="country"
        id="country"
        value={billingInfo.country}
        onChange={handleChange}
        placeholder="Enter your country"
      />
      <Label htmlFor="phoneNumber">Phone Number</Label>
      <Input
        type="text"
        name="phoneNumber"
        id="phoneNumber"
        value={billingInfo.phoneNumber}
        onChange={handleChange}
        placeholder="Enter your phone number"
      />
    </Container>
  );
};

export default BillingInfoForm;
