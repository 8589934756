import React from 'react';
import styled from 'styled-components';
import {
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import { useTranslation } from 'react-i18next';

const FilterButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 15px;
  margin-bottom: 20px;
  padding: 15px;
  border-bottom: 1px solid #adb5bd;
`;

const FilterButton = styled.button`
  padding: 8px 16px;
  ${(props) => props.$status && `status: ${props.$status};`}
  background-color: ${(props) => (props.active ? '#03045e' : '#ddd')};
  color: ${(props) => (props.active ? 'white' : '#000')};
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const IconWrapper = styled.span`
  margin-right: 8px;
`;

const iconMap = {
  delivered: <CheckCircleOutlined />,
  transit: <LocalAirportIcon />,
  'out for delivery': <ClockCircleOutlined />,
  'failed attempt': <CloseCircleOutlined />,
  exception: <ExclamationCircleOutlined />,
  'not found': <QuestionCircleOutlined />,
  'info received': <InfoCircleOutlined />,
  pending: <SyncOutlined spin />,
};

const FilterButtons = ({ activeFilter, onFilterChange }) => {
  const { t } = useTranslation(); // Initialize useTranslation hook

  const deliveryStatuses = [
    'All',
    'delivered',
    'transit',
    'out for delivery',
    'failed attempt',
    'exception',
    'not found',
    'info received',
    'pending',
  ];

  return (
    <FilterButtonsContainer>
      {deliveryStatuses.map((status) => (
        <FilterButton
          key={status}
          $status={status}
          active={activeFilter === status ? 'active' : undefined}
          onClick={() => onFilterChange(status)}
        >
          <IconWrapper>{iconMap[status]}</IconWrapper>
          {t(`deliveryStatuses.${status}`, { defaultValue: status })}
        </FilterButton>
      ))}
    </FilterButtonsContainer>
  );
};

export default FilterButtons;
