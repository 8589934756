import React from 'react';
import styled from 'styled-components';
import courier2 from '../../Images/courier2.png';
import vehicle from '../../Images/vehicle.jpg';

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  text-align: center;
  height: 75vh;
  position: relative;
  color: #fff;

  /* Background image and overlay */
  background-image: url(${vehicle});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* Dark overlay */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1;
  }
`;

const HeroContent = styled.div`
  max-width: 600px;
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
`;

const HeroHeading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const HeroSubheading = styled.p`
  font-size: 18px;
  color: #ddd;
  margin-bottom: 30px;
`;

const CTAButton = styled.a`
  background-color: #6200b3;
  color: #fff;
  padding: 1rem 2rem;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 800;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #4b0091;
  }
`;

const CourierImage = styled.img`
  margin-top: 30px;
  width: 100%;
  max-width: 500px;
  height: auto;
  z-index: 2; /* Ensure the image appears above the overlay */
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroHeading>Track Your Packages with Ease</HeroHeading>
        <HeroSubheading>
          Stay updated on all your deliveries in one place. Sign up today to get real-time notifications and never miss a package again.
        </HeroSubheading>
        <CTAButton href="/dashboard">Get Started</CTAButton>
      </HeroContent>
      <CourierImage src={courier2} alt="Courier delivering packages" />
    </HeroContainer>
  );
};

export default HeroSection;
