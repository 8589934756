import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 70%;
  padding: 10px 20px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;

  &:focus {
    outline: none;
    border-color: #4f46e5; /* Change this to your preferred focus color */
    box-shadow: 0 0 5px rgba(79, 70, 229, 0.5); /* Optional: add a shadow on focus */
  }

  &::placeholder {
    color: #aaa;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  return (
    <SearchBarContainer>
      <StyledInput
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search for help articles..."
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
