// articleActionTypes.js

// Creating a new article
export const CREATE_ARTICLE_REQUEST = 'CREATE_ARTICLE_REQUEST';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_FAILURE = 'CREATE_ARTICLE_FAILURE';

// Getting all articles
export const GET_ALL_ARTICLES_REQUEST = 'GET_ALL_ARTICLES_REQUEST';
export const GET_ALL_ARTICLES_SUCCESS = 'GET_ALL_ARTICLES_SUCCESS';
export const GET_ALL_ARTICLES_FAILURE = 'GET_ALL_ARTICLES_FAILURE';

// Getting a single article by ID
export const GET_ARTICLE_BY_ID_REQUEST = 'GET_ARTICLE_BY_ID_REQUEST';
export const GET_ARTICLE_BY_ID_SUCCESS = 'GET_ARTICLE_BY_ID_SUCCESS';
export const GET_ARTICLE_BY_ID_FAILURE = 'GET_ARTICLE_BY_ID_FAILURE';

// Updating an article by ID
export const UPDATE_ARTICLE_REQUEST = 'UPDATE_ARTICLE_REQUEST';
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS';
export const UPDATE_ARTICLE_FAILURE = 'UPDATE_ARTICLE_FAILURE';

// Deleting an article by ID
export const DELETE_ARTICLE_REQUEST = 'DELETE_ARTICLE_REQUEST';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_FAILURE = 'DELETE_ARTICLE_FAILURE';

// Incrementing article views
export const INCREMENT_ARTICLE_VIEWS_REQUEST = 'INCREMENT_ARTICLE_VIEWS_REQUEST';
export const INCREMENT_ARTICLE_VIEWS_SUCCESS = 'INCREMENT_ARTICLE_VIEWS_SUCCESS';
export const INCREMENT_ARTICLE_VIEWS_FAILURE = 'INCREMENT_ARTICLE_VIEWS_FAILURE';

// Adding feedback to an article
export const ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST';
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAILURE = 'ADD_FEEDBACK_FAILURE';

// Voting an article as helpful
export const VOTE_ARTICLE_HELPFUL_REQUEST = 'VOTE_ARTICLE_HELPFUL_REQUEST';
export const VOTE_ARTICLE_HELPFUL_SUCCESS = 'VOTE_ARTICLE_HELPFUL_SUCCESS';
export const VOTE_ARTICLE_HELPFUL_FAILURE = 'VOTE_ARTICLE_HELPFUL_FAILURE';

// Voting an article as not helpful
export const VOTE_ARTICLE_NOT_HELPFUL_REQUEST = 'VOTE_ARTICLE_NOT_HELPFUL_REQUEST';
export const VOTE_ARTICLE_NOT_HELPFUL_SUCCESS = 'VOTE_ARTICLE_NOT_HELPFUL_SUCCESS';
export const VOTE_ARTICLE_NOT_HELPFUL_FAILURE = 'VOTE_ARTICLE_NOT_HELPFUL_FAILURE';
