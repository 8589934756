import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 90%;
     
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const Setting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const SettingText = styled.span`
  font-size: 16px;
  color: #555;
`;

const Switch = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;

const NotificationSettings = () => {
  const [notificationSettings, setNotificationSettings] = useState({
    inTransit: true,
    outForDelivery: true,
    delivered: true,
    exception: true,
    expired: true,
    notificationFrequency: 'immediate', // Example: immediate, hourly, daily
    quietHours: { start: '22:00', end: '06:00' }, // Example: 10:00 PM - 6:00 AM
    customSounds: true,
    selectedSound: 'default', // Example: customSound1, customSound2, default
    reminders: true,
    alerts: true,
  });

  const toggleNotification = (type) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      [type]: !prevSettings[type],
    }));
  };

  const toggleNotificationFrequency = (frequency) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      notificationFrequency: frequency,
    }));
  };

  const toggleCustomSounds = () => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      customSounds: !prevSettings.customSounds,
    }));
  };

  const toggleReminders = () => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      reminders: !prevSettings.reminders,
    }));
  };

  const toggleAlerts = () => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      alerts: !prevSettings.alerts,
    }));
  };

  return (
    <Container>
      <Title>Notification Settings</Title>

      <Setting>
        <SettingText>In Transit</SettingText>
        <Switch
          checked={notificationSettings.inTransit}
          onChange={() => toggleNotification('inTransit')}
        />
      </Setting>

      <Setting>
        <SettingText>Out For Delivery</SettingText>
        <Switch
          checked={notificationSettings.outForDelivery}
          onChange={() => toggleNotification('outForDelivery')}
        />
      </Setting>

      <Setting>
        <SettingText>Delivered</SettingText>
        <Switch
          checked={notificationSettings.delivered}
          onChange={() => toggleNotification('delivered')}
        />
      </Setting>

      <Setting>
        <SettingText>Exception</SettingText>
        <Switch
          checked={notificationSettings.exception}
          onChange={() => toggleNotification('exception')}
        />
      </Setting>

      <Setting>
        <SettingText>Expired</SettingText>
        <Switch
          checked={notificationSettings.expired}
          onChange={() => toggleNotification('expired')}
        />
      </Setting>

      <Setting>
        <SettingText>Custom Sounds</SettingText>
        <Switch
          checked={notificationSettings.customSounds}
          onChange={toggleCustomSounds}
        />
      </Setting>

      <Setting>
        <SettingText>Reminders</SettingText>
        <Switch
          checked={notificationSettings.reminders}
          onChange={toggleReminders}
        />
      </Setting>

      <Setting>
        <SettingText>Alerts</SettingText>
        <Switch
          checked={notificationSettings.alerts}
          onChange={toggleAlerts}
        />
      </Setting>
    </Container>
  );
};

export default NotificationSettings;
