import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPackageAction } from '../../store/actions/packageActions';
import styled from 'styled-components';
import CourierSelectionModal from './CourierSelectionModal';

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

   @media (max-width: 768px) {
    margin-top: 90px; 
    margin-left: 20px;
    
  }
`;

const FormContent = styled.div`
  flex: 1;
  padding-right: 60px;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 14px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 8px;
  font-size: 14px;
  width: 100%;
  min-height: 80px;
`;

const SelectButton = styled.button`
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
  color: black;
  cursor: pointer;

  &:hover {
    border: 2px solid #4834d4;
  }
`;

const SelectButtonText = styled.span`
  font-size: 16px;
`;

const CategoriesContainer = styled.div`
  margin-bottom: 20px;
`;

const CategoryButton = styled.button`
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #000;
  cursor: pointer;
  border: ${({ selected }) => (selected ? '2px solid #4834d4' : '1px solid #ccc')};

  &:hover {
    border: 2px solid #4834d4;
  }
`;

const SubmitButton = styled.button`
  background-color: #3b0086;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #2c0063;
  }
`;

const PackageForm = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user);

  const [formData, setFormData] = useState({
    trackingNumber: '',
    packageDescription: '',
    selectedCategory: null,
    courier: null,
    userId: userId,
  });

  const [showCourierModal, setShowCourierModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Validate form fields
  if (
    !formData.trackingNumbers.trim() ||
    !formData.packageDescription.trim() ||
    !formData.selectedCategory ||
    !formData.courier
  ) {
    alert('Please fill in all fields and ensure courier and category are selected.');
    return;
  }

    dispatch(addPackageAction(formData));

    setFormData({
      trackingNumber: '',
      packageDescription: '',
      selectedCategory: null,
      courier: null,
      userId: userId,
    });

    alert('Package added successfully');
  };

  const categories = [
    'Clothing Apparel',
    'Home And Gardening',
    'Mother & Kids',
    'Sports',
    'Outdoor & Travel',
    'Food',
    'Health & Beauty',
    'Flower & Gifts',
    'Games, Toys & Books',
    'Others',
  ];

  return (
    <FormContainer>
      <FormContent>
        <FormGroup>
          <Label>Tracking Number:</Label>
          <Input
            type="text"
            name="trackingNumber"
            value={formData.trackingNumber}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Package Description:</Label>
          <TextArea
            name="packageDescription"
            value={formData.packageDescription}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <SelectButton type="button" onClick={() => setShowCourierModal(true)}>
            <SelectButtonText>
              {formData.courier ? `Courier: ${formData.courier.courier_name}` : 'Select Courier'}
            </SelectButtonText>
          </SelectButton>
        </FormGroup>
        <CategoriesContainer>
          <Label>Select Category:</Label>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              selected={formData.selectedCategory === category}
              onClick={() => setFormData((prevData) => ({ ...prevData, selectedCategory: category }))}
            >
              {category}
            </CategoryButton>
          ))}
        </CategoriesContainer>
        <SubmitButton onClick={handleSave}>Add Package</SubmitButton>
      </FormContent>
      <CourierSelectionModal
        visible={showCourierModal}
        onSelectCourier={(selectedCourier) => {
          setFormData((prevData) => ({ ...prevData, courier: selectedCourier }));
          setShowCourierModal(false);
        }}
        onClose={() => setShowCourierModal(false)}
      />
    </FormContainer>
  );
};

export default PackageForm;
