import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import loadingAnimation from '../../Images/animation.json'; // Adjust the path accordingly

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa; /* Optional background color */
`;

const Animation = styled.div`
  width: 200px; /* Adjust size */
  height: 200px;
`;

const LoadingText = styled.p`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #6c757d;
`;

const LoadingComponent = () => {
  return (
    <Container>
      <Animation>
        <Lottie animationData={loadingAnimation} loop />
      </Animation>
      <LoadingText>Loading...</LoadingText>
    </Container>
  );
};

export default LoadingComponent;
