// subscriptionTypes.js

export const FETCH_SUBSCRIPTIONS_REQUEST = 'FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'FETCH_SUBSCRIPTIONS_FAILURE';

export const FETCH_SUBSCRIPTION_DETAILS_REQUEST = 'FETCH_SUBSCRIPTION_DETAILS_REQUEST';
export const FETCH_SUBSCRIPTION_DETAILS_SUCCESS = 'FETCH_SUBSCRIPTION_DETAILS_SUCCESS';
export const FETCH_SUBSCRIPTION_DETAILS_FAILURE = 'FETCH_SUBSCRIPTION_DETAILS_FAILURE';

export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';

export const DELETE_SUBSCRIPTION_REQUEST = 'DELETE_SUBSCRIPTION_REQUEST';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_FAILURE = 'DELETE_SUBSCRIPTION_FAILURE';

export const CREATE_SETUP_INTENT_REQUEST = 'CREATE_SETUP_INTENT_REQUEST';
export const CREATE_SETUP_INTENT_SUCCESS = 'CREATE_SETUP_INTENT_SUCCESS';
export const CREATE_SETUP_INTENT_FAILURE = 'CREATE_SETUP_INTENT_FAILURE';

export const FETCH_USED_TRACKS_REQUEST = 'FETCH_USED_TRACKS_REQUEST';
export const FETCH_USED_TRACKS_SUCCESS = 'FETCH_USED_TRACKS_SUCCESS';
export const FETCH_USED_TRACKS_FAILURE = 'FETCH_USED_TRACKS_FAILURE';

export const SET_SUBSCRIPTION_ACTION = 'SET_SUBSCRIPTION_ACTION'; // New action type

export const RENEW_SUBSCRIPTION_REQUEST = 'RENEW_SUBSCRIPTION_REQUEST'; // New action type
export const RENEW_SUBSCRIPTION_SUCCESS = 'RENEW_SUBSCRIPTION_SUCCESS'; // New action type
export const RENEW_SUBSCRIPTION_FAILURE = 'RENEW_SUBSCRIPTION_FAILURE'; // New action type

export const CHANGE_SUBSCRIPTION_PLAN_REQUEST = 'CHANGE_SUBSCRIPTION_PLAN_REQUEST'; // New action type
export const CHANGE_SUBSCRIPTION_PLAN_SUCCESS = 'CHANGE_SUBSCRIPTION_PLAN_SUCCESS'; // New action type
export const CHANGE_SUBSCRIPTION_PLAN_FAILURE = 'CHANGE_SUBSCRIPTION_PLAN_FAILURE'; // New action type
