import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { CheckCircleOutlined, DownOutlined, ShopOutlined, ExclamationCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, ClockCircleOutlined, WarningOutlined, InfoCircleFilled, LoadingOutlined } from '@ant-design/icons'; 
import styled from 'styled-components';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PackageTimeline from './PackageTimeline';
import FilterButtons from './FilterButtons';
import { updatePackageStatusAction } from '../../store/actions/packageActions';

const CustomAccordion = styled(Accordion)`
  border: none;
  max-width: 1200px;
  margin-bottom: 16px; 
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  border-bottom: 1px solid #ddd; 
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 30px;
  justify-content: space-between; 
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  padding: 12px; 
  margin-bottom: 10px;
`;

const HeaderDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  p {
    font-weight: 600;
    margin: 5px 0;
  }
`;

const ShopIcon = styled(ShopOutlined)`
  font-size: 44px; 
`;

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'delivered':
      return '#4CAF50'; // Green
    case 'in transit':
      return '#03A9F4'; // Light Blue
    case 'out for delivery':
      return '#2196F3'; // Blue
    case 'failed attempt':
      return '#F44336'; // Red
    case 'exception':
      return '#9C27B0'; // Purple
    case 'expired':
      return '#795548'; // Brown
    case 'info received':
      return '#FF5722'; // Deep Orange
    case 'pending':
      return '#FF9800'; // Orange
    default:
      return '#000000'; // Black (default or unknown status)
  }
};

const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 50%;
  color: ${(props) => getStatusColor(props.$status)};
`;

const Message = styled.p`
  font-size: 18px;
  font-weight: 700;
`;

const ReloadButton = styled.button`
  background-color: #c1121f;
  color: #fff;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #861018;
  }
`;

const DeliveryStatus = styled.div`
  color: ${(props) => getStatusColor(props.status)};
  padding: 6px;
  font-weight: 700;
  border-radius: 12px;
  font-size: 17px;
`;

const TimelineContainer = styled.div`
  margin-left: 0;
  margin-top: 20px;
`;

const StatusBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin-bottom: 10px;
  background-color: #90e0ef;
  border-radius: 10px;
`;

const StatusMessage = styled.div`
  margin-left: 10px;
  font-weight: 700;
  color: #03045e;
`;

const FilterContainer = styled.div`
  max-width: 1150px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
`;

const FilterInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  width: 200px;
`;

const DropdownButton = styled.button`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
`;

const SortButton = styled.button`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
`;

const AddNewButton = styled.button`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
`;

const StatusBadgeComponent = ({ status }) => {
  const { t } = useTranslation();
  const translatedStatus = t(status.toLowerCase());
  console.log('Current status:', status);

  switch (status.toLowerCase()) {
    case 'delivered':
      return (
        <StatusBadge $status="success" text={translatedStatus}>
          <CheckCircleOutlined />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    case 'in transit':
      return (
        <StatusBadge $status="processing" text={translatedStatus}>
          <LocalAirportIcon />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    case 'out for delivery':
      return (
        <StatusBadge $status="warning" text={translatedStatus}>
          <ClockCircleOutlined />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    case 'failed attempt':
      return (
        <StatusBadge $status="error" text={translatedStatus}>
          <CloseCircleOutlined />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    case 'exception':
      return (
        <StatusBadge $status="default" text={translatedStatus}>
          <ExclamationCircleOutlined />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    case 'expired':
      return (
        <StatusBadge $status="default" text={translatedStatus}>
          <ExclamationCircleOutlined />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    case 'not found':
      return (
        <StatusBadge $status="default" text={translatedStatus}>
          <QuestionCircleOutlined />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    case 'info received':
      return (
        <StatusBadge $status="default" text={translatedStatus}>
          <InfoCircleFilled />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    case 'pending':
      return (
        <StatusBadge $status="default" text={translatedStatus}>
          <LoadingOutlined />
          <Message>{translatedStatus}</Message>
        </StatusBadge>
      );
    default:
      return (
        <StatusBadge $status="default" text="Unknown Status">
          <QuestionCircleOutlined />
          <Message>{t('unknown status')}</Message>
        </StatusBadge>
      );
  }
};


const AccordionListContainer = styled.div`
  max-height: 600px; // Set a fixed height for the container
  overflow-y: auto; // Make the container scrollable
  padding-right: 20px; // Add some padding for better visuals
  padding-top: 5px;
`;

const PackageInfo = ({ packages }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeFilter, setActiveFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    console.log('Packages:', packages); 
  }, [packages]);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleAddNewClick = () => {
    navigate('/add-new');
  };

  const handleReloadClick = (packageId) => {
    dispatch(updatePackageStatusAction(packageId));
  };

  const filteredPackages = packages.filter((item) => {
    const matchesStatus = activeFilter === 'all' || item.deliveryStatus.toLowerCase() === activeFilter;
    const matchesSearchTerm =
      item.NameOfOrder?.toLowerCase().includes(searchTerm) ||
      item.trackingNumber?.toLowerCase().includes(searchTerm) ||
      item.courier?.toLowerCase().includes(searchTerm);
    return matchesStatus && matchesSearchTerm;
  });

  console.log('my filtered', filteredPackages);
  if (packages.length === 0) {
    return <p>{t('no packages available')}</p>;
  }
  return (
    <>
      <FilterContainer>
        <FilterButtons activeFilter={activeFilter} onFilterChange={handleFilterChange} />
        <FilterInnerContainer>
          <SearchInput
            type="text"
            placeholder={t('search')}
            value={searchTerm}
            onChange={handleSearchChange}
          />
          
          
          <AddNewButton onClick={handleAddNewClick}>{t('add new')}</AddNewButton>
        </FilterInnerContainer>
      </FilterContainer>

      <AccordionListContainer>
        {filteredPackages.map((item) => (
          <CustomAccordion key={item._id}>
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<DownOutlined />}
                aria-controls={`package-content-${item.trackingNumber}`}
                id={`package-header-${item.trackingNumber}`}
              >
                <HeaderContainer>
                  <HeaderDetails>
                    <div>
                      <ShopIcon />
                    </div>
                    <div>
                      <p>{item.NameOfOrder}</p>
                      <p>{item.trackingNumber}</p>
                      <p>{item.courier}</p>
                    </div>
                  </HeaderDetails>

                  <DeliveryStatus status={item.deliveryStatus}>{t(item.deliveryStatus.toLowerCase())}</DeliveryStatus>

                  <div>{item.latest_event || t('latest event pending')}</div>
                </HeaderContainer>
              </StyledAccordionSummary>
              <AccordionDetails>
                <StatusBadgeContainer>
                  <StatusBadgeComponent status={item.deliveryStatus} />
                </StatusBadgeContainer>
                <TimelineContainer>
                  <PackageTimeline
                    trackinfo={[...item.origin_info[0].trackinfo, ...item.destination_info[0].trackinfo]}
                    location={item.origin_info[0].location}
                  />
                </TimelineContainer>
                <ReloadButton onClick={() => handleReloadClick(item._id)}>{t('reload')}</ReloadButton>
              </AccordionDetails>
            </Accordion>
          </CustomAccordion>
        ))}
      </AccordionListContainer>
    </>
  );
};

export default PackageInfo;
