import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByIdAction, updateUserAction } from '../../store/actions/userActions';

const Container = styled.div`
  width: 100%;
  max-width: 600px;
`;

const FormSector = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
`;

const Form = styled.form`
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #3c096c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
`;

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user);
  const user = useSelector((state) => state.user.selectedUser);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    fullname: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (userId) {
      dispatch(getUserByIdAction(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || '',
        email: user.email || '',
        fullName: user.fullName || '',
        phoneNumber: user.phoneNumber || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      username: formData.username,
      email: formData.email,
      fullName: formData.fullName,
      phoneNumber: formData.phoneNumber,
    };
    dispatch(updateUserAction(userId, userData));
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <FormSector>
          <FormGroup>
            <Label>Username</Label>
            <Input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </FormGroup>
        </FormSector>

        <FormSector>
          <FormGroup>
            <Label>Full Name</Label>
            <Input
              type="text"
              name="fullname"
              value={formData.fullName}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Phone Number</Label>
            <Input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </FormGroup>
        </FormSector>

        <Button type="submit">Save Changes</Button>
      </Form>
    </Container>
  );
};

export default ProfileSettings;
