import React from 'react';
import styled from 'styled-components';
import { FaCreditCard } from 'react-icons/fa'; // Credit card icon
import { SiCashapp } from "react-icons/si"; // Cash App icon



const PaymentButtons = ({ paymentMethods, selectedMethod, onSelectPaymentMethod }) => {
  return (
    <Container>
      <Title>Select Your Payment Method</Title>
      <PaymentMethodsContainer>
        {paymentMethods.map((method) => (
          <PaymentMethodButton
            key={method._id}
            selected={selectedMethod && selectedMethod._id === method._id}
            onClick={() => onSelectPaymentMethod(method)}
          >
            <PaymentMethodIcon methodType={method.type}>
              {method.type === 'Cashapp' && <SiCashapp size={24} />}
              {method.type === 'Link' && <FaCreditCard size={24} />}
            </PaymentMethodIcon>
            {method.type}
          </PaymentMethodButton>
        ))}
      </PaymentMethodsContainer>
    </Container>
  );
};
const Container = styled.div`
  background-color: #fff;
  margin-top: 8px;
  border-radius: 20px;
  width: 97%;

  @media (max-width: 768px) {
   
    margin-right: 25px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.2rem;
  color: #000; 
  margin-top: 15px;
`;

const PaymentMethodsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 5px;
  border-radius: 20px;
  padding: 2rem;
`;

const PaymentMethodButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  width: 40%;
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

  ${({ selected }) => {
    console.log(selected); // Debugging the selected prop here
    return selected && `
      border: 2px solid #6200b3;
    `;
  }}

  &:hover {
    border: 2px solid #6200b3;
  }
`;

const PaymentMethodIcon = styled.div`
  margin-right: 10px;

  /* Color customization based on payment method type */
  ${({ methodType }) => {
    if (methodType === 'CashApp') {
      return `color: #00C24E;`; // CashApp's green color
    } else if (methodType === 'Link') {
      return `color: #0061F2;`; // A blue color for credit card method (Link)
    }
    return `color: #000;`; // Default color if no match
  }}
`;

export default PaymentButtons;
