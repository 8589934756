import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getPackagesForUserAction } from '../store/actions/packageActions';
import PackageCard from '../components/package/PackageCard';
import {
  FaBox,
  FaTruck,
  FaCheckCircle,
  FaTimesCircle,
  FaExclamationTriangle,
  FaClock,
  FaSyncAlt,
  FaSearch,
} from 'react-icons/fa';
import { TiInfoOutline } from 'react-icons/ti';
import DashHeader from '../components/common/DashHeader';
import LoadingComponent from '../components/common/loading';



const PackageTestPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const packages = useSelector((state) => state.package.packages);
  const loading = useSelector((state) => state.package.loading);
  const error = useSelector((state) => state.package.error);
  const userId = useSelector((state) => state.auth.user);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const packagesPerPage = 5;

  useEffect(() => {
    if (userId) {
      dispatch(getPackagesForUserAction(userId));
    }
  }, [dispatch, userId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (status) => {
    setFilterStatus(status);
    setCurrentPage(1);
  };

  const handleMobileSelectChange = (event) => {
    setFilterStatus(event.target.value);
    setCurrentPage(1);
  };

  
  const filteredPackages = packages.filter(
    (pkg) =>
      (pkg.trackingNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pkg.status.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (filterStatus === 'all' || pkg.deliveryStatus.toLowerCase() === filterStatus.toLowerCase())
  );

  

  const indexOfLastPackage = currentPage * packagesPerPage;
  const indexOfFirstPackage = indexOfLastPackage - packagesPerPage;
  const currentPackages = filteredPackages.slice(indexOfFirstPackage, indexOfLastPackage);

  const totalPages = Math.ceil(filteredPackages.length / packagesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleAddPackage = () => {
    navigate('/package/create');
  };

  if (loading) return <LoadingComponent/>;
  if (error) return <p>Error: {error}</p>;

  return (
   <>
      <DashHeader/>
    <PackageContainer>
      
      <HeaderContainer>
        <Title>Packages</Title>
        <AddPackageButton onClick={handleAddPackage}>Add Package</AddPackageButton>
      </HeaderContainer>
      
      <FilterContainer show={showFilters}>
        <FilterButton active={filterStatus === 'all'} onClick={() => handleFilterChange('all')}>
          <FaBox /> All
        </FilterButton>
        <FilterButton active={filterStatus === 'delivered'} onClick={() => handleFilterChange('delivered')}>
          <FaCheckCircle /> Delivered
        </FilterButton>
        <FilterButton active={filterStatus === 'in transit'} onClick={() => handleFilterChange('in transit')}>
          <FaTruck /> In Transit
        </FilterButton>
        <FilterButton active={filterStatus === 'out for delivery'} onClick={() => handleFilterChange('out for delivery')}>
          <FaClock /> Out for Delivery
        </FilterButton>
        <FilterButton active={filterStatus === 'failed attempt'} onClick={() => handleFilterChange('failed attempt')}>
          <FaTimesCircle /> Failed Attempt
        </FilterButton>
        <FilterButton active={filterStatus === 'exception'} onClick={() => handleFilterChange('exception')}>
          <FaExclamationTriangle /> Exception
        </FilterButton>
        <FilterButton active={filterStatus === 'expired'} onClick={() => handleFilterChange('expired')}>
          <FaSyncAlt /> Expired
        </FilterButton>
        <FilterButton active={filterStatus === 'info received'} onClick={() => handleFilterChange('info received')}>
          <TiInfoOutline /> Info Received
        </FilterButton>
        <FilterButton active={filterStatus === 'pending'} onClick={() => handleFilterChange('pending')}>
          <FaClock /> Pending
        </FilterButton>
      </FilterContainer>
      <SearchInputContainer>
        <SearchIcon />
        <SearchInput
          type="text"
          placeholder="Search by tracking number or status"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </SearchInputContainer>
      <MobileSelectContainer>
        <select value={filterStatus} onChange={handleMobileSelectChange}>
          <option value="all">All</option>
          <option value="delivered">Delivered</option>
          <option value="in transit">In Transit</option>
          <option value="out for delivery">Out for Delivery</option>
          <option value="failed attempt">Failed Attempt</option>
          <option value="exception">Exception</option>
          <option value="expired">Expired</option>
          <option value="info received">Info Received</option>
          <option value="pending">Pending</option>
        </select>
      </MobileSelectContainer>
      <PackagesList>
        {currentPackages.map((pkg) => (
          <PackageCard key={pkg.trackingNumber} pkg={pkg} />
        ))}
      </PackagesList>
      <PaginationControls>
        <PageButton onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </PageButton>
        <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </PageButton>
      </PaginationControls>
    </PackageContainer>
   </>
  );
};

const PackageContainer = styled.div`
  height: 90vh;
  padding: 15px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y:auto;

  @media (max-width: 768px) {
    height: 96vh;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px; /* Default margin for larger screens */
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-top: 50px; /* Increased margin for mobile screens */
  }
`;


const Title = styled.h2`
  color: #333;
`;

const MobileFilterButton = styled.button`
  display: none;
  padding: 12px;
  background: #3b0086;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: #30006b;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
  }
`;

const FilterButton = styled.button`
  padding: 12px;
  margin: 5px;
  background: ${(props) => (props.active ? '#3b0086' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#000')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 700;

  &:hover {
    background: #3b0086;
    color: #fff;
  }

  svg {
    margin-right: 5px;
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;

  @media (max-width: 768px) {
    width:80%;
    margin-bottom:10px;
  }
`;

const SearchIcon = styled(FaSearch)`
  padding: 10px;
  color: #007bff;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 15px;
  border: none;
  outline: none;
`;

const PackagesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: 100%;
  overflow-y: auto;
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 20px;
`;

const PageButton = styled.button`
  padding: 10px;
  margin: 0 5px;
  background: #3a0ca3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background: #ddd;
    cursor: not-allowed;
  }
`;

const AddPackageButton = styled.button`
  padding: 10px 16px;
  background-color: #3b0086;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #30006b;
  }
`;

const MobileSelectContainer = styled.div`
  display: none;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    display: block;
  }

  select {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    font-size: 16px;
  }
`;

export default PackageTestPage;
