import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUserAction } from '../store/actions/authActions';
import Header from '../components/common/Header';
import Footer from '../components/home/Footer';

const PageContainer = styled.div`
 
  background-color: #f4f4f4;
  height: 100vh; /* Take full height of the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const Section = styled.section`
  margin-bottom: 40px;
  margin-top: 20px;
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color:#fff;
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
`;

const SubTitle = styled.h3`
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
`;

const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
`;

const HeroSection = styled.section`
  background-color: #6a0dad; /* Purple background color */
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  padding: 20px 15px; /* Reduced padding for smaller height */
  text-align: center;
`;

const Value = styled.div`
  text-align: center;
  flex: 1 0 200px;
  max-width: 250px;
`;

const ValueIcon = styled.div`
  font-size: 40px;
  margin-bottom: 10px;
  color: #1976d2;
`;

const ValueTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
`;

const AboutUs = () => {
    const dispatch = useDispatch();
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleLogout = () => {
    dispatch(logoutUserAction());
  };

  return (
    <>
    <Header isAuthenticated={isAuthenticated} onLogout={handleLogout}/>
    <PageContainer>
      {/* Mission Section */}
      <HeroSection>
        <Title>About Track4U</Title>
      </HeroSection>

      {/* Our Vision */}
      <Section>
      <Paragraph>
          Track4U is your go-to platform for seamlessly tracking all your packages in one place. 
          Our mission is to make package tracking effortless and reliable for everyone.
        </Paragraph>

        <SubTitle>Our Vision</SubTitle>
        <Paragraph>
          At Track4U, we aim to revolutionize the way people interact with logistics. 
          Our vision is to provide a universal tracking solution that simplifies the process of package management, 
          making it easier and more transparent for our users.
        </Paragraph>

        <SubTitle>Our Core Values</SubTitle>
        <ValuesContainer>
          <Value>
            <ValueIcon>🚀</ValueIcon>
            <ValueTitle>Innovation</ValueTitle>
            <Paragraph>We are dedicated to constantly innovating to improve our platform and services.</Paragraph>
          </Value>
          <Value>
            <ValueIcon>🤝</ValueIcon>
            <ValueTitle>Customer-Centric</ValueTitle>
            <Paragraph>Our users come first. We strive to provide them with the best experience possible.</Paragraph>
          </Value>
          <Value>
            <ValueIcon>🌍</ValueIcon>
            <ValueTitle>Sustainability</ValueTitle>
            <Paragraph>We aim to reduce our environmental impact through smart logistics solutions.</Paragraph>
          </Value>
        </ValuesContainer>

        
      </Section>

      

      {/* Contact Information */}
      <Section>
        <SubTitle>Contact Us</SubTitle>
        <Paragraph>
          Have any questions? Reach out to us at: <br />
          Email: <a href="mailto:info@track4u.com">info@track4u.net</a> <br />
          Phone: (123) 456-7890
        </Paragraph>
      </Section>
      <Footer/>
    </PageContainer>
    </>
  );
};

export default AboutUs;
