import React from 'react';
import styled from 'styled-components';
import FeatureHighlights from '../components/features/FeatureHighlights';

const PageContainer = styled.div`
  padding: 20px;
`;

const HeroSection = styled.div`
  text-align: center;
  padding: 50px 20px;
  background-color: #f8f9fa;
`;

const Heading = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const Subheading = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
`;

const CTAButton = styled.button`
  background-color: #6200b3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #4b0091;
  }
`;

const FeaturesPage = () => {
  return (
    <PageContainer>
      <HeroSection>
        <Heading>Track Your Packages in Real Time</Heading>
        <Subheading>Stay updated with automated emails and a comprehensive dashboard.</Subheading>
        <CTAButton>Get Started</CTAButton>
      </HeroSection>
      <FeatureHighlights />
    </PageContainer>
  );
};

export default FeaturesPage;
