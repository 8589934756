import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import LoginForm from '../components/auth/LoginForm';

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  overflow-y:hidden;
  
`;



const LoginPage = () => {
  const user = useSelector((state) => state.auth.user);
  

  return (
    <PageContainer>
    
      <LoginForm />
    </PageContainer>
  );
};

export default LoginPage;
