import React, { useState } from 'react';
import styled from 'styled-components';
import ProfileSettings from '../components/settings/ProfileSettings';
import PasswordSettings from '../components/settings/PasswordSettings';
import BillingAddress from '../components/settings/BillingAddress'; // Import BillingAddress component here
import NotificationSettings from '../components/settings/NotificationSettings';
import DashHeader from '../components/common/DashHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 880px;

  @media (max-width: 768px) {
    
     margin-top: 60px;
   
  }
  
`;

const TabsWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-top:20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f2f2f2;
  }

  ${(props) =>
    props.active &&
    `
      background-color: #fff;
      border-bottom: 1px solid #fff;
      border-radius: 4px 4px 0 0;
    `}
`;

const ContentContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
`;



const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState('profile');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
     <DashHeader/>
     <Container>
      
      <TabsWrapper>
        <Tab active={activeTab === 'profile'} onClick={() => handleTabClick('profile')}>
          Profile Settings
        </Tab>
        <Tab active={activeTab === 'billing'} onClick={() => handleTabClick('billing')}>
          Billing Address
        </Tab>
        <Tab active={activeTab === 'notifications'} onClick={() => handleTabClick('notifications')}>
          Notification Settings
        </Tab>
        <Tab active={activeTab === 'password'} onClick={() => handleTabClick('password')}>
          Password Settings
        </Tab>
      </TabsWrapper>
      <ContentContainer>
        {activeTab === 'profile' && <ProfileSettings />}
        {activeTab === 'billing' && <BillingAddress />}
        {activeTab === 'notifications' && <NotificationSettings />}
        {activeTab === 'password' && <PasswordSettings />}
      </ContentContainer>
    </Container>
    </>
   
  );
};

export default SettingsPage;
