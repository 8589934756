import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PackageInfo from '../components/package/PackageInfo';
import { getPackagesForUserAction } from '../store/actions/packageActions';
import styled from 'styled-components';
import LoadingComponent from '../components/common/loading';
import DashHeader from '../components/common/DashHeader';

const PackageContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CenteredError = styled.p`
  color: red;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

const PackageArchivePage = () => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.package.packages.filter(pkg => pkg.archived)); // Filter archived packages
  const loading = useSelector((state) => state.package.loading);
  const error = useSelector((state) => state.package.error);
  const userId = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userId) {
      dispatch(getPackagesForUserAction(userId)); // Fetch all packages
    }
  }, [dispatch, userId]);

  if (loading) return (<LoadingComponent/>);
  if (error) return <CenteredError>{error}</CenteredError>;


  return (
    <>
    <DashHeader/>
      <PackageContainer>
      <h2>Archived Packages</h2>

      {!loading && !error && <PackageInfo packages={packages} />} {/* Pass filtered archived packages */}
    </PackageContainer>
    </>
  );
};

export default PackageArchivePage;
