import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FaRocket, FaGem, FaStar, FaCheckCircle, FaCogs, FaShieldAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { fetchSubscriptionPlansAction } from '../../store/actions/subscriptionPlanActions';

const PricingContainer = styled.div`
  width: 100%;
  padding: 80px 0;
  background-color: #3b0086;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1024px) {
    width: 1800px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Typography = styled.div`
  text-align: center;
  margin-bottom: 40px;
  color: #fff;
  font-size: 24px;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const PlanCard = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  width: 250px;
  height: 300px;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    max-width: 80%;
    margin-bottom: 20px;
  }
`;

const PlanTitle = styled.div`
  font-weight: 600;
  font-size: 25px;
  color: #000;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 8px;
  }
`;

const PlanIcon = styled.span`
  color: #3b0086; /* Purple color for the icon */
  margin-right: 8px;
`;

const PlanPrice = styled.div`
  display: flex;
  align-items: baseline;
  font-weight: 600;
  margin-bottom: 16px;
`;

const DollarSign = styled.span`
  font-size: 16px;
  margin-right: 4px;
  position: relative;
  top:-8px;
`;

const PriceAmount = styled.span`
  font-size: 24px;
`;

const PlanFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 16px 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #757575;

  & > svg {
    margin-right: 8px;
    color: #3b0086;
  }
`;

const Button = styled.button`
  background-color: #3b0086;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 25px;
  text-transform: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1565c0;
  }
`;

const planIcons = {
  Basic: <FaRocket />,
  Standard: <FaStar />,
  Premium: <FaGem />
};

const featureIcons = {
  'Priority Support': <FaShieldAlt />,
  'Advanced Settings': <FaCogs />,
  'Standard Support': <FaCheckCircle />,
  'Enhanced Security': <FaShieldAlt />,
  'Customizable Options': <FaCogs />
};

const Pricing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSubscriptionPlansAction());
  }, [dispatch]);

  const plans = useSelector((state) => state.plans.subscriptionPlans);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const loading = useSelector((state) => state.plans.loading);
  const error = useSelector((state) => state.plans.error);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading plans: {error}</p>;

  const filteredPlans = plans.filter(plan => plan.name !== 'Trial');

  const handleChoosePlan = () => {
    if (isAuthenticated) {
      navigate('/subscription/plans');
    } else {
      navigate('/register');
    }
  };

  return (
    <PricingContainer id="pricing">
      <Container>
        <Typography>Choose Your Plan</Typography>
        <Grid>
          {filteredPlans.map((plan, index) => (
            <PlanCard key={index}>
              <PlanTitle>
                <PlanIcon>{planIcons[plan.name]}</PlanIcon> {plan.name}
              </PlanTitle>
              <PlanPrice>
                <DollarSign>$</DollarSign>
                <PriceAmount>{plan.monthlyPrice}</PriceAmount>
              </PlanPrice>
              <PlanFeatures>
                {plan.features.map((feature, idx) => (
                  <FeatureItem key={idx}>
                    {featureIcons[feature] || <FaCheckCircle />}
                    {feature}
                  </FeatureItem>
                ))}
              </PlanFeatures>
              <Button onClick={() => handleChoosePlan(plan.id)}>Choose Plan</Button>
            </PlanCard>
          ))}
        </Grid>
      </Container>
    </PricingContainer>
  );
};

export default Pricing;
