import React from 'react';
import styled from 'styled-components';
import Header from '../components/test/Header'; // Adjust path as per your file structure
import Hero from '../components/test/Hero'; // Adjust path as per your file structure
import Features from '../components/test/Features'; // Adjust path as per your file structure
import About from '../components/test/About'; // Adjust path as per your file structure
import Pricing from '../components/test/Pricing'; // Adjust path as per your file structure
import Testimonial from '../components/test/Testimonial'; // Adjust path as per your file structure
import Contact from '../components/test/Contact'; // Adjust path as per your file structure
import Footer from '../components/test/Footer'; // Adjust path as per your file structure
import FAQSectionComponent from '../components/test/FAQ';

// Styled container with overflow-y: auto
const MainContainer = styled.div`
  overflow-y: auto;
  height: 100%; /* Ensure the container takes up full height of the viewport */
`;

const MainPage = () => {
  return (
    <MainContainer>
      <Header />
      <Hero />
      <Features />
      <About />
      <Pricing />
      <FAQSectionComponent/>
      <Testimonial />
      <Contact />
      <Footer />
    </MainContainer>
  );
}

export default MainPage;
