import React from 'react';
import styled from 'styled-components';
import ActivityItem from './ActivityItem';

const StyledLatestActivity = styled.div`
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem; /* Reduced gap for closer spacing */
  padding-top: 1rem;
  height: 350px;
  max-width: 680px;
  margin-left: 2.2rem;
  margin-bottom: 2.4rem;

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
    margin: 0; /* Removed margin for closer spacing */
  }

  @media (max-width: 1024px) {
    width: 90%;
    margin-left: 1rem;
    padding: 1.5rem;
  }

  @media (max-width: 768px) {
    background-color:#f5f5f5;
    width: 100%;
    height: auto;
    padding: 1rem;
    gap: 0.5rem; /* Further reduce gap on smaller screens */
  }
`;

const ActivityList = styled.ul`
  margin-top: 0.5rem; /* Slight gap from title */
  padding: 0;
  list-style-type: none;

  font-size: 0.9rem; /* Reduced font size for list items */
  color: #555;
`;

const NoActivity = styled.p`
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  color: #777;
  margin-top: 0.8rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const LoadingText = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #888;
`;

function LatestActivity({ activities, isLoading }) {
  const latestActivities = activities.slice(0, 4);

  return (
    <StyledLatestActivity>
      <h2>Latest Activity</h2>
      {!isLoading ? (
        latestActivities.length > 0 ? (
          <ActivityList>
            {latestActivities.map((activity) => (
              <ActivityItem activity={activity} key={activity.id} />
            ))}
          </ActivityList>
        ) : (
          <NoActivity>No recent activity...</NoActivity>
        )
      ) : (
        <LoadingText>Loading activities...</LoadingText>
      )}
    </StyledLatestActivity>
  );
}

export default LatestActivity;
