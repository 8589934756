import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Tab,
  Tabs,
  CircularProgress,
  Typography,
} from '@mui/material';
import { FaHome } from 'react-icons/fa';
import { MdNotifications } from 'react-icons/md';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LanguageIcon from '@mui/icons-material/Language';
import i18n from '../../language/i18n';
import { getNotificationsAction, markAllNotificationsAsReadAction } from '../../store/actions/notificationActions';
import { logoutUserAction } from '../../store/actions/authActions';
import { getUserByIdAction } from '../../store/actions/userActions';

const DashHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationEl, setNotificationEl] = useState(null);
  const [languageEl, setLanguageEl] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const userId = useSelector((state) => state.auth.user);
  const notifications = useSelector((state) => state.note.notifications);
  const loading = useSelector((state) => state.note.loading);
  const error = useSelector((state) => state.note.error);
  const user = useSelector((state) => state.user.selectedUser);

  useEffect(() => {
    if (userId) {
      dispatch(getUserByIdAction(userId));
      dispatch(getNotificationsAction(userId));
    }
  }, [dispatch, userId]);

  const unreadCount = notifications?.filter((n) => !n.read).length || 0;

  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleProfileMenuClose = () => setAnchorEl(null);

  const handleNotificationMenuOpen = (event) => setNotificationEl(event.currentTarget);
  const handleNotificationMenuClose = () => setNotificationEl(null);

  const handleLanguageMenuOpen = (event) => setLanguageEl(event.currentTarget);
  const handleLanguageMenuClose = () => setLanguageEl(null);

  const handleTabChange = (event, newValue) => setTabIndex(newValue);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    handleLanguageMenuClose();
  };

  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsReadAction(userId));
  };

  const breakLongText = (text, limit = 40) => {
    if (!text) return '';
    const regex = new RegExp(`.{1,${limit}}`, 'g'); // Properly wraps the regex pattern in backticks
    return text.match(regex)?.join('<br>') || ''; // Safeguards against null in case text.match() returns null
  };

  const filteredNotifications = () => {
    if (tabIndex === 0) return notifications;
    return notifications.filter((notification) =>
      tabIndex === 1 ? !notification.read : notification.read
    );
  };

  return (
    <HeaderContainer>
      <NavLinks>
        <IconButton as={Link} to="/" title="Home">
          <FaHome size={24} color="#403d39" />
        </IconButton>
        <IconButton onClick={handleNotificationMenuOpen}>
          <Badge badgeContent={unreadCount} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton onClick={handleLanguageMenuOpen}>
          <LanguageIcon />
        </IconButton>
        <IconButton onClick={handleProfileMenuOpen}>
          <Avatar alt="User Profile" src={user?.avatar || ''} />
        </IconButton>
      </NavLinks>

      {/* Profile Menu */}
      <ProfileMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
      >
        {user && (
          <ProfileDetails>
            <Avatar alt={user.username} src={user.avatar || ''} />
            <UserInfo>
              <Username>{user.username}</Username>
              <UserEmail>{user.email}</UserEmail>
            </UserInfo>
          </ProfileDetails>
        )}
        <MenuItem onClick={() => { navigate('/settings'); handleProfileMenuClose(); }}>
          Profile Settings
        </MenuItem>
        <MenuItem onClick={() => { dispatch(logoutUserAction()); handleProfileMenuClose(); }}>
          Logout
        </MenuItem>
      </ProfileMenu>

      {/* Notification Menu */}
      <NotificationMenuContainer
        anchorEl={notificationEl}
        open={Boolean(notificationEl)}
        onClose={handleNotificationMenuClose}
      >
        <NotificationHeader>
          <span>Notifications</span>
          <MarkAllAsRead onClick={markAllAsRead}>Mark all as read</MarkAllAsRead>
        </NotificationHeader>

        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="All" />
          <Tab label="Unread" />
          <Tab label="Read" />
        </Tabs>

        <SubNotifications>
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={24} />
            <Typography ml={2}>Loading notifications...</Typography>
          </MenuItem>
        ) : error ? (
          <MenuItem disabled>
            <Typography color="error">Error: {error}</Typography>
          </MenuItem>
        ) : filteredNotifications().length > 0 ? (
          filteredNotifications().map((notification) => (
            
              <StyledNotification key={notification._id}>
              <NotificationIcon>
                {notification.read ? (
                  <IoMdCheckmarkCircleOutline size={24} color="#4caf50" />
                ) : (
                  <MdNotifications size={24} color="#ff9800" />
                )}
              </NotificationIcon>
              <Typography
              dangerouslySetInnerHTML={{
                __html: breakLongText(notification.message),
              }}
            />
            </StyledNotification>



            

            
          ))
        ) : (
          <MenuItem disabled>No new notifications</MenuItem>
        )}
        </SubNotifications>
      </NotificationMenuContainer>

      
  <ProfileMenu
    anchorEl={languageEl}
    open={Boolean(languageEl)}
    onClose={handleLanguageMenuClose}
  >
    <MenuItem onClick={() => changeLanguage('en')}>
      <FlagIcon src="https://flagcdn.com/us.svg" alt="US Flag" />
      English
    </MenuItem>
    <MenuItem onClick={() => changeLanguage('es')}>
      <FlagIcon src="https://flagcdn.com/es.svg" alt="Spanish Flag" />
      Spanish
    </MenuItem>
    <MenuItem onClick={() => changeLanguage('fr')}>
      <FlagIcon src="https://flagcdn.com/fr.svg" alt="French Flag" />
      French
    </MenuItem>
    <MenuItem onClick={() => changeLanguage('de')}>
      <FlagIcon src="https://flagcdn.com/de.svg" alt="German Flag" />
      German
    </MenuItem>
  </ProfileMenu>

    </HeaderContainer>
  );
};
const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 30px;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: absolute;
  right: 2rem;

  
`;

const ProfileMenu = styled(Menu)`
  margin-top: 10px;
`;

const NotificationMenuContainer = styled(Menu)`
  margin-top: 10px;
  width: 410px;
`;

const SubNotifications = styled.div`
 height: 420px;
 width:100%;
 overflow-y: auto;
 
`

const FlagIcon = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 8px;
`;

const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

const MarkAllAsRead = styled.span`
  cursor: pointer;
  font-size: 0.9rem;
  &:hover {
    text-decoration: underline;
  }
`;

const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Username = styled(Typography)`
  font-weight: bold;
  font-size: 1rem;
`;

const UserEmail = styled(Typography)`
  color: #6c757d;
  font-size: 0.9rem;
`;

const StyledNotification = styled(MenuItem)`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;

  @media (max-width: 600px) {
    font-size: 0.875rem;
  }
`;

const NotificationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DashHeader;
