import React, { useEffect } from 'react';
import styled from 'styled-components';
import SubscriptionPlanCard from '../components/subscription/subscriptionPlan'; // Adjust the path as needed
import SubscriptionPlans from '../components/subscription/Plans';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptionPlansAction } from '../store/actions/subscriptionPlanActions'; // Adjust the path as needed
import DashHeader from '../components/common/DashHeader';
import LoadingComponent from '../components/common/loading';

const SubscriptionPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubscriptionPlansAction());
  }, [dispatch]);

  const plans = useSelector((state) => state.plans.subscriptionPlans);
  const loading = useSelector((state) => state.plans.loading);
  const error = useSelector((state) => state.plans.error);

  return (
    <PageContainer>
    <DashHeader/>
      
      
      {loading && <LoadingComponent/>}
      {error && <p>{error}</p>}
      {!loading && !error && (
        <Container>
            <SubscriptionPlans plans={plans} />
            <SubContainer>

            </SubContainer>

        </Container>
      )}
    </PageContainer>
  );
};

const Container = styled.div`
  @media (max-width: 768px) {
     height: 100vh;
     overflow-y:auto;
     background-color: #f5f5f5;
     margin-top: 80px;
     padding-bottom: 100px;
  }
`;

const SubContainer = styled.div`
  @media (max-width: 768px) {
    height:200px;
  }
`;

const PageContainer = styled.div`
 
  @media (max-width: 768px) {
    
     margin-bottom: 80px;
  }
`;

export default SubscriptionPage;
