import React from 'react';
import styled from 'styled-components';
import TestimonialCard from './TestimonialCard';

// Importing images from the images folder
import userImage1 from '../../Images/uifaces-human-image1.jpg';
import userImage2 from '../../Images/uifaces-human-image2.jpg';
import userImage3 from '../../Images/uifaces-human-image1.jpg';
import userImage from '../../Images/uifaces-human-image.jpg';

const testimonialData = [
  {
    id: 1,
    userImage: userImage1,
    userName: 'Sarah Johnson',
    userLocation: 'New York, USA',
    rating: 5,
    testimonial: 'I love using this package tracker! It makes it so easy to keep an eye on all my orders from different stores. Highly recommended!',
  },
  {
    id: 2,
    userImage: userImage,
    userName: 'Mark Davis',
    userLocation: 'London, UK',
    rating: 4,
    testimonial: 'Great app! It\'s a time-saver and helps me stay organized with all my online purchases. No more searching for tracking numbers!',
  },
  {
    id: 3,
    userImage: userImage2,
    userName: 'Anna Chen',
    userLocation: 'Tokyo, Japan',
    rating: 5,
    testimonial: 'I have tried several package tracking apps, and this one stands out. Simple, intuitive, and does the job perfectly!',
  },
  // Add more testimonial data as needed
];

const SectionContainer = styled.section`
  width: 100%;
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
`;

const TestimonialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const TestimonialsSection = () => {
  return (
    <SectionContainer>
      <Title>What Our Customers Are Saying</Title>
      <TestimonialsContainer>
        {testimonialData.map((testimonial) => (
          <TestimonialCard key={testimonial.id} {...testimonial} />
        ))}
      </TestimonialsContainer>
    </SectionContainer>
  );
};

export default TestimonialsSection;
