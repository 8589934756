import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPackagesAction } from '../../store/actions/packageActions';
import styled from 'styled-components';
import CourierSelectionModal from './CourierSelectionModal';
import { FaTimes } from 'react-icons/fa';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

   @media (max-width: 768px) {
    margin-top: 90px; 
    margin-left: 20px;
    
  }
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
  width: 90%;
`;

const CategoryGroup = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
`;

const TextArea = styled.textarea`
  padding: 8px;
  font-size: 14px;
  width: 100%;
  min-height: 80px;
  resize: vertical;
`;

const SelectButton = styled.button`
  background-color: #e5e7eb;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
  color: black;
  cursor: pointer;

  &:hover {
    background-color: #4834d4;
    color: #fff;
  }
`;

const SubmitButton = styled.button`
  background-color: #3b0086;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #2c0063;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CategoryButton = styled.button`
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #000;
  cursor: pointer;
  border: 1px solid #000;

  ${({ selected }) =>
    selected &&
    `
    border: 2px solid #3b0086; // Purple border color when selected
    
  `}

  &:hover {
    border-color: #3c096c;
  }
`;

const ConfirmButton = styled.button`
  background-color: #6200b3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
`;

const StandardPackageForm = ({ usedTracks, allowedTracks, remainingDays, plan }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user);
  
  const [formData, setFormData] = useState({
    trackingNumbers: '',
    packageDescription: '',
    selectedCategory: null,
    courier: null,
    userId: userId,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSave = (e) => {
    e.preventDefault();
  
    // Validate form fields
  if (
    !formData.trackingNumbers.trim() ||
    !formData.packageDescription.trim() ||
    !formData.selectedCategory ||
    !formData.courier
  ) {
    alert('Please fill in all fields and ensure courier and category are selected.');
    return;
  }
  
    // Split tracking numbers by commas, newlines, or both and trim extra spaces
    const trackingNumbersArray = formData.trackingNumbers.split(/[\s,]+/).map((number) => number.trim()).filter((number) => number.length > 0);
  
    const packagesData = trackingNumbersArray.map((trackingNumber) => ({
      trackingNumber,
      packageDescription: formData.packageDescription,
      selectedCategory: formData.selectedCategory,
      courier: formData.courier.courier_code,
      userId: userId,
    }));
  
    dispatch(addPackagesAction(packagesData));
  
    setFormData({
      trackingNumbers: '',
      packageDescription: '',
      selectedCategory: null,
      courier: null,
      userId: userId,
    });
  };
  
  const categories = [
    
    'Sports',
    'Food',
    'Mother & Kids',
    'Health & Beauty',
    'Flower & Gifts',
    'Outdoor & Travel',
    'Clothing Apparel',
    'Home And Gardening',
    'Games, Toys & Books',
    'Others',
  ];

  return (
    <FormContainer>
      <FormGroup>
        <Label>Tracking Numbers (up to 5, one per line):</Label>
        <TextArea
          value={formData.trackingNumbers}
          onChange={(e) =>
            setFormData((prevData) => ({
              ...prevData,
              trackingNumbers: e.target.value,
            }))
          }
          placeholder="Enter tracking numbers, separated by commas"
        />
      </FormGroup>
      
      <FormGroup>
        <Label>Package Description:</Label>
        <TextArea
          name="packageDescription"
          value={formData.packageDescription}
          onChange={(e) =>
            setFormData((prevData) => ({
              ...prevData,
              packageDescription: e.target.value,
            }))
          }
          required
        />
      </FormGroup>
      <FormGroup>
        <SelectButton type="button" onClick={() => setIsModalVisible(true)}>
          {formData.courier ? `Courier: ${formData.courier.courier_name}` : 'Select Courier'}
        </SelectButton>
      </FormGroup>
      <FormGroup>
        <Label>Select Category:</Label>
        <CategoryGroup>
        {categories.map((category, index) => (
          <CategoryButton
          key={index}
          type="button"
          selected={formData.selectedCategory === category}
          onClick={() =>
            setFormData((prevData) => ({
              ...prevData,
              selectedCategory: category,
            }))
          }
        >
          {category}
        </CategoryButton>
        ))}

        </CategoryGroup>
        
      </FormGroup>
      <SubmitButton onClick={handleSave}>Add Package</SubmitButton>
      {isModalVisible && (
        <ModalContainer>
          <ModalContent>
            <CloseButton onClick={() => setIsModalVisible(false)}>
              <FaTimes size={24} color="#666" />
            </CloseButton>
            <ModalTitle>{plan === 'Trial' ? 'Upgrade Your Plan' : 'Renew Your Plan'}</ModalTitle>
            <p>
              {plan === 'Trial'
                ? 'Please upgrade your plan to continue.'
                : 'Please renew your plan to continue.'}
            </p>
            <ConfirmButton onClick={() => setIsModalVisible(false)}>Close</ConfirmButton>
          </ModalContent>
        </ModalContainer>
      )}
      <CourierSelectionModal
        visible={isModalVisible}
        onSelectCourier={(selectedCourier) => {
          setFormData((prevData) => ({ ...prevData, courier: selectedCourier }));
          setIsModalVisible(false);
        }}
        onClose={() => setIsModalVisible(false)}
      />
    </FormContainer>
  );
};

export default StandardPackageForm;
