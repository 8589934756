// components/ProfileInfoCard.js
import React from 'react';
import { Card } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledCard = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: center;
  max-width: 1150px;
  gap: 15px;
`;

const CardContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border-radius: 12px;
   background-color: #fff;
   padding: 20px;
   width: 310px;
   p {
      font-size: 20px;
      font-weight: 800;
      
      display: flex;
      align-items: center;
   }

   span {
      font-size: 18px;
      font-weight: 500;
   }

   .icon {
      margin-right: 8px;
   }
`;

const ProfileInfoCard = ({ totalPackages, subscriptionStatus, nextSubscriptionDate }) => {
  return (
    <>
      <StyledCard>
        <CardContainer>
          <InfoCircleOutlined className="icon" />
          <p>
             Total Packages
          </p>
          <span>{totalPackages}</span>
        </CardContainer>

        <CardContainer>
           <CheckCircleOutlined className="icon" />
          <p>
             Subscription Status
          </p>
          <span>{subscriptionStatus}</span>
        </CardContainer>

        <CardContainer>
          <WarningOutlined className="icon" />
          <p>
             Next Subscription Date
          </p>
          <span>{nextSubscriptionDate}</span>
        </CardContainer>
      </StyledCard>
    </>
  );
};

export default ProfileInfoCard;
