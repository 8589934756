import React, { useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeSubscriptionPlanAction, fetchUsedTracksAction, renewSubscriptionAction } from '../../store/actions/subscriptionActions';
import { updatePaymentAction } from '../../store/actions/paymentActions';

const CashAppCheckout = ({ selectedPaymentMethod }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screenshot, setScreenshot] = useState(null);
  const userId = useSelector((state) => state.auth.user);
  const paymentId = useSelector((state) => state.payments.paymentId);
  const subscriptionAction = useSelector((state) => state.subscription.subscriptionAction);
  const subscription = useSelector((state) => state.subscription.subscriptionDetails);
  const plan = useSelector((state) => state.plans.selectedPlan);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles[0]) {
      const reader = new FileReader();
  
      reader.onload = () => {
        setScreenshot(reader.result); // Store Base64 string
      };
  
      reader.readAsDataURL(acceptedFiles[0]); // Convert file to Base64
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    

    const paymentData = {
      status: 'paid',
      paymentScreenshot: screenshot, // Base64 string
    };

    dispatch(updatePaymentAction(paymentId, paymentData));

    const subscriptionData = { 
      subscriptionId: subscription._id, 
      paymentStatus: 'paid' 
    };

    if (subscriptionAction === 'renew') {
      dispatch(renewSubscriptionAction(subscriptionData));
    } else {
      const newPlanData = { ...subscriptionData, newPlanId: plan._id };
      dispatch(changeSubscriptionPlanAction(newPlanData));
    }

    dispatch(fetchUsedTracksAction(userId));
    navigate('/dashboard');
  };

  return (
    <PageContainer>
      <MainContainer>
        <CheckoutContainer>
          <Title>Cash App Checkout</Title>
          <PaymentDetails>
            <BarcodeImage
              src={selectedPaymentMethod.paymentLinks[0].cashAppBarcode}
              alt="Cash App Barcode"
            />
            <div>
              <strong>Selected Payment Method:</strong> {selectedPaymentMethod.type}
            </div>
            <div>
              <strong>Cash App ID:</strong> {selectedPaymentMethod.paymentLinks[0].cashAppId}
            </div>
          </PaymentDetails>
          <form onSubmit={handleSubmit}>
            <DropzoneContainer {...getRootProps()}>
              <input {...getInputProps()} />
              <DropzoneText>
                {screenshot ? 'Screenshot Uploaded' : 'Drag & drop a screenshot, or click to select'}
              </DropzoneText>
            </DropzoneContainer>
            <UploadButton type="submit" disabled={!screenshot}>
              {screenshot ? 'Proceed with Upload' : 'Upload Screenshot'}
            </UploadButton>
          </form>
        </CheckoutContainer>
      </MainContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 80vh;
  overflow-y: hidden;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  width: 100%;
`;

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  max-width: 700px;
  width: 90%;
  margin: 20px;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const PaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #ffffff;
`;

const BarcodeImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 1rem 0;
  border-radius: 8px;

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
  width: 90%;
  border: 2px dashed #6200b3;
  border-radius: 8px;
  background-color: #f1f1f9;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0f7;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DropzoneText = styled.p`
  margin: 0;
  color: #6200b3;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const UploadButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 20px;
  background-color: #6200b3;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #450085;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;

export default CashAppCheckout;
