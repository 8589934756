import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaSyncAlt, FaInfoCircle } from 'react-icons/fa';
import { getCourierByCodeAction, updatePackageStatusAction } from '../../store/actions/packageActions';
import boxes from '../../Images/boxes.png';

const Card = styled.div`
  width: 100%;
  max-width: 700px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    flex-direction: row; /* Change to row on mobile */
    align-items: center; /* Center items vertically */
    padding: 20px 15px;
    margin: 10px;
    width:90%;
  }
`;

const IconColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 15px; /* Add a little space between image and text */
    margin-bottom: 0;
  }
`;

const PackageImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-left: 10px;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding-left: 5px; /* Add extra padding to give some space */
  }
`;

const PackageName = styled.h3`
  margin: 0;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const StatusBadge = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => getStatusColor(props.status)};

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const CardBody = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding-left: 10px; /* Additional padding to keep elements away from the border */
  }
`;

const PackageDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const PackageDetails = styled.p`
  margin: 0;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const CardFooter = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-left: 10px; /* Add extra space for visual comfort */
  }
`;

const DetailButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  color: #000;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3b0086;
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const ReloadButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  color: black;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3b0086;
    color: #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'delivered':
      return '#28a745';
    case 'in transit':
      return '#17a2b8';
    case 'out for delivery':
      return '#ffc107';
    case 'failed attempt':
      return '#dc3545';
    case 'exception':
      return '#6f42c1';
    case 'expired':
      return '#6c757d';
    case 'info received':
      return '#007bff';
    case 'pending':
      return '#ffc107';
    default:
      return '#343a40';
  }
};

const PackageCard = ({ pkg }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  

  const handleViewDetails = () => {
    
    navigate(`/package/${pkg._id}`);
  };

  const handleReloadClick = () => {
    dispatch(updatePackageStatusAction(pkg._id, userId));
  };

  return (
    <Card>
      <IconColumn>
        <PackageImage src={boxes} alt="Package" />
      </IconColumn>
      <ContentColumn>
        <CardHeader>
          <PackageName>{pkg.NameOfOrder}</PackageName>
          <StatusBadge status={pkg.deliveryStatus}>
            {pkg.deliveryStatus}
          </StatusBadge>
        </CardHeader>
        <CardBody>
          <PackageDetailsContainer>
            <PackageDetails>Tracking Number: {pkg.trackingNumber}</PackageDetails>
            <PackageDetails>Courier: {pkg.courier}</PackageDetails>
            <PackageDetails>Latest Event: {pkg.latest_event || 'Pending'}</PackageDetails>
          </PackageDetailsContainer>
        </CardBody>
        <CardFooter>
          <DetailButton onClick={handleViewDetails}>
            <FaInfoCircle style={{ marginRight: '8px' }} />
            View Details
          </DetailButton>
          <ReloadButton onClick={handleReloadClick}>
            <FaSyncAlt style={{ marginRight: '8px' }} />
            Reload
          </ReloadButton>
        </CardFooter>
      </ContentColumn>
    </Card>
  );
};

export default PackageCard;
